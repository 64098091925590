import styled from '@emotion/styled';

interface FixedBannerCProps {
    width: string;
    height: string;
}

const FixedBannerC = styled.div<FixedBannerCProps>`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 16px;
    overflow: hidden;
`;

export default FixedBannerC;
