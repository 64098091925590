import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import PaginatedControls from '@legacy/designs/paginated-controls';
import RoutesEnum from '@routes';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import BodyText from 'src/legacy/components/common/text/body-text';
import { FixedRankListProps } from '..';

const FixedRankListC: React.FC<FixedRankListProps> = ({ productList, page, totalPage, itemsPerPage, prev, next, rankTotal }) => {
    const { theme } = useHarmony();

    return (
        <Container>
            {productList?.map((product, index) => (
                <Link
                    href={{
                        pathname: RoutesEnum.ProductDetail,
                        query: { productId: product.id },
                    }}
                    key={index}
                >
                    <RankItemWrapper theme={theme}>
                        <BodyText className="rank" size="18px" lineHeight="1" weight="bold" color={theme.primary.default} textAlign="center">
                            {itemsPerPage * (page - 1) + (index + 1)}.
                        </BodyText>
                        <BodyText className="title" size="18px" lineHeight="1" lineClamp={1} wordBreak={'break-all'} ellipsis whiteSpace="nowrap">
                            {product.title}
                        </BodyText>
                    </RankItemWrapper>
                </Link>
            ))}
            {totalPage > 1 && rankTotal > itemsPerPage - 1 && <PaginatedControls active={page - 1} total={totalPage} prev={prev} next={next} />}
        </Container>
    );
};

export default FixedRankListC;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 16px 0 16px;
    min-height: 252px;
`;

const RankItemWrapper = styled.div<IThemeProps>`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 14px 0;
    .rank {
        width: 24px;
    }
    .title {
        width: 302px;
    }

    ${({ theme }) => `
      border-bottom: 1px solid ${theme.gray[50]};

      :nth-last-of-type(1) {
        border-bottom: none;
      }
    `}
`;
