import { useDesignComponents } from '@hooks/use-design-components';

import { MainContentInnerProps } from '@designs/pages/main-content';
import { useHarmony } from '@hooks/use-harmony';
import { useIsClientSide } from '@hooks/use-is-client-side';
import DefaultLayout from '@layouts/default-layout';
import { BlockVStack } from '@legacy/components/blockui';
import LegacyFooter from '@legacy/components/common/legacy-footer';
import LegacyFullPageLoader from '@legacy/components/common/legacy-full-page-loader';
import LegacyHomeChannelComponent from '@legacy/components/home/legacy-home-channel-component';
import TabBar from '@legacy/designs/tab-bar';
import { IChannelComponentDto } from '@models/channel-component/responseDto/channel-component.dto';
import FrameTopMenu from '@models/frame-collection/frame-top-menu';
import { FrameTopMenuItemTypeEnum } from '@models/frame-collection/responseDto/frame.dto';
import Popup from '@models/popup';
import { Design } from '@type/harmony-config';
import { useTranslations } from 'next-intl';
import PopUp from 'src/legacy/components/home/pop-up';

interface MainContentLegacyProps extends MainContentInnerProps {}

const MainContentLegacy: React.FC<MainContentLegacyProps> = ({ componentsDto, popupDto, isLoading }: MainContentLegacyProps) => {
    const isClientSide = useIsClientSide();
    const { theme } = useHarmony();
    const t = useTranslations('common');
    const { frameCollection } = useHarmony();

    const homeBackgroundColor = useDesignComponents<string>({
        [Design.DesignA]: '',
        [Design.DesignB]: '',
        [Design.DesignC]: theme.gray[100],
    });

    if (!isClientSide) {
        return <></>;
    }

    return (
        <DefaultLayout backgroundColor={homeBackgroundColor}>
            <TabBar
                frameTopMenu={
                    frameCollection?.topMenuFrame ||
                    new FrameTopMenu({
                        componentType: 'top_menu',
                        itemType: FrameTopMenuItemTypeEnum.Self,
                        items: [
                            {
                                name: t('home'),
                                componentType: 'top_menu',
                                isForPage: false,
                                pageUrl: null,
                                categoryId: null,
                                categoryName: null,
                            },
                        ],
                    })
                }
            />
            {isLoading && <LegacyFullPageLoader />}
            <BlockVStack spacing="none">
                {popupDto && <PopUp popup={new Popup(popupDto)} />}
                {componentsDto &&
                    componentsDto.map((component: IChannelComponentDto, i) => (
                        <div key={i}>
                            <LegacyHomeChannelComponent index={i} componentDto={component} />
                        </div>
                    ))}
            </BlockVStack>
            {frameCollection && <LegacyFooter footerFrame={frameCollection.footerFrame} />}
        </DefaultLayout>
    );
};

export default MainContentLegacy;
