import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import FooterContainerA from './designA/footer-container';
import FooterContainerB from './designB/footer-container';
import FooterContainerC from './designC/footer-container';

interface FooterContainerProps {}

const FooterContainer: React.FC<FooterContainerProps> = (props) => {
    const { theme } = useHarmony();
    const Component = useDesignComponents<typeof FooterContainerA | typeof FooterContainerB | typeof FooterContainerC>({
        [Design.DesignA]: FooterContainerA,
        [Design.DesignB]: FooterContainerB,
        [Design.DesignC]: FooterContainerC,
    });
    return <Component theme={theme} {...props} />;
};

export default FooterContainer;
