import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import React from 'react';
import SwipeProductListContainerA from './designA/swipe-product-list-container';
import SwipeProductListContainerB from './designB/swipe-product-list-container';
import SwipeProductListContainerC from './designC/swipe-product-list-container';
import { useDragScroll } from '@hooks/use-mouse-drag';

export interface SwipeProductListContainerProps {
    padding?: string;
    children: React.ReactNode;
}

const SwipeProductListContainer: React.FC<SwipeProductListContainerProps> = ({ children, ...props }) => {
    const { theme } = useHarmony();
    const Component = useDesignComponents<typeof SwipeProductListContainerA | typeof SwipeProductListContainerB | typeof SwipeProductListContainerC>({
        [Design.DesignA]: SwipeProductListContainerA,
        [Design.DesignB]: SwipeProductListContainerB,
        [Design.DesignC]: SwipeProductListContainerC,
    });

    const dragScroll = useDragScroll();

    return (
        <Component theme={theme} {...props} {...dragScroll}>
            {children}
        </Component>
    );
};

export default SwipeProductListContainer;
