import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { PaginationProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const LegacyPaginationB: React.FC<PaginationProps> = ({ index, total }) => {
    const { theme } = useHarmony();

    return (
        <Pagination theme={theme}>
            {[...new Array(total)].map((_, i) => {
                return i === index ? <Bar /> : <Dot theme={theme} />;
            })}
        </Pagination>
    );
};

export default LegacyPaginationB;

const Pagination = styled.div`
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    z-index: ${ThemeUtils.zIndex.above};
`;

const Bar = styled.div`
    background: black;
    border-radius: 16px;
    width: 28px;
    height: 6px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
`;

const Dot = styled.div<IThemeProps>`
    background: ${({ theme }) => theme.gray['50']};
    width: 6px;
    height: 6px;
    border-radius: 16px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
`;
