import { useDesignComponents } from '@hooks/use-design-components';
import { BlockVStack } from 'src/legacy/components/blockui';
import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import AccordionRankListA from './designA/accordion-rank-list';
import AccordionRankListB from './designB/accordion-rank-list';
import AccordionRankListC from './designC/accordion-rank-list';

export interface AccordionRankListProps {
    paginatedProductList?: ComponentProduct[];
    page: number;
    totalPage: number;
    itemsPerPage: number;
    prev: () => void;
    next: () => void;
    rankTotal: number;
}

const AccordionRankList: React.FC<AccordionRankListProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof AccordionRankListA | typeof AccordionRankListB | typeof AccordionRankListC>({
        [Design.DesignA]: AccordionRankListA,
        [Design.DesignB]: AccordionRankListB,
        [Design.DesignC]: AccordionRankListC,
    });

    return (
        <BlockVStack spacing="none">
            <Component {...props} />
        </BlockVStack>
    );
};

export default AccordionRankList;
