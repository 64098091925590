import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import FixedSubmenuA from './designA/fixed-submenu';
import FixedSubmenuB from './designB/fixed-submenu';
import FixedSubmenuC from './designC/fixed-submenu';

export type SubmenuStyle = {};

export interface FixedSubmenuProps extends SubmenuStyle {
    items: string[];
    index?: number;
    onSelect: (index: number) => void;
}

const FixedSubmenu: React.FC<FixedSubmenuProps> = (props) => {
    const Component = useDesignComponents<typeof FixedSubmenuA | typeof FixedSubmenuB | typeof FixedSubmenuC>({
        [Design.DesignA]: FixedSubmenuA,
        [Design.DesignB]: FixedSubmenuB,
        [Design.DesignC]: FixedSubmenuC,
    });

    return <Component {...props} />;
};

export default FixedSubmenu;
