import { Body1DProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body1DD: React.FC<Body1DProps> = (props) => {
    return (
        <AbstractedText fontSize="16px" weight="bold" lineHeight="16px">
            {props.children}
        </AbstractedText>
    );
};

export default Body1DD;
