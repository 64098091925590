import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import StarA from './designA/star';
import StarB from './designB/star';
import StarC from './designC/star';

export type StarColor = 'gold' | 'primary' | 'black';

type StarProps = {
    /**@default black */
    color?: StarColor;

    /**@default false */
    active?: boolean;

    size?: string;
};

export type StarComponentProps = Omit<StarProps, 'color'> & {
    color: string;
};

const Star: React.FC<StarProps> = (props) => {
    const Component = useDesignComponents<ComponentType<StarComponentProps>>({
        [Design.DesignA]: StarA,
        [Design.DesignB]: StarB,
        [Design.DesignC]: StarC,
    });
    const { theme } = useHarmony();

    const gold = useDesignComponents<string>({
        [Design.DesignA]: '#ffcd3c',
        [Design.DesignB]: '#F5B843',
        [Design.DesignC]: theme.primary.default,
    });

    let color: string;
    switch (props.color) {
        case 'gold':
            color = gold;
            break;
        case 'primary':
            color = theme.primary.default;
            break;
        case 'black':
        default:
            color = 'black';
            break;
    }
    return <Component {...props} color={color} />;
};

export default Star;
