import { Accordion, Box, Center, VStack } from '@chakra-ui/react';
import Pagination from '@designs/grouped/common/pagination/pagination';
import RankingProductCard from '@designs/grouped/common/product-card/ranking-product-card';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import RankingItem from '@designs/primitives/main/ranking/ranking-item';
import { useHarmony } from '@hooks/use-harmony';
import { usePagination } from '@hooks/use-pagination';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RankingProps } from '..';

const ITEMS_PER_PAGE = 5;

const RankingD: React.FC<RankingProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    const { paginatedData, totalPages, currentPage, setCurrentPage } = usePagination({ data: channelComponent.items[0].mainDisplayProductList, itemsPerPage: ITEMS_PER_PAGE });
    const [expandedIndex, setExpandedIndex] = useState<number>(0);
    const isAccordion = channelComponent.displayType === ComponentDisplayTypeEnum.Accordion;

    useEffect(() => {
        setExpandedIndex(0);
    }, [currentPage]);

    return (
        <VStack as="section" spacing="8px" pt="8px" pb="16px" align="stretch">
            <MainSectionTitle title={channelComponent.title} />
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe ? (
                <Box sx={{ '.swiper-slide': { opacity: 0.5, transition: 'opacity 0.2s ease-in-out' }, '.swiper-slide-active': { opacity: 1 } }}>
                    <Swiper
                        loop={(channelComponent.firstItem?.mainDisplayProductList.length || 0) > 2}
                        slidesPerView={1.39}
                        centeredSlides
                        spaceBetween={8}
                        style={{ padding: '16px 0px', margin: '-16px 0px' }}
                    >
                        {channelComponent.firstItem?.mainDisplayProductList.map((product, index) => (
                            <SwiperSlide key={index}>
                                <RankingProductCard product={product} ranking={index + 1} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            ) : (
                <VStack spacing="24px" align="stretch">
                    <Accordion index={expandedIndex} onChange={(index: number) => setExpandedIndex(index)}>
                        <VStack spacing="8px" align="stretch" px="16px" minH={isAccordion ? '356px' : '232px'}>
                            {paginatedData.map((product, index) => (
                                <RankingItem key={index} product={product} ranking={index + 1 + (currentPage - 1) * ITEMS_PER_PAGE} isAccordion={isAccordion} />
                            ))}
                        </VStack>
                    </Accordion>
                    <Center>
                        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
                    </Center>
                </VStack>
            )}
        </VStack>
    );
};

export default RankingD;
