import styled from '@emotion/styled';
import ThemeUtils from '@utils/theme.utils';

const BannerB = styled.div`
    border-radius: 16px;
    overflow: hidden;
    z-index: ${ThemeUtils.zIndex.above};
    position: relative;
`;

export default BannerB;
