import styled from '@emotion/styled';
import { SwipeProductListContainerProps } from '..';

const SwipeProductListContainerC = styled.div<SwipeProductListContainerProps>`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 12px 16px;
    overflow-y: hidden;
    overflow-x: auto;
    user-select: none;

    ${({ padding }) => `
        padding: ${padding};
    `}
`;

export default SwipeProductListContainerC;
