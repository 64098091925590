import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import PaginatedControlsA from './designA/paginated-controls';
import PaginatedControlsB from './designB/paginated-controls';
import PaginatedControlsC from './designC/paginated-controls';

export interface PaginatedControlsProps {
    active: number;
    total: number;
    prev: () => void;
    next: () => void;
    margin?: string;
}

const PaginatedControls: React.FC<PaginatedControlsProps> = (props) => {
    const Component = useDesignComponents<typeof PaginatedControlsA | typeof PaginatedControlsB | typeof PaginatedControlsC>({
        [Design.DesignA]: PaginatedControlsA,
        [Design.DesignB]: PaginatedControlsB,
        [Design.DesignC]: PaginatedControlsC,
    });

    return <Component {...props} />;
};

export default PaginatedControls;
