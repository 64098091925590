import styled from '@emotion/styled';
import { BlockVStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { AbsoluteContainer, AlignedText, NoWrapTextContainer } from '@styles/styled-components';
import React from 'react';
import { HomeComponentHeadingProps } from '../home-component-heading';

const HomeComponentHeadingB: React.FC<HomeComponentHeadingProps> = ({ title, subtitle, children }) => {
    const { theme } = useHarmony();

    return (
        <StyledHeading>
            <StyledTitle theme={theme} spacing="4px" alignItems="center">
                <NoWrapTextContainer>
                    <AlignedText weight="extraBold" size="19px" lineHeight="20px" align="center">
                        {title}
                    </AlignedText>
                </NoWrapTextContainer>
                {subtitle && (
                    <NoWrapTextContainer>
                        <AlignedText color={theme.gray[500]} weight="medium" size="16px" lineHeight="18px" align="center">
                            {subtitle}
                        </AlignedText>
                    </NoWrapTextContainer>
                )}
            </StyledTitle>
            <AbsoluteContainer right="16px">{children}</AbsoluteContainer>
        </StyledHeading>
    );
};

export default HomeComponentHeadingB;

const StyledHeading = styled.div`
    position: relative;
    width: 100%;
    height: 58px;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
`;

const StyledTitle = styled(BlockVStack)`
    ${({ theme }) => `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        color: ${theme.black};
        flex: 1;
    `}
`;
