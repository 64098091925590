import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { FixedSubmenuProps } from '..';

const FixedSubmenuA: React.FC<FixedSubmenuProps> = ({ items, index, onSelect, ...props }) => {
    const { theme } = useHarmony();
    return (
        <StyledSubmenu theme={theme} {...props}>
            {items.map((item, i) => {
                const active = i === index;
                return (
                    <SubmenuButton key={i} theme={theme} size="16px" color={active ? theme.white : theme.black} className={active ? ' active' : ''} onClick={() => onSelect(i)}>
                        {item}
                    </SubmenuButton>
                );
            })}
        </StyledSubmenu>
    );
};

export default FixedSubmenuA;

const StyledSubmenu = styled.div<IThemeProps>`
    ${() => `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 6px;
    background: white;
  `}
`;

const SubmenuButton = styled(BodyText)<IThemeProps>`
    ${({ theme }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        cursor: pointer;
        border: solid 1px ${theme.gray['50']};
        &.active {
            background: ${theme.primary.default};
        }
    `}
`;
