import { Box, Button, HStack } from '@chakra-ui/react';
import HideOnEmptyBox from '@components/hide-on-empty-box';
import BadgeA from '@designs/primitives/typography/badge-a';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import DetailA from '@designs/primitives/typography/detail-a';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import RoutesEnum from '@routes';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { FooterInnerProps } from '..';

const FooterD: React.FC<FooterInnerProps> = ({ terms, onTermChange, ...props }) => {
    const { theme, settings, frameCollection } = useHarmony();
    const { extensionStatus } = useExtension();
    const t = useTranslations();

    return (
        <Box as="footer" bgColor={theme.background.gray50} p="24px 16px 32px" {...props}>
            <HStack spacing="16px">
                <Link href={RoutesEnum.Faq}>
                    <Box>
                        <Body2C color={theme.text.title1}>{t('footer.faq')}</Body2C>
                    </Box>
                </Link>
                {extensionStatus?.inquiry.isEnabled && (
                    <Link href={RoutesEnum.Inquiry}>
                        <Box>
                            <Body2C color={theme.text.title1}>{t('footer.inquiry')}</Body2C>
                        </Box>
                    </Link>
                )}
                {extensionStatus?.noticeBoard.isEnabled && (
                    <Link href={RoutesEnum.Notice}>
                        <Box>
                            <Body2C color={theme.text.title1}>{t('footer.notice')}</Body2C>
                        </Box>
                    </Link>
                )}
            </HStack>

            <HideOnEmptyBox mt="12px">
                {settings?.customerServiceInfo.contactNumber && (
                    <Body2C color={theme.text.title1}>
                        {t('footer.customerCenter')} {settings.customerServiceInfo.contactNumber}
                    </Body2C>
                )}
                {settings?.customerServiceInfo.csHours && <Body2B color={theme.text.title1}>{settings.customerServiceInfo.csHours}</Body2B>}
            </HideOnEmptyBox>

            <HideOnEmptyBox mt="8px">
                {frameCollection?.footerFrame?.footerBusinessInfo && (
                    <DetailA
                        color={theme.text.title1}
                        dangerouslySetInnerHTML={{ __html: frameCollection.footerFrame.footerBusinessInfo }}
                        sx={{ '*': { lineHeight: 'inherit' }, a: { textDecor: 'underline', color: theme.status.information }, 'a:visited': { color: 'mediumpurple' } }}
                    />
                )}
            </HideOnEmptyBox>

            <HideOnEmptyBox mt="24px" display="flex" flexWrap="wrap" columnGap="16px">
                {terms.map((term, index) => (
                    <Button key={index} onClick={() => onTermChange(term)} variant="unstyled">
                        <DetailA color={theme.text.body2} flexShrink="0">
                            {term.title}
                        </DetailA>
                    </Button>
                ))}
            </HideOnEmptyBox>

            <HideOnEmptyBox mt="10px">
                {frameCollection?.footerFrame?.footerCopyright && (
                    <BadgeA
                        color={theme.text.body2}
                        dangerouslySetInnerHTML={{ __html: frameCollection.footerFrame.footerCopyright }}
                        sx={{ a: { textDecor: 'underline', color: theme.status.information }, 'a:visited': { color: 'mediumpurple' } }}
                    />
                )}
            </HideOnEmptyBox>
        </Box>
    );
};

export default FooterD;
