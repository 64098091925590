import useSWR from 'swr';
import { IAvailablePointsDto } from '@models/available-points/responseDto/available-points.dto';
import TransactionCacheKeys from '@api/transaction/cache-keys';
import { TransactionClient } from '@api/transaction/transaction.client';
import { useCustomer } from '@hooks/use-customer/use-customer';
import HomePoints from '@legacy/designs/home-points';
import ConversionUtils from '@utils/conversion.utils';
import Link from 'next/link';
import RoutesEnum from '@routes';

const HomePointsComponent: React.FC = () => {
    const { isAuth } = useCustomer();
    const { data: availablePoints } = useSWR<IAvailablePointsDto>(isAuth ? TransactionCacheKeys.availablePointsCacheKey() : undefined, () =>
        TransactionClient.getAvailablePoints()
    );

    if (!isAuth) return <></>;

    return (
        <Link href={RoutesEnum.PointHistory}>
            <a>
                <HomePoints availablePoints={ConversionUtils.numberToString(availablePoints?.totalPointAvailable || 0)} />
            </a>
        </Link>
    );
};

export default HomePointsComponent;
