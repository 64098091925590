import { useHarmony } from '@hooks/use-harmony';
import { BrandCollectionProps } from '..';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import CustomImage from '@legacy/components/common/custom-image';
import { PaddedContainer } from '@styles/styled-components';
import styled from '@emotion/styled';
import { useWindowSize } from '@hooks/use-window-size';
import BodyText from '@legacy/components/common/text/body-text';
import { BlockVStack } from '@legacy/components/blockui';
import CustomResizeableImage from '@legacy/components/common/custom-resizeable-image';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import Link from 'next/link';
import RoutesEnum from '@routes';
import { useDragScroll } from '@hooks/use-mouse-drag';

const BrandCollectionA: React.FC<BrandCollectionProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const collectionImage = channelComponent.firstItem?.image;
    const [width] = useWindowSize({ maxWidth: true });
    const dragScroll = useDragScroll();

    return (
        <PaddedContainer padding="16px 0 32px">
            <HomeComponentHeading title={channelComponent.title} subtitle={channelComponent.subtitle} />
            <PaddedContainer>
                <ImageContainer height={(width - 32) * channelComponent.imageHeightFactor}>
                    {collectionImage && (
                        <Link href={channelComponent.firstItem?.pageUrl || ''}>
                            <a className={!channelComponent.firstItem?.pageUrl ? 'disabled' : ''}>
                                <CustomResizeableImage
                                    imageHeightFactor={channelComponent.imageHeightFactor}
                                    src={collectionImage}
                                    alt={channelComponent.title}
                                    layout="fill"
                                    objectFit="cover"
                                />
                            </a>
                        </Link>
                    )}
                </ImageContainer>
            </PaddedContainer>
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <BrandGrid row={channelComponent.brandRows} col={channelComponent.brandCols}>
                    {channelComponent.brandItems?.map((brand) => (
                        <Link href={{ pathname: RoutesEnum.BrandDetail, query: { brandId: brand.brandId } }} key={brand.brandId}>
                            <BrandItem key={brand.brandId}>
                                {brand.brandImageUrl && (
                                    <IconWrapper height={`${(width - 32 - 8 * (channelComponent.brandCols - 1)) / channelComponent.brandCols}px`}>
                                        <CustomImage src={brand.brandImageUrl} alt={brand.channelBrandName} layout={'fill'} objectFit={'cover'} />
                                    </IconWrapper>
                                )}
                                <BlockVStack align="center" alignItems="center">
                                    <BodyText staticSize="14px" color={theme.gray[800]} wordBreak="break-all" textAlign="center" lineHeight="140%">
                                        {brand.channelBrandName}
                                    </BodyText>
                                </BlockVStack>
                            </BrandItem>
                        </Link>
                    ))}
                </BrandGrid>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                <BrandSwipeContainer {...dragScroll}>
                    {channelComponent.brandItems?.map((brand) => (
                        <Link href={{ pathname: RoutesEnum.BrandDetail, query: { brandId: brand.brandId } }} key={brand.brandId}>
                            <BrandItem>
                                {brand.brandImageUrl && (
                                    <IconWrapper width="100px" height="100px">
                                        <CustomImage src={brand.brandImageUrl} alt={brand.channelBrandName} layout={'fill'} objectFit={'cover'} />
                                    </IconWrapper>
                                )}
                                <BlockVStack align="center" alignItems="center" width="100px">
                                    <BodyText staticSize="14px" color={theme.gray[800]} wordBreak="break-all" textAlign="center" lineHeight="140%">
                                        {brand.channelBrandName}
                                    </BodyText>
                                </BlockVStack>
                            </BrandItem>
                        </Link>
                    ))}
                </BrandSwipeContainer>
            )}
        </PaddedContainer>
    );
};

export default BrandCollectionA;

const ImageContainer = styled.div<{ height: number }>`
    position: relative;
    width: 100%;
    height: ${({ height }) => height}px;

    .disabled {
        pointer-events: none;
    }
`;

const BrandGrid = styled.ul<{ row: number; col: number }>`
    ${({ row, col }) => `
    display: grid;
    grid-gap: 8px;
    padding: 0 16px;
    grid-template-rows: repeat(${row}, 1fr);
    grid-template-columns: repeat(${col}, 1fr);
    `}
`;

const BrandSwipeContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 16px;
    overflow-x: auto;
    user-select: none;
`;

const BrandItem = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`;

const IconWrapper = styled.div<{ width?: string; height: string }>`
    width: ${({ width }) => (width ? `${width}` : '100%')};
    height: ${({ height }) => height};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
`;
