import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const FooterContainerC = styled.div<IThemeProps>`
    padding: 24px 16px 40px 16px;
    background: #e6e6e6;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export default FooterContainerC;
