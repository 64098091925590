import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import FixedSubMenuCellD from './designD/fixed-sub-menu-cell';

export interface FixedSubMenuCellProps extends BoxProps {
    isActive?: boolean;
    onClick: () => void;
}

const FixedSubMenuCell: React.FC<FixedSubMenuCellProps> = (props) => {
    const Component = useDesignComponents<ComponentType<FixedSubMenuCellProps>>({
        [Design.DesignD]: FixedSubMenuCellD,
    });

    return <Component {...props} />;
};

export default FixedSubMenuCell;
