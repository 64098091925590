import styled from '@emotion/styled';
import { GridContainerProps } from '..';

const GridContainerA = styled.div<GridContainerProps>`
    ${({ cols, gap, padding, marginTop }) => `
        display: grid;
        grid-template-columns: repeat(${cols}, 1fr);
        gap: ${gap || '16px 16px'};
        place-content: center center;
        width: 100%;
        margin-top: ${marginTop};
        padding: ${padding || '0 16px'};
        min-height: 215px;
`}
`;

export default GridContainerA;
