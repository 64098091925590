import { useEffect, useRef, useState } from 'react';

export const useFixedSubMenuHeight = (deps: unknown[] = []) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [cellHeight, setCellHeight] = useState<string>('auto');

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        let maxHeight = 0;
        for (let i = 0; i < containerRef.current.children.length; i++) {
            const child = containerRef.current.children[i];
            if (child instanceof HTMLDivElement) {
                maxHeight = Math.max(maxHeight, child.offsetHeight);
            }
        }

        if (maxHeight > 0) {
            setCellHeight(`${maxHeight}px`);
        }
    }, [...deps]);

    return {
        containerRef,
        cellHeight,
    };
};
