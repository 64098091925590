import { Swiper, SwiperSlide } from 'swiper/react';
import LegacyPagination from '@legacy/designs/legacy-pagination';
import styled from '@emotion/styled';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import Link from 'next/link';
import { useWindowSize } from '@hooks/use-window-size';
import CustomButton from '@legacy/designs/custom-button/custom-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from 'src/legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import { ImageContainer } from '..';
import CustomImage from 'src/legacy/components/common/custom-image';
import ThemeUtils from '@utils/theme.utils';
import { useGATracking } from '@hooks/use-ga-tracking';

const CLOSE_BUTTON_HEIGHT = 40;
const CUSTOM_BUTTON_HEIGHT = 76;
const PADDING = 16 * 2;

const CenterFloatingPopupB: React.FC<PopupProps> = ({ useButton, buttonName, imageRatio, items, handleIgnoreForToday, onClose }) => {
    const { theme } = useHarmony();
    const { logEvent } = useGATracking();
    const [width, height] = useWindowSize({ maxWidth: true });
    const [index, setIndex] = useState<number>(0);
    const t = useTranslations();

    const buttonHeight = useButton ? CLOSE_BUTTON_HEIGHT + CUSTOM_BUTTON_HEIGHT : CLOSE_BUTTON_HEIGHT;

    const popupWidth = useMemo(() => {
        if (height - buttonHeight < (width - PADDING) * imageRatio) return (height - buttonHeight) / imageRatio;
        return width;
    }, [width, height, imageRatio, buttonHeight]);

    return (
        <BlockHStack align="center" alignItems="center" width="100%" height="100%" spacing="none">
            <PopUpContents>
                <BlockVStack spacing="0">
                    <PopupButtonContainer align="distribute" alignItems="center" width="100%" hasPage={useButton}>
                        <PopUpButton onClick={handleIgnoreForToday}>
                            <BodyText color={theme.white} lineHeight="18px" weight="medium">
                                {t('home.ignoreForToday')}
                            </BodyText>
                        </PopUpButton>
                        <PopUpButton onClick={onClose}>
                            <BodyText color={theme.white} weight="medium">
                                {t('common.close')}
                            </BodyText>
                        </PopUpButton>
                    </PopupButtonContainer>
                    <SwiperContainer width={popupWidth - PADDING} height={(popupWidth - PADDING) * imageRatio} hasPage={useButton}>
                        <Swiper
                            loop={items.length > 1}
                            onSlideChange={(swiper) => {
                                width && setIndex(swiper.realIndex);
                            }}
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                        >
                            {items.map((popupItem, i) => (
                                <SwiperSlide key={i}>
                                    {popupItem.image && (
                                        <Link href={popupItem.linkPageUrl}>
                                            <a>
                                                <ImageContainer width={`${popupWidth - PADDING}px`} height={`${(popupWidth - PADDING) * imageRatio}px`}>
                                                    <CustomImage
                                                        src={popupItem.image}
                                                        layout="fill"
                                                        objectFit="cover"
                                                        onClick={() => {
                                                            logEvent('select_promotion', {
                                                                promotion_name: popupItem.linkPageUrl,
                                                            });
                                                        }}
                                                    />
                                                </ImageContainer>
                                            </a>
                                        </Link>
                                    )}
                                </SwiperSlide>
                            ))}
                            {items.length > 1 && <LegacyPagination index={index} total={items.length} />}
                        </Swiper>
                    </SwiperContainer>
                </BlockVStack>
                {useButton && (
                    <DetailButtonContainer>
                        <Link href={items[index].linkPageUrl}>
                            <CustomButton
                                full
                                color={theme.primary.default}
                                onClick={() => {
                                    logEvent('select_promotion', { promotion_name: items[index].linkPageUrl });
                                }}
                            >
                                {buttonName || t('home.goDetailsPage')}
                            </CustomButton>
                        </Link>
                    </DetailButtonContainer>
                )}
            </PopUpContents>
        </BlockHStack>
    );
};

export default CenterFloatingPopupB;

const PopUpContents = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    padding: 16px;
    & div {
        z-index: ${ThemeUtils.zIndex.alert};
    }
`;

const SwiperContainer = styled.div<{ width: number; height: number; hasPage: boolean }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ hasPage }) => (hasPage ? `16px 16px 0 0` : '16px')};
    overflow: hidden;
`;

const PopUpButton = styled.button`
    background-color: transparent;
    color: #fff;
    z-index: ${ThemeUtils.zIndex.alert};
`;

const PopupButtonContainer = styled(BlockHStack)<{ hasPage: boolean }>`
    ${({ hasPage }) => !hasPage && `order: 1;`};
    height: 40px;
`;

const DetailButtonContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 14px 16px;
    border-radius: 0 0 16px 16px;
`;
