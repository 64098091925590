import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { MagazineItemProps } from '..';
import { BlockVStack } from 'src/legacy/components/blockui';
import { useRouter } from 'next/router';
import { AlignedText, LineClipText, NoWrapTextContainer } from '@styles/styled-components';
import ProductCardImage from '@legacy/designs/product-card-image';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import SwipeProductListContainer from '@legacy/designs/swipe-product-list-container';
import { HStack } from '@chakra-ui/react';

const MagazineItemB: React.FC<MagazineItemProps> = ({ channelComponent, width, ratio, isSwipe = false }) => {
    const { theme } = useHarmony();
    const router = useRouter();

    return (
        <>
            {isSwipe ? (
                <SwipeProductListContainer>
                    <HStack spacing="16px">
                        {channelComponent.items.map((item: ChannelComponentItem, index: number) => (
                            <BlockVStack spacing="8px" key={`${item.categoryId}-${index}`} width={`${width}px`}>
                                {item.image && (
                                    <ImageContainer width={`${width}px`} height={`${width * ratio}px`}>
                                        <ProductCardImage
                                            src={item.image}
                                            alt={item.name || ''}
                                            layout="fill"
                                            objectFit="cover"
                                            onClick={() => {
                                                if (item.pageUrl) {
                                                    router.push({
                                                        pathname: item.pageUrl,
                                                    });
                                                }
                                            }}
                                        />
                                    </ImageContainer>
                                )}
                                <BlockVStack spacing="8px" alignItems="center" padding="8px 0 ">
                                    <NoWrapTextContainer>
                                        <AlignedText weight="extraBold" size="19px" lineHeight="20px" align="center">
                                            {item.title}
                                        </AlignedText>
                                    </NoWrapTextContainer>
                                    {item.subTitle && (
                                        <BlockVStack alignItems="center">
                                            <LineClipText font="secondary" color={theme.gray[500]} weight="regular" size="14px" lineHeight="19px" textAlign="center" lines={2}>
                                                {item.subTitle}
                                            </LineClipText>
                                        </BlockVStack>
                                    )}
                                </BlockVStack>
                            </BlockVStack>
                        ))}
                    </HStack>
                </SwipeProductListContainer>
            ) : (
                <BlockVStack spacing="24px" alignItems="center" width={`${width}px`}>
                    {channelComponent.items.map((item: ChannelComponentItem, index: number) => (
                        <BlockVStack key={`${item.categoryId}-${index}`} spacing="8px" width={`${width - 32}px`}>
                            {item.image && (
                                <ImageContainer width={`${width}px`} height={`${width * ratio}px`}>
                                    <ProductCardImage
                                        src={item.image}
                                        alt={item.name || ''}
                                        layout="fill"
                                        objectFit="cover"
                                        onClick={() => {
                                            if (item.pageUrl) {
                                                router.push({
                                                    pathname: item.pageUrl,
                                                });
                                            }
                                        }}
                                    />
                                </ImageContainer>
                            )}
                            <BlockVStack spacing="8px" alignItems="center" padding="8px 0 ">
                                <NoWrapTextContainer>
                                    <AlignedText weight="extraBold" size="19px" lineHeight="20px" align="center">
                                        {item.title}
                                    </AlignedText>
                                </NoWrapTextContainer>
                                {item.subTitle && (
                                    <BlockVStack alignItems="center">
                                        <LineClipText font="secondary" color={theme.gray[500]} weight="regular" size="14px" lineHeight="19px" textAlign="center" lines={2}>
                                            {item.subTitle}
                                        </LineClipText>
                                    </BlockVStack>
                                )}
                            </BlockVStack>
                        </BlockVStack>
                    ))}
                </BlockVStack>
            )}
        </>
    );
};

export default MagazineItemB;

const ImageContainer = styled.div<{ width: string; height: string }>`
    position: relative;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));

    ${({ width, height }) => `
        width: ${width};
        height: ${height};
    `}
`;
