import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import IconMenuA from './designA/icon-menu';
import IconMenuB from './designB/icon-menu';
import IconMenuC from './designC/icon-menu';

export interface IconMenuProps {
    channelComponent: ChannelComponent;
}

const IconMenu: React.FC<IconMenuProps> = ({ channelComponent }) => {
    const Component = useDesignComponents<typeof IconMenuA | typeof IconMenuB | typeof IconMenuC>({
        [Design.DesignA]: IconMenuA,
        [Design.DesignB]: IconMenuB,
        [Design.DesignC]: IconMenuC,
    });

    return <Component channelComponent={channelComponent} />;
};

export default IconMenu;
