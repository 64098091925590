import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import Loader from '@designs/primitives/common/loader';
import { useDesignComponents } from '@hooks/use-design-components';
import ChannelComponent from '@models/channel-component';
import OfflineStoreItem from '@models/offline-store-item';
import { IOfflineStoreItemDto } from '@models/offline-store-item/responseDto/offline-store-item.dto';
import { Design } from '@type/harmony-config';
import { ComponentType, useMemo } from 'react';
import useSWR from 'swr';
import OfflineStoreA from './designA/offline-store';
import OfflineStoreB from './designB/offline-store';
import OfflineStoreC from './designC/offline-store';
import OfflineStoreD from './designD/offline-store';
import { useExtension } from '@hooks/use-extension';

export interface OfflineStoreProps {
    channelComponent: ChannelComponent;
}

export interface OfflineStoreInnerProps {
    offlineStores: OfflineStoreItem[] | undefined;
}

const OfflineStore: React.FC<OfflineStoreProps> = (props) => {
    const Component = useDesignComponents<ComponentType<OfflineStoreInnerProps>>({
        [Design.DesignA]: OfflineStoreA,
        [Design.DesignB]: OfflineStoreB,
        [Design.DesignC]: OfflineStoreC,
        [Design.DesignD]: OfflineStoreD,
    });
    
    const { extensionStatus } = useExtension();

    const offlineStoreIds = props.channelComponent.items.map((item) => item.offlineStoreId) as string[];
    const { data, isLoading } = useSWR<IOfflineStoreItemDto[]>(extensionStatus?.offlineStore.isEnabled && offlineStoreIds && ChannelCacheKeys.postOfflineStoreCacheKey(offlineStoreIds), () =>
        ChannelClient.postOfflineStore(offlineStoreIds)
    );
    const offlineStores = useMemo(() => (data ? data.map((dto) => new OfflineStoreItem(dto)) : []), [data]);

    if (isLoading) return <Loader />;

    return <Component offlineStores={offlineStores} />;
};

export default OfflineStore;
