import { useDesignComponents } from '@hooks/use-design-components';

import GalleryProductList from '@legacy/designs/gallery-product-list';
import ImageListImage from '@legacy/designs/image-list-image';
import PaginatedControls from '@legacy/designs/paginated-controls';
import { css } from '@emotion/react';
import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface ProductGalleryProps {
    channelComponent: ChannelComponent;
}

const ProductGallery: React.FC<ProductGalleryProps> = ({ channelComponent }) => {
    const router = useRouter();
    const [active, setActive] = useState<number>(0);
    const item = channelComponent.items[active];

    const padding = useDesignComponents<string>({
        [Design.DesignA]: '0 16px',
        [Design.DesignB]: '0 16px',
        [Design.DesignC]: '8px 16px',
    });
    const marginTop = useDesignComponents<string>({
        [Design.DesignA]: '20px',
        [Design.DesignB]: '16px',
        [Design.DesignC]: '0',
    });

    const next = () => {
        setActive(active === channelComponent.items.length - 1 ? active : active + 1);
    };

    const prev = () => {
        setActive(active === 0 ? 0 : active - 1);
    };

    return (
        <>
            <div
                css={css`
                    width: 100%;
                    height: auto;
                    padding: ${padding};
                    margin-top: ${marginTop};
                `}
            >
                {item.image && item.pageUrl && (
                    <Link href={item.pageUrl ? item.pageUrl : ''}>
                        <ImageListImage src={item.image} alt={item.name} imageHeightFactor={channelComponent.imageHeightFactor} layout="fill" objectFit="cover" />
                    </Link>
                )}
                {item.image && !item.pageUrl && (
                    <ImageListImage src={item.image} alt={item.name} imageHeightFactor={channelComponent.imageHeightFactor} layout="fill" objectFit="cover" />
                )}

                {item.mainDisplayProductList.length > 0 && <GalleryProductList products={item.mainDisplayProductList} />}
            </div>
            <PaginatedControls active={active} total={channelComponent.items.length} next={next} prev={prev} />
        </>
    );
};

export default ProductGallery;
