import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import { IconMenuProps } from '../icon-menu';

const IconMenuC: React.FC<IconMenuProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <Container theme={theme} cols={3}>
            {channelComponent.items.map((item, index) => (
                <ItemContainer key={index}>
                    <Link href={item.legacyLink}>
                        <a className={!item.legacyLink ? 'disabled' : ''}>
                            <IconMenuWrapper>
                                {item.image && (
                                    <IconWrapper>
                                        <CustomImage src={item.image} alt={item.name} width={24} height={24} layout={item.isIcon ? 'fixed' : 'fill'} objectFit={'cover'} />
                                    </IconWrapper>
                                )}
                                <MenuWrapper>
                                    <BodyText size="15px" lineHeight="17px" lineClamp={2} wordBreak={'break-all'} ellipsis>
                                        {item.displayName}
                                    </BodyText>
                                </MenuWrapper>
                            </IconMenuWrapper>
                        </a>
                    </Link>
                </ItemContainer>
            ))}
        </Container>
    );
};

export default IconMenuC;

const Container = styled.div<IThemeProps & { cols: number }>`
    width: 100%;
    border-radius: 20px;
    padding: 16px;
    background-color: ${({ theme }) => theme.white};

    ${({ cols }) => `
        display: grid;
        grid-template-columns: repeat(${cols}, 1fr);
        place-content: center center;
        gap: 16px 0;
    `}
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .disabled {
        pointer-events: none;
    }
`;

const IconMenuWrapper = styled.div`
    width: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

const IconWrapper = styled.div`
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
`;

const MenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 24px;
`;
