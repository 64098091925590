import { BlockVStack } from 'src/legacy/components/blockui';
import ProductCardImage from '@legacy/designs/product-card-image';
import SwipeProductListContainer from '@legacy/designs/swipe-product-list-container';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { AlignedText, LineClipText, NoWrapTextContainer } from '@styles/styled-components';
import { useRouter } from 'next/router';
import { MagazineItemProps } from '..';

const MagazineItemC: React.FC<MagazineItemProps> = ({ channelComponent, width, ratio, isSwipe }) => {
    const { theme } = useHarmony();
    const router = useRouter();

    return (
        <>
            {isSwipe && (
                <SwipeProductListContainer padding="0 16px">
                    {channelComponent.items.map((item, index) => (
                        <BlockVStack key={index} spacing="10px" margin={index === channelComponent.items.length - 1 ? '0' : '0 8px 0 0'}>
                            {item.image && (
                                <ImageContainer width={`${width}px`} height={`${width * ratio}px`}>
                                    <ProductCardImage
                                        src={item.image}
                                        alt={item.name || ''}
                                        layout={'fill'}
                                        objectFit="cover"
                                        onClick={() => {
                                            if (item.pageUrl) {
                                                router.push({
                                                    pathname: item.pageUrl,
                                                });
                                            }
                                        }}
                                    />
                                </ImageContainer>
                            )}
                            <BlockVStack spacing="2px" alignItems="start">
                                <NoWrapTextContainer>
                                    <AlignedText size="18px" lineHeight="22px" align="start">
                                        {item.title}
                                    </AlignedText>
                                </NoWrapTextContainer>
                                {item.subTitle && (
                                    <LineClipText color={theme.gray[600]} size="16px" lineHeight="20px" textAlign="start" lines={2}>
                                        {item.subTitle}
                                    </LineClipText>
                                )}
                            </BlockVStack>
                        </BlockVStack>
                    ))}
                </SwipeProductListContainer>
            )}

            {!isSwipe && (
                <BlockVStack spacing="24px" alignItems="center">
                    {channelComponent.items.map((item, index) => (
                        <BlockVStack key={`${item.categoryId}-${index}`} spacing="10px" width={`${width}px`}>
                            {item.image && (
                                <ImageContainer width={`${width}px`} height={`${width * ratio}px`}>
                                    <ProductCardImage
                                        src={item.image}
                                        alt={item.name || ''}
                                        radius={4}
                                        layout="fill"
                                        objectFit="cover"
                                        onClick={() => {
                                            if (item.pageUrl) {
                                                router.push({
                                                    pathname: item.pageUrl,
                                                });
                                            }
                                        }}
                                    />
                                </ImageContainer>
                            )}
                            <BlockVStack spacing="2px" alignItems="start">
                                <NoWrapTextContainer>
                                    <AlignedText size="18px" lineHeight="22px" align="start">
                                        {item.title}
                                    </AlignedText>
                                </NoWrapTextContainer>
                                {item.subTitle && (
                                    <LineClipText color={theme.gray[600]} size="16px" lineHeight="20px" textAlign="start" lines={2}>
                                        {item.subTitle}
                                    </LineClipText>
                                )}
                            </BlockVStack>
                        </BlockVStack>
                    ))}
                </BlockVStack>
            )}
        </>
    );
};

export default MagazineItemC;

const ImageContainer = styled.div<{ width: string; height: string }>`
    position: relative;
    width: 140px;
    height: 140px;
    overflow: hidden;

    ${({ width, height }) => `
        width: ${width};
        height: ${height};
    `}
`;
