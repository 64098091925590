import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import { useDisclosure } from '@chakra-ui/react';
import CustomFooter from '@legacy/designs/custom-footer';
import FooterFrame from '@models/frame-collection/frame-footer';
import Term from '@models/term';
import { ITermDto } from '@models/term/responseDto/term.dto';
import { TermDisplayArea } from '@type/api';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

interface FooterProps {
    footerFrame: FooterFrame | undefined;
}

const LegacyFooter: React.FC<FooterProps> = ({ footerFrame }) => {
    const termDtos = useSWR<ITermDto[]>(ChannelCacheKeys.termsCacheKey(TermDisplayArea.Footer), () => ChannelClient.getTerms(TermDisplayArea.Footer), {
        revalidateOnFocus: false,
    });

    const terms = useMemo<Term[]>(() => {
        if (termDtos && termDtos.data) {
            return termDtos.data.map((term) => new Term(term));
        }
        return [];
    }, [termDtos]);

    const { onClose, isOpen, onOpen } = useDisclosure();
    const [term, setTerm] = useState<Term | undefined>(undefined);

    const openDrawer = (term: Term) => () => {
        setTerm(term);
        onOpen();
    };

    return <CustomFooter footerFrame={footerFrame} terms={terms} term={term} open={isOpen} openDrawer={openDrawer} onClose={onClose} />;
};

export default LegacyFooter;
