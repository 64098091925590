import { VStack, forwardRef } from '@chakra-ui/react';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import DetailA from '@designs/primitives/typography/detail-a';
import { useHarmony } from '@hooks/use-harmony';
import { IconMenuItemProps } from '..';

const IconMenuItemD = forwardRef<IconMenuItemProps, 'div'>(({ imageUrl, label, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="4px" align="stretch" ref={ref} {...props}>
            <ComponentImage src={imageUrl} borderRadius="full" />
            <DetailA textAlign="center">{label}</DetailA>
        </VStack>
    );
});

export default IconMenuItemD;
