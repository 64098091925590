import { Box, BoxProps } from '@chakra-ui/react';
import React, { useRef, useState, MouseEvent } from 'react';
import isMobile from 'is-mobile';

interface SliderBoxProps extends BoxProps {
    children: React.ReactNode;
}

/** Box component that enables horizontal scrolling in the desktop version */

const SliderBox: React.FC<SliderBoxProps> = ({ children, ...props }) => {
    if (isMobile())
        return (
            <Box w="100%" overflowX="auto" {...props}>
                {children}
            </Box>
        );

    return <SliderBoxInner {...props}>{children}</SliderBoxInner>;
};

const SliderBoxInner: React.FC<SliderBoxProps> = ({ children, ...props }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (containerRef.current?.offsetLeft || 0));
        setScrollLeft(containerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging || !containerRef.current) return;
        const x = e.pageX - (containerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <Box
            w="100%"
            overflowX="auto"
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={() => setIsDragging(false)}
            onMouseLeave={() => setIsDragging(false)}
            {...props}
        >
            {children}
        </Box>
    );
};

export default SliderBox;
