import { Box } from '@chakra-ui/react';
import FullModal from '@components/full-modal';
import InnerHtml from '@designs/primitives/common/inner-html';
import { useHarmony } from '@hooks/use-harmony';
import { TermsFullModalInnerProps } from '..';
import Dropdown from '../../dropdown';

const TermsFullModalD: React.FC<TermsFullModalInnerProps> = ({ termsHistoryItems, selectedTerm, onTermChange, ...props }) => {
    const { theme } = useHarmony();

    return (
        <FullModal title={selectedTerm?.value.title} {...props}>
            <Box p="8px 16px 0">
                <Dropdown items={termsHistoryItems} onChange={onTermChange} selectedItem={selectedTerm} />
            </Box>
            <Box p="16px">{selectedTerm?.value.content && <InnerHtml content={selectedTerm?.value.content} />}</Box>
        </FullModal>
    );
};

export default TermsFullModalD;
