import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { useHarmony } from '@hooks/use-harmony';
import ChannelComponent from '@models/channel-component';
import ThemeUtils from '@utils/theme.utils';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';

interface HomeDividerProps {
    channelComponent: ChannelComponent;
}

const HomeDivider: React.FC<HomeDividerProps> = ({ channelComponent }: HomeDividerProps) => {
    const { theme } = useHarmony();
    if (!channelComponent.metadata) return <></>;

    const isTransparent = channelComponent.displayType === ComponentDisplayTypeEnum.Translucent;
    const color = isTransparent ? 'transparent' : ThemeUtils.hexToRgba(channelComponent.metadata.color, channelComponent.metadata.opacity);
    const height = channelComponent.metadata?.height || 0;

    return <DividerLine theme={theme} color={color} height={height} />;
};

export default HomeDivider;

const DividerLine = styled('div')<IThemeProps & { color: string; height: number }>`
    ${({ color, height }) => `
        display: block;
        width:100% ;
        height: ${`${height}px` || '0px'};
        background-color: ${color};
    `}
`;
