import { useDesignComponents } from '@hooks/use-design-components';
import { CustomResizeableImageProps } from 'src/legacy/components/common/custom-resizeable-image';
import { Design } from '@type/harmony-config';
import ImageListImageA from './designA/image-list-image';
import ImageListImageB from './designB/image-list-image';
import ImageListImageC from './designC/image-list-image';

export interface ImageListImageProps extends CustomResizeableImageProps {}

const ImageListImage: React.FC<ImageListImageProps> = (props) => {
    const Component = useDesignComponents<typeof ImageListImageA | typeof ImageListImageB | typeof ImageListImageC>({
        [Design.DesignA]: ImageListImageA,
        [Design.DesignB]: ImageListImageB,
        [Design.DesignC]: ImageListImageC,
    });

    return <Component {...props} />;
};

export default ImageListImage;
