import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType, useEffect } from 'react';
import ProductListD from './designD/product-list';
import { useGATracking } from '@hooks/use-ga-tracking';

export interface ProductListProps {
    channelComponent: ChannelComponent;
}

const ProductList: React.FC<ProductListProps> = (props) => {
    const { logEvent } = useGATracking();
    const Component = useDesignComponents<ComponentType<ProductListProps>>({
        [Design.DesignD]: ProductListD,
    });

    useEffect(() => {
        logEvent('view_item_list', {
            items: props.channelComponent.firstItem?.mainDisplayProductList?.map((product) => ({
                item_id: product.id,
                item_name: product.title,
            })),
        });
    }, [props.channelComponent]);

    return <Component {...props} />;
};

export default ProductList;
