import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const FooterContainerA = styled.div<IThemeProps>`
    ${({ theme }) => `
        padding: 22px 16px 26px 16px;
        background: ${theme.gray[50]};
    `}
`;

export default FooterContainerA;
