import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import FooterContainer from '@legacy/designs/footer-container';
import InnerHtml from '@designs/primitives/common/inner-html';
import RoutesEnum from '@routes';
import { PaddedContainer } from '@styles/styled-components';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React from 'react';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomTermDrawer from 'src/legacy/components/common/custom-term-drawer';
import BodyText from 'src/legacy/components/common/text/body-text';
import { CustomFooterProps } from '..';

const CustomFooterB: React.FC<CustomFooterProps> = ({ footerFrame, terms, term, open, openDrawer, onClose }) => {
    const { theme, designSettings, settings } = useHarmony();
    const { extensionStatus } = useExtension();
    const t = useTranslations('footer');

    return (
        <>
            <FooterContainer>
                <ContactInfo>
                    <BodyText color={theme.primary.default} size={designSettings?.getLegacyFontSize('18px')} weight="bold" textAlign="center">
                        {t('customerCenter')}
                    </BodyText>
                    <BodyText color={theme.primary.default} size={designSettings?.getLegacyFontSize('18px')} weight="bold" textAlign="center">
                        {settings?.customerServiceInfo.contactNumber}
                    </BodyText>
                    <BodyText color={theme.primary.default} size={designSettings?.getLegacyFontSize('18px')}>
                        {settings?.customerServiceInfo.csHours}
                    </BodyText>
                </ContactInfo>
                {footerFrame && (
                    <div
                        css={css`
                            margin-bottom: 33px;
                            font-family: ${theme.font.default.light};
                            font-size: ${designSettings?.getLegacyFontSize('18px')};
                            text-align: center;
                            h1,
                            h3 {
                                font-size: ${designSettings?.getLegacyFontSize('18px')};
                                color: ${theme.primary.default};
                                font-family: ${theme.font.default.bold};
                            }
                            h2 {
                                font-size: ${designSettings?.getLegacyFontSize('18px')};
                                color: ${theme.primary.default};
                            }
                            p {
                                text-align: left;
                                font-size: ${designSettings?.getLegacyFontSize('15px')};
                                color: ${theme.gray[800]};
                                margin: 8px 0;
                            }
                            li {
                                margin-bottom: 4px;
                                text-align: left;
                                color: ${theme.gray[800]};
                                span {
                                    margin-right: 8px;
                                    color: ${theme.gray[600]};
                                }
                            }
                        `}
                    >
                        <InnerHtml content={footerFrame?.footerBusinessInfo} />
                    </div>
                )}
                <div
                    css={css`
                        width: calc(100% - 32px);
                        height: 1px;
                        margin: 16px 0px;
                        background-color: white;
                    `}
                ></div>
                <div
                    css={css`
                        margin-bottom: 32px;
                        text-align: center;
                    `}
                >
                    <PaddedContainer padding="0 0 16px 0">
                        <BlockHStack alignItems="center" spacing="sm" align="center">
                            {terms.map((term) => (
                                <FooterButton key={term.id} size="13px" weight="thin" onClick={openDrawer(term)}>
                                    {term.title}
                                </FooterButton>
                            ))}
                        </BlockHStack>
                    </PaddedContainer>
                    <BlockHStack alignItems="center" spacing="sm" align="center">
                        <Link href={RoutesEnum.Faq}>
                            <FooterButton size="14px" weight="thin">
                                {t('faq')}
                            </FooterButton>
                        </Link>
                        {extensionStatus?.inquiry.isEnabled && (
                            <Link href={RoutesEnum.Inquiry}>
                                <FooterButton size="14px" weight="thin">
                                    {t('inquiry')}
                                </FooterButton>
                            </Link>
                        )}
                        {extensionStatus?.noticeBoard.isEnabled && (
                            <Link href={RoutesEnum.Notice}>
                                <FooterButton size="14px" weight="thin">
                                    {t('notice')}
                                </FooterButton>
                            </Link>
                        )}
                    </BlockHStack>
                </div>
                {footerFrame && (
                    <InnerHtml
                        css={css`
                            text-align: center;
                            color: ${theme.black};
                            font-family: ${theme.font.secondary.light};
                            font-size: ${designSettings?.getLegacyFontSize('12px')};
                        `}
                        content={footerFrame.footerCopyright}
                    />
                )}
            </FooterContainer>
            <CustomTermDrawer term={term} open={open} onClose={onClose} />
        </>
    );
};

export default CustomFooterB;

const ContactInfo = styled.address`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 16px;
`;

const FooterButton = styled(BodyText)`
    cursor: pointer;
`;
