import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { PaginatedQuery } from '@type/api';
import { CouponId, ProductId } from '@type/models';

export enum CouponStatusEnum {
    Pending = 'pending',
    Available = 'available',
    Used = 'used',
    Expired = 'expired',
    SoonExp = 'soon-exp',
}

export enum CouponOrderByEnum {
    DiscountAmount = '-discount_amount',
    IssuedDesc = '-issued_dt',
}

export interface IQueryCouponDto extends PaginatedQuery {
    status?: CouponStatusEnum[] | null;
    productId?: ProductId;
    productCouponIds?: CouponId[];
    orderBy?: CouponOrderByEnum;
    salesType?: SalesTypeEnum;
}

export interface IQueryOrderCouponDto extends PaginatedQuery {
    productIds?: ProductId[];
    salesType?: SalesTypeEnum;
}
