import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import StyledImage from '@legacy/designs/product-card-image/designB/product-card-image';
import { ListPrice } from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import { LineClipText } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import { RankedProductContainerProps, SwipeRankedProductProps } from '..';
import { useSellPricePolicy } from '@hooks/use-sell-price-policy';
import { useCustomer } from '@hooks/use-customer/use-customer';

const CURRENT_PRODUCT_SCALE = 0.65;
const OTHER_PRODUCT_SCALE = 0.55;

const SwipeRankedProductB: React.FC<SwipeRankedProductProps> = ({ product, rank, width = 1, focused, onClick }) => {
    const { theme, designSettings } = useHarmony();
    const t = useTranslations();
    const { currencyService } = useGlobalization();
    const { getPriceWithUnitPolicy } = useSellPricePolicy();
    const { isAuth } = useCustomer();

    const productContainerWidthFactor = focused ? CURRENT_PRODUCT_SCALE : OTHER_PRODUCT_SCALE;
    const slideWidth = width * productContainerWidthFactor;

    return (
        <RelativeContainer onClick={() => onClick?.(product.id)} focused={!!focused}>
            <BlockVStack spacing="8px" width={`${slideWidth}px`}>
                <BlockVStack alignItems="center">
                    <Banner columnWidth={slideWidth} theme={theme} focused={!!focused}>
                        <Overlay active={!focused} />
                        {product.image && (
                            <ImageContainer>
                                <StyledImage src={product.image} alt={product.title} layout="fill" objectFit="cover" theme={theme} disabled={product.isSoldOut} />
                            </ImageContainer>
                        )}
                        <Ranking theme={theme}>
                            <BodyText font="secondary" weight="bold" color={theme.white} size="26px">
                                {rank}
                            </BodyText>
                        </Ranking>
                        <WishlistContainer>
                            <WishListButton className="heart" size="20px" product={product} padding="5px" />
                        </WishlistContainer>
                    </Banner>
                </BlockVStack>
                <ProductDetailsContainer spacing="1px" focused={!!focused}>
                    <BlockVStack spacing="3px">
                        <LineClipText size="15px" lineHeight="18px" textAlign="left" lines={2} letterSpacing={'-0.01em'} weight={'medium'}>
                            {product.showBrandOnProductCard && (
                                <BodyText size="15px" lineHeight="18px" color={theme.primary.default} letterSpacing={'-0.01em'} weight={'bold'}>
                                    [{product.brand}]{' '}
                                </BodyText>
                            )}
                            {product.title}
                        </LineClipText>
                        <BlockVStack spacing="none">
                            {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                                <ListPrice size="14px" color={theme.gray[400]}>
                                    {product.displayedMarketPriceString}
                                </ListPrice>
                            )}
                            <BlockHStack alignItems="flex-end" spacing="4px">
                                <BlockHStack alignItems="flex-end" spacing="2px">
                                    {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                                        <>
                                            {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinSubPrice && (
                                                <BodyText size="15px" lineHeight="18px">
                                                    {t('common.pluralMonth', { month: product.subscriptionMonthlyUnit })}/
                                                </BodyText>
                                            )}
                                            <BlockHStack alignItems="flex-end" spacing="none">
                                                <BodyText weight="bold" size="18px" lineHeight="18px" textAlign="center">
                                                    {getPriceWithUnitPolicy(product.displayedSellPrice)}
                                                </BodyText>
                                                <BodyText size="15px" lineHeight="18px" textAlign="center">
                                                    {currencyService.currencySymbol}{' '}
                                                    {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinPurPrice && `~`}
                                                </BodyText>
                                            </BlockHStack>
                                        </>
                                    ) : (
                                        <BodyText weight="bold" size="18px" lineHeight="18px" textAlign="center">
                                            {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                                        </BodyText>
                                    )}
                                </BlockHStack>
                                {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                                    <BodyText weight="bold" size="18px" lineHeight="18px" color={theme.primary.default}>
                                        {product.discountRate}%
                                    </BodyText>
                                )}
                            </BlockHStack>
                        </BlockVStack>
                    </BlockVStack>
                    <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} direction={Direction.Horizontal} />
                    <TextBadge hasDivider />
                </ProductDetailsContainer>
            </BlockVStack>
        </RelativeContainer>
    );
};

export default SwipeRankedProductB;

const Banner = styled.div<RankedProductContainerProps & { focused: boolean }>`
    ${({ columnWidth }) => `
        width: ${columnWidth}px;
        height: ${columnWidth}px;
        position: relative;
        border-radius:16px;
    `}
    border: 1px solid ${({ theme }) => theme.gray[200]};
    ${({ focused }) => focused && `box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);`}
    overflow: hidden;
`;

const Ranking = styled.div<IThemeProps>`
    ${({ theme }) => `
        position: absolute;
        top: -1px;
        left: -1px;
        border-radius: 16px 0 16px 0;
        background: ${theme.primary.default};
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: ${ThemeUtils.zIndex.badge};
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    `}
`;

export const Overlay = styled.div<{ active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: ${({ active }) => (active ? 0.15 : 0)};
    transition: ease-in-out 0.3s;
    z-index: ${ThemeUtils.zIndex.above};
`;

const RelativeContainer = styled.div<{ focused: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    position: relative;
    cursor: pointer;
    padding-top: ${({ focused }) => (focused ? '0px' : '8%')};
`;

const WishlistContainer = styled.div`
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: ${ThemeUtils.zIndex.badge};
`;

export const ProductDetailsContainer = styled(BlockVStack)<{ focused: boolean }>`
    opacity: ${({ focused }) => (focused ? 1 : 0)};
    transition: ease-in-out 0.3s;
    min-height: 80px;
`;

const ImageContainer = styled.div`
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;
