import { useDesignComponents } from '@hooks/use-design-components';

import CONFIG from '@config';
import { Design } from '@type/harmony-config';
import HomePointsA from './designA/home-points';
import HomePointsB from './designB/home-points';
import HomePointsC from './designC/home-points';

export interface HomePointsProps {
    availablePoints: string;
}

export interface InternalHomePointsProps extends HomePointsProps {
    pointSystem: string;
}

const HomePoints: React.FC<HomePointsProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof HomePointsA | typeof HomePointsB | typeof HomePointsC>({
        [Design.DesignA]: HomePointsA,
        [Design.DesignB]: HomePointsB,
        [Design.DesignC]: HomePointsC,
    });

    const pointSystem = CONFIG.pointSystem;
    if (!pointSystem) return <span></span>;

    return <Component {...props} pointSystem={pointSystem} />;
};

export default HomePoints;
