import { useDesignComponents } from '@hooks/use-design-components';

import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import { BoxProps } from '@chakra-ui/react';
import TermsFullModal from '@designs/grouped/common/terms-full-modal';
import Term from '@models/term';
import { ITermDto } from '@models/term/responseDto/term.dto';
import { TermDisplayArea } from '@type/api';
import { Design } from '@type/harmony-config';
import { ComponentType, useMemo, useState } from 'react';
import useSWR from 'swr';
import FooterD from './designD/footer';

interface FooterProps extends BoxProps {}

export interface FooterInnerProps extends FooterProps {
    terms: Term[];
    onTermChange: (term: Term) => void;
}

const Footer: React.FC<FooterProps> = (props) => {
    const Component = useDesignComponents<ComponentType<FooterInnerProps>>({
        [Design.DesignD]: FooterD,
    });

    const [term, setTerm] = useState<Term | null>(null);

    const termDtos = useSWR<ITermDto[]>(ChannelCacheKeys.termsCacheKey(TermDisplayArea.Footer), () => ChannelClient.getTerms(TermDisplayArea.Footer));
    const terms = useMemo<Term[]>(() => {
        if (termDtos && termDtos.data) {
            return termDtos.data.map((term) => new Term(term));
        }
        return [];
    }, [termDtos]);

    return (
        <>
            <Component terms={terms} onTermChange={setTerm} {...props} />
            <TermsFullModal isOpen={!!term} onClose={() => setTerm(null)} term={term} />
        </>
    );
};

export default Footer;
