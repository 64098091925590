import RatioBox from '@components/ratio-box';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import { ComponentImageProps } from '..';

const ComponentImageD: React.FC<ComponentImageProps> = ({ ratio, src = '', alt, ...props }) => {
    const { theme } = useHarmony();

    return (
        <RatioBox w="100%" pos="relative" ratio={ratio} overflow="hidden" {...props}>
            <CustomImage layout="fill" objectFit="cover" src={src} alt={alt} />
        </RatioBox>
    );
};

export default ComponentImageD;
