import Divider from '@designs/primitives/common/divider';
import { useHarmony } from '@hooks/use-harmony';
import { DividerProps } from '..';

const DividerD: React.FC<DividerProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <Divider borderBottomWidth={channelComponent.metadata?.height || '8px'} borderColor={channelComponent.metadata?.color} opacity={channelComponent.metadata?.opacity || 0} />
    );
};

export default DividerD;
