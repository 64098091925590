import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import ChannelComponent from '@models/channel-component';
import MagazineItemA from './designA/magazine-item';
import MagazineItemB from './designB/magazine-item';
import MagazineItemC from './designC/magazine-item';

export interface MagazineItemProps {
    channelComponent: ChannelComponent;
    width: number;
    ratio: number;
    isSwipe?: boolean;
}

const MagazineItem: React.FC<MagazineItemProps> = (props) => {
    const Component = useDesignComponents<typeof MagazineItemA | typeof MagazineItemB | typeof MagazineItemC>({
        [Design.DesignA]: MagazineItemA,
        [Design.DesignB]: MagazineItemB,
        [Design.DesignC]: MagazineItemC,
    });

    return <Component {...props} />;
};

export default MagazineItem;
