import styled from '@emotion/styled';
import ThemeUtils from '@utils/theme.utils';

const BannerC = styled.div`
    position: relative;
    overflow: hidden;
    z-index: ${ThemeUtils.zIndex.above};
    border-radius: 16px;
`;

export default BannerC;
