import { BlockAccordion, BlockAccordionContent, BlockAccordionItem, BlockAccordionTrigger, BlockAccordionTypeEnum, BlockArrowIconEnum } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import AccordionRankedProduct from '@legacy/designs/accordion-ranked-product';
import PaginatedControls from '@legacy/designs/paginated-controls';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { HorizPadContainer } from '@styles/styled-components';
import { AccordionRankListProps } from '..';

const AccordionRankListB: React.FC<AccordionRankListProps> = ({ paginatedProductList, itemsPerPage, page, totalPage, prev, next }) => {
    const { theme } = useHarmony();

    return (
        <>
            <BlockAccordion type={BlockAccordionTypeEnum.single} defaultValue="0">
                {paginatedProductList?.map((product, i) => (
                    <BlockAccordionItem value={i.toString()} key={i}>
                        <HorizPadContainer>
                            <BlockAccordionTrigger arrowIcon={BlockArrowIconEnum.triangle} size="32px">
                                <RankItemContainer>
                                    <BodyText className="rank" size="18px" weight="extraBold" font="secondary" color={theme.primary.default}>
                                        {itemsPerPage * (page - 1) + (i + 1)}.
                                    </BodyText>
                                    <BodyText size="18px" ellipsis lineClamp={1}>
                                        {product.title}
                                    </BodyText>
                                </RankItemContainer>
                            </BlockAccordionTrigger>
                        </HorizPadContainer>
                        <BlockAccordionContent>
                            <AccordionRankedProduct product={product} />
                        </BlockAccordionContent>
                    </BlockAccordionItem>
                ))}
            </BlockAccordion>
            {totalPage > 1 && <PaginatedControls margin={'18px 0 0'} active={page - 1} total={totalPage} prev={prev} next={next} />}
        </>
    );
};

export default AccordionRankListB;

const RankItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    height: 18px;
    min-width: 0px;

    .rank {
        width: 25px;
        flex-shrink: 0;
    }
`;
