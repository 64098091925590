import { useHarmony } from '@hooks/use-harmony';
import { OfflineStoreCardProps } from '..';
import { Box, VStack } from '@chakra-ui/react';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import RoutesEnum from '@routes';
import SanitationUtils from '@utils/sanitation.utils';

const OfflineStoreCardC: React.FC<OfflineStoreCardProps> = ({ offlineStore }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Link href={`${RoutesEnum.OfflineStore}/${offlineStore.id}`}>
            <VStack align="flex-start" w="140px" spacing="8px">
                <Box w="140px" h="140px" pos="relative" flexShrink={0} borderRadius="4px" overflow="hidden">
                    <CustomImage src={offlineStore.imageUrl} layout="fill" objectFit="cover" />
                </Box>
                <VStack w="100%" align="flex-start" spacing="4px">
                    <VStack w="100%" align="flex-start" spacing="2px">
                        <BodyText size="15px" lineHeight="18px" color={theme.gray[900]} ellipsis lineClamp={2} wordBreak="break-all">
                            {offlineStore.name}
                        </BodyText>
                        <BodyText size="14px" color={theme.gray[600]} ellipsis lineClamp={2} wordBreak="break-all" lineHeight="16px">
                            {offlineStore.baseAddress} {offlineStore.detailAddress}
                        </BodyText>
                    </VStack>
                    <BodyText size="14px" color={theme.gray[600]} lineHeight="16px">
                        {SanitationUtils.sanitizePhoneNumber(offlineStore.phone)}
                    </BodyText>
                    <Box display="flex" h="20px" p="4px 6px" bgColor={offlineStore.isOpenForBusiness ? theme.status.information : theme.gray[400]} borderRadius="4px">
                        <BodyText size="14px" color={theme.white} lineHeight="12px">
                            {offlineStore.isOpenForBusiness ? t('store.open') : t('store.ready')}
                        </BodyText>
                    </Box>
                </VStack>
            </VStack>
        </Link>
    );
};

export default OfflineStoreCardC;
