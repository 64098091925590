import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { Design } from '@type/harmony-config';
import { Selectable } from '@type/theme';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import SubMenuD from './designD/sub-menu';

interface SubMenuProps {
    channelComponent: ChannelComponent;
}

export interface SubMenuInnerProps extends SubMenuProps {
    subMenuItems: Selectable<number>[];
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
    isLoading?: boolean;
    componentItems: ChannelComponentItem[];
}

const SubMenu: React.FC<SubMenuProps> = (props) => {
    const Component = useDesignComponents<ComponentType<SubMenuInnerProps>>({
        [Design.DesignD]: SubMenuD,
    });

    const { designSettings } = useHarmony();

    const items = useMemo(() => props.channelComponent.items.map((item, index) => ({ label: item.displayName, value: index })), [props.channelComponent.items]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [componentItems, setComponentItems] = useState<ChannelComponentItem[]>(props.channelComponent.items);

    useEffect(() => {
        const item = componentItems[selectedIndex];
        if (item.pendingProducts) {
            (async () => {
                setIsLoading(true);
                const updatedItems = [...componentItems];
                await item.updatePendingProducts(designSettings?.isStockBadgeOn);
                updatedItems[selectedIndex] = item;
                setComponentItems(updatedItems);
                setIsLoading(false);
            })();
        }
    }, [selectedIndex]);

    return <Component subMenuItems={items} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} isLoading={isLoading} componentItems={componentItems} {...props} />;
};

export default SubMenu;
