import { Box, Grid, VStack } from '@chakra-ui/react';
import DesignedContainer from '@designs/primitives/common/designed-container';
import ImageMenuCell from '@designs/primitives/main/image-menu/image-menu-cell';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { useHarmony } from '@hooks/use-harmony';
import { ImageMenuProps } from '..';

const ImageMenuD: React.FC<ImageMenuProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <VStack as="section" spacing="8px" pt="8px" pb="16px" align="stretch">
            <MainSectionTitle title={channelComponent.title} subTitle={channelComponent.subtitle} />
            <Box px="16px">
                <DesignedContainer p="8px">
                    <Grid templateColumns="repeat(2, 1fr)" gap="8px">
                        {channelComponent.items.map((item, index) => (
                            <ImageMenuCell key={index} pagePath={item.path} imageUrl={item.image} title={item.imageOverlayText} ratio={channelComponent.imageRatio} />
                        ))}
                    </Grid>
                </DesignedContainer>
            </Box>
        </VStack>
    );
};

export default ImageMenuD;
