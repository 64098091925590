import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import IconMenuD from './designD/icon-menu';

export interface IconMenuProps {
    channelComponent: ChannelComponent;
}

const IconMenu: React.FC<IconMenuProps> = (props) => {
    const Component = useDesignComponents<ComponentType<IconMenuProps>>({
        [Design.DesignD]: IconMenuD,
    });

    return <Component {...props} />;
};

export default IconMenu;
