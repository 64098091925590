import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import BrandCardD from './designD/brand-card';

export interface BrandCardProps extends BoxProps {
    componentItem: ChannelComponentItem;
}

const BrandCard: React.FC<BrandCardProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BrandCardProps>>({
        [Design.DesignD]: BrandCardD,
    });

    return (
        <CustomLink href={{ pathname: RoutesEnum.BrandDetail, query: { brandId: props.componentItem.brandId } }}>
            <Component {...props} />
        </CustomLink>
    );
};

export default BrandCard;
