import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { NoWrapText, NoWrapTextContainer, PaddedContainer } from '@styles/styled-components';
import React from 'react';
import { HomeComponentHeadingProps } from '../home-component-heading';

const HomeComponentHeadingC: React.FC<HomeComponentHeadingProps> = ({ title, subtitle, children }) => {
    const { theme } = useHarmony();

    return (
        <Wrapper padding="10px 16px">
            <NoWrapTextContainer>
                <NoWrapText size={'20px'} weight={'bold'} lineHeight={'1'}>
                    {title}
                </NoWrapText>
            </NoWrapTextContainer>
            {subtitle && (
                <BodyText size={'16px'} color={theme.gray[600]}>
                    {subtitle}
                </BodyText>
            )}
            {children}
        </Wrapper>
    );
};

export default HomeComponentHeadingC;

const Wrapper = styled(PaddedContainer)`
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;
