import styled from '@emotion/styled';

const FixedBannerB = styled.div<{ width: string; height: string }>`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 16px;
    overflow: hidden;
`;

export default FixedBannerB;
