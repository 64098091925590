import { Swiper, SwiperSlide } from 'swiper/react';
import LegacyPagination from '@legacy/designs/legacy-pagination';
import styled from '@emotion/styled';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import Link from 'next/link';
import { useWindowSize } from '@hooks/use-window-size';
import { VerticalDivider } from '@styles/styled-components';
import CustomButton from '@legacy/designs/custom-button/custom-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from 'src/legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import { ImageContainer } from '..';
import CustomImage from 'src/legacy/components/common/custom-image';
import ThemeUtils from '@utils/theme.utils';
import { useGATracking } from '@hooks/use-ga-tracking';
import CustomLink from '@components/custom-link';

const CLOSE_BUTTON_HEIGHT = 40;
const CUSTOM_BUTTON_HEIGHT = 48;
const PADDING = 16 * 2;

const CenterFloatingPopupC: React.FC<PopupProps> = ({ useButton, buttonName, imageRatio, items, handleIgnoreForToday, onClose }) => {
    const { theme } = useHarmony();
    const { logEvent } = useGATracking();
    const [width, height] = useWindowSize({ maxWidth: true });
    const [index, setIndex] = useState<number>(0);
    const t = useTranslations();

    const buttonHeight = useButton ? CLOSE_BUTTON_HEIGHT + CUSTOM_BUTTON_HEIGHT : CLOSE_BUTTON_HEIGHT;

    const popupWidth = useMemo(() => {
        if (height - buttonHeight < (width - PADDING) * imageRatio) return (height - buttonHeight) / imageRatio;
        return width;
    }, [width, height, imageRatio, buttonHeight]);

    return (
        <BlockHStack align="center" alignItems="center" width="100%" height="100%" spacing="none">
            <PopUpContents>
                <SwiperContainer width={popupWidth - 32} height={(popupWidth - 32) * imageRatio}>
                    <Swiper
                        loop={items.length > 1}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        onSlideChange={(swiper) => {
                            width && setIndex(swiper.realIndex);
                        }}
                    >
                        {items.map((popupItem, i) => (
                            <SwiperSlide key={i}>
                                {popupItem.image && (
                                    <Link href={popupItem.linkPageUrl}>
                                        <a>
                                            <ImageContainer width={`${popupWidth - 32}px`} height={`${(popupWidth - 32) * imageRatio}px`}>
                                                <CustomImage
                                                    src={popupItem.image}
                                                    layout="fill"
                                                    objectFit="cover"
                                                    onClick={() => {
                                                        logEvent('select_promotion', {
                                                            promotion_name: popupItem.linkPageUrl,
                                                            items: [],
                                                        });
                                                    }}
                                                />
                                            </ImageContainer>
                                        </a>
                                    </Link>
                                )}
                            </SwiperSlide>
                        ))}
                        {items.length > 1 && <LegacyPagination index={index} total={items.length} />}
                    </Swiper>
                </SwiperContainer>
                <BlockVStack width="100%" spacing="2px">
                    {useButton && (
                        <CustomLink href={items[index].linkPageUrl}>
                            <CustomButton
                                full
                                color={theme.primary.default}
                                onClick={() => {
                                    logEvent('select_promotion', {
                                        promotion_name: items[index].linkPageUrl,
                                        items: [],
                                    });
                                }}
                            >
                                {buttonName || t('home.goDetailsPage')}
                            </CustomButton>
                        </CustomLink>
                    )}
                    <BlockHStack align="distribute" alignItems="center" width="100%" height="40px" spacing="none">
                        <PopUpButton onClick={handleIgnoreForToday} width="50%">
                            <BodyText color={theme.white}>{t('home.ignoreForToday')}</BodyText>
                        </PopUpButton>
                        <VerticalDivider theme={theme} width="1px" height="18px" color={theme.white} />
                        <PopUpButton onClick={onClose} width="50%">
                            <BodyText color={theme.white}>{t('common.close')}</BodyText>
                        </PopUpButton>
                    </BlockHStack>
                </BlockVStack>
            </PopUpContents>
        </BlockHStack>
    );
};

export default CenterFloatingPopupC;

const PopUpContents = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    gap: 8px;
    padding: 16px;
    & div {
        z-index: ${ThemeUtils.zIndex.alert};
    }
`;

const SwiperContainer = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: 20px;
    overflow: hidden;
`;

const PopUpButton = styled.button<{ width: string }>`
    width: ${({ width }) => width};
    background-color: transparent;
    color: #fff;
    z-index: ${ThemeUtils.zIndex.alert};
`;
