import { useDesignComponents } from '@hooks/use-design-components';

import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';
import Popup from '@models/popup';
import { IPopupDto } from '@models/popup/responseDto/popup.dto';
import safeWindow from '@services/safe-window.service';
import { Design } from '@type/harmony-config';
import moment from 'moment';
import { ComponentType, useCallback, useEffect, useMemo } from 'react';
import PopUpD from './designD/pop-up';

const POPUP_EXPIRY_STORAGE_KEY = 'popup_expiry';

interface PopUpProps {
    popupDto: IPopupDto;
}

export interface PopUpInnerProps {
    popup: Popup;
    disclosure: UseDisclosureReturn;
    onIgnoreForToday: () => void;
}

const PopUp: React.FC<PopUpProps> = (props) => {
    const Component = useDesignComponents<ComponentType<PopUpInnerProps>>({
        [Design.DesignD]: PopUpD,
    });

    const popup = useMemo(() => new Popup(props.popupDto), [props.popupDto]);
    const disclosure = useDisclosure();

    const handleIgnoreForToday = useCallback(() => {
        const newExpiryDate = moment(Date()).endOf('d');
        localStorage.setItem(POPUP_EXPIRY_STORAGE_KEY, String(newExpiryDate));
        disclosure.onClose();
    }, [disclosure]);

    useEffect(() => {
        const expiryDate = safeWindow?.localStorage.getItem(POPUP_EXPIRY_STORAGE_KEY);
        const today = new Date();

        if (expiryDate && moment(expiryDate).isAfter(today)) {
            return;
        }

        disclosure.onOpen();
    }, []);

    return <Component {...props} popup={popup} disclosure={disclosure} onIgnoreForToday={handleIgnoreForToday} />;
};

export default PopUp;
