import { Box, HStack, VStack } from '@chakra-ui/react';
import RatioBox from '@components/ratio-box';
import CustomImage from '../common/custom-image';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '../common/text/body-text';
import StarRating from '@legacy/designs/star-rating';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import ChannelComponent from '@models/channel-component';
import DomainUtils from '@utils/domain.utils';
import SliderBox from '@designs/primitives/common/slider-box';
import Link from 'next/link';

interface ReviewProps {
    channelComponent: ChannelComponent;
}

const ReviewComponent: React.FC<ReviewProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    if (!DomainUtils.isToyplus) return <></>;

    return (
        <Box w="100%" py="16px">
            <HomeComponentHeading title={title} />
            <SliderBox padding="0px 16px">
                <HStack align="flex-start" spacing="none">
                    {reviewList.map((review, i) => (
                        <Link href={'https://toyplus-stage.travelflan.com/product-detail?productId=9cee65e0-79fc-4a56-927a-d4f7bf88e8c6'} key={review.id}>
                            <Box key={review.id} w="256px" h="unset" flexShrink={0} mr="16px" userSelect="none" cursor="pointer">
                                <RatioBox
                                    ratio={1}
                                    pos="relative"
                                    borderRadius="16px"
                                    overflow="hidden"
                                    border="1px solid"
                                    borderColor={theme.gray[200]}
                                    boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                                >
                                    <CustomImage src={review.image} layout="fill" objectFit="cover" />
                                </RatioBox>
                                <VStack mt="8px" mb="6px" spacing={0} align="flex-start">
                                    <BodyText size="16px" lineHeight="18px" weight="bold" lineClamp={1} ellipsis>
                                        {review.productTitle}
                                    </BodyText>
                                    <HStack spacing="8px">
                                        <StarRating averageRating={review.score} color="gold" />
                                        <BodyText size="14px" color={theme.gray[700]} weight="regular">
                                            {review.userNickname}
                                        </BodyText>
                                    </HStack>
                                </VStack>
                                <BodyText size="15px" lineHeight="18px" weight="regular" color={theme.gray[900]} lineClamp={3} ellipsis>
                                    {review.comments}
                                </BodyText>
                            </Box>
                        </Link>
                    ))}
                </HStack>
            </SliderBox>
        </Box>
    );
};

export default ReviewComponent;

const title = '이달의 베스트 포토리뷰';
const reviewList = [
    {
        id: 1,
        productId: '',
        image: 'https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016579710ko.jpg',
        productTitle: '[모공각질] 넘버즈인 4번 모공제로 깐달걀 토너패드 70매 기획 (+10매 추가 증정)',
        userNickname: 'apple123',
        score: 1,
        comments: '너무 만족하며 사용하고 있는 클렌징오일이에요. 피부에 맞지 않는 오일쓰면 사용 후 가려움 생기는데 넘버즈원 클렌징오일은 잘맞고 만족하며 사용중입니다. 추천해요:)',
    },
    {
        id: 2,
        productId: '',
        image: 'https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016579708ko.jpg',
        productTitle: '에스티 로더 뉴트리셔스 2-in-1 폼 클렌저',
        userNickname: 'banana123',
        score: 2,
        comments:
            '이미 세통 비웠구 또 사둔 제품이에요- 개인적으로 마녀공장 오일이 너무 무거운 느낌ㅠㅠ이라서 그닥 선호하지 않는데 요건 사용감도 산뜻하고 세정력도 마음에 들어서 완전 정착했어요! 마스카라도 싹 다 지워지고 아주 좋음! (두 번 세 번 덧 바른 건 조금 남긴 합니다) 바비브라운 클렌징 오일이랑 사용감이나 향도 되게 비슷해요 딱히 바꿀 생각 없습니당 요걸로 쭉 정착 할 듯?!',
    },
    {
        id: 3,
        productId: '',
        image: 'https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016579708ko.jpg',
        productTitle: '아벤느 클리낭스 클렌징 젤 400ml *피지잡는 *약산성클렌저',
        userNickname: 'kiwi123',
        score: 3,
        comments: '순해서 사용후 자극이 없어 좋습니다.',
    },
    {
        id: 4,
        productId: '',
        image: 'https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016579708ko.jpg',
        productTitle: '그레이멜린 카놀라크레이지 클렌징오일 더블 기획',
        userNickname: 'orange123',
        score: 4,
        comments: '정말 잘 쓰고 있는 제품이에요! 민감성 피부인데 자극도 없고 용량도 많아서 좋아요 화장도 아주 잘 지워지고 오일도 무겁지 않아서 데일리로 쓰기 좋은 거 같아요',
    },
    {
        id: 5,
        productId: '',
        image: 'https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0018/A00000018551227ko.jpg',
        productTitle: '파파레서피 효소 파우더 클렌저 50g 3종 택1',
        userNickname: 'melon123',
        score: 5,
        comments:
            '1등 클렌징오일이라고 하는 이유가 있네요! 자극없이 부드럽게 롤링되고 대용량버전이 있어서 좋아요!! 피지가 롤링하면서 알갱이처럼 쏙쏙 뽑히네요!! 얼굴도 안답답해요!',
    },
];
