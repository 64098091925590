import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import PaginatedControls from '@legacy/designs/paginated-controls';
import { FixedRankListProps } from '..';
import Link from 'next/link';
import RoutesEnum from '@routes';
import { HorizPadContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import BodyText from 'src/legacy/components/common/text/body-text';

const FixedRankListB: React.FC<FixedRankListProps> = ({ productList, page, totalPage, itemsPerPage, prev, next }) => {
    const { theme } = useHarmony();

    return (
        <RankListContainer>
            {productList?.map((product, i) => (
                <Link
                    href={{
                        pathname: RoutesEnum.ProductDetail,
                        query: { productId: product.id },
                    }}
                    key={i.toString()}
                >
                    <HorizPadContainer padding="12.5px">
                        <RankItemContainer theme={theme} isLast={i + 1 === productList.length}>
                            <RankItem>
                                <BodyText size="18px" weight="extraBold" font="secondary" color={theme.primary.default}>
                                    {itemsPerPage * (page - 1) + (i + 1)}.
                                </BodyText>{' '}
                                <BodyText size="18px">{product.title}</BodyText>
                            </RankItem>
                        </RankItemContainer>
                    </HorizPadContainer>
                </Link>
            ))}
            {totalPage > 1 && <PaginatedControls margin="11px 0 12px" active={page - 1} total={totalPage} prev={prev} next={next} />}
        </RankListContainer>
    );
};

export default FixedRankListB;

const RankListContainer = styled.div`
    margin: 0 16px;
    padding-bottom: 4px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

const RankItemContainer = styled.div<IThemeProps & { isLast: boolean }>`
    padding: 21px 0;
    border-bottom: ${({ isLast, theme }) => (isLast ? 'none' : `1px solid ${theme.gray[50]}`)};
`;

const RankItem = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
