import { Box, Center, Drawer, DrawerContent, DrawerOverlay, HStack, Modal, ModalContent, ModalOverlay, VStack } from '@chakra-ui/react';
import BottomButtons from '@designs/grouped/common/bottom-buttons';
import MainButton from '@designs/primitives/common/button/main-button';
import CounterBar from '@designs/primitives/common/counter-bar';
import ImgCounter from '@designs/primitives/common/img-counter';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { PopupPosition } from '@models/popup/responseDto/popup.dto';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PopUpInnerProps } from '..';
import { useExtension } from '@hooks/use-extension';

const PopUpD: React.FC<PopUpInnerProps> = ({ popup, disclosure, onIgnoreForToday }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const { extensionStatus } = useExtension();

    const [index, setIndex] = useState<number>(0);

    const hasMultiItems = popup.items.length > 1;

    const popupHeader = useMemo(
        () => (
            <HStack justify="space-between" p="8px 16px">
                <Body2C color={theme.text.white} onClick={onIgnoreForToday}>
                    {t('home.ignoreForToday')}
                </Body2C>
                <Body2C color={theme.text.white} onClick={disclosure.onClose}>
                    {t('common.close')}
                </Body2C>
            </HStack>
        ),
        [disclosure.onClose, onIgnoreForToday, t, theme.text.white]
    );

    const imageContent = useMemo(
        () => (
            <>
                <Swiper
                    spaceBetween={8}
                    allowTouchMove={hasMultiItems}
                    loop={hasMultiItems}
                    autoplay={hasMultiItems && { delay: 5000, disableOnInteraction: false }}
                    onSlideChange={(swiper) => setIndex(swiper.realIndex)}
                >
                    {popup.items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <ComponentImage src={item.image} ratio={popup.imageRatio} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {hasMultiItems && <ImgCounter index={index} total={popup.items.length} />}
                {hasMultiItems && (
                    <Center pos="absolute" bottom="10px" left="0" right="0" zIndex={ThemeUtils.zIndex.floating}>
                        <CounterBar currentCount={index + 1} maxCount={popup.items.length} />
                    </Center>
                )}
            </>
        ),
        [hasMultiItems, index, popup.imageRatio, popup.items]
    );

    const detailButton = useMemo(
        () =>
            popup.useButton && (
                <MainButton size="s" href={popup.items[index].linkPageUrl}>
                    {popup.buttonName}
                </MainButton>
            ),
        [index, popup.buttonName, popup.items, popup.useButton]
    );

    return (
        <>
            {popup.position === PopupPosition.CenterFloating && (
                <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} closeOnOverlayClick={false} isCentered>
                    <ModalOverlay />
                    <ModalContent px="16px" bg="none" boxShadow="none">
                        {popupHeader}
                        <VStack spacing="12px" align="stretch">
                            <Box pos="relative" borderRadius="4px" overflow="hidden" boxShadow={theme.dropShadow.dropdown}>
                                {imageContent}
                            </Box>
                            {detailButton}
                        </VStack>
                    </ModalContent>
                </Modal>
            )}
            {popup.position === PopupPosition.BottomSticky && (
                <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} closeOnOverlayClick={false} placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent bg="none" w="auto" mx={`max(calc((100vw - ${extensionStatus?.maxScreenWidth}px) / 2), 0px)`}>
                        <VStack spacing="0" align="stretch">
                            {popupHeader}
                            <Box pos="relative" borderTopRadius="4px" overflow="hidden" bgColor={theme.background.white} maxH="80vh">
                                {imageContent}
                            </Box>
                            <BottomButtons>{detailButton}</BottomButtons>
                        </VStack>
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};

export default PopUpD;
