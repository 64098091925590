import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import DividerD from './designD/divider';

export interface DividerProps {
    channelComponent: ChannelComponent;
}

const Divider: React.FC<DividerProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DividerProps>>({
        [Design.DesignD]: DividerD,
    });

    return <Component {...props} />;
};

export default Divider;
