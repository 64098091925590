import { Box } from '@chakra-ui/react';
import { SwipeProductCardProps } from '..';
import FixedProductCard from '../../fixed-product-card';

const SwipeProductCardD: React.FC<SwipeProductCardProps> = ({ ...props }) => {
    return (
        <Box flexShrink="0" w="100%" maxW="138px">
            <FixedProductCard {...props} />
        </Box>
    );
};

export default SwipeProductCardD;
