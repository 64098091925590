import { useDesignComponents } from '@hooks/use-design-components';

import { RatioBoxProps } from '@components/ratio-box';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ComponentImageD from './designD/component-image';

export interface ComponentImageProps extends RatioBoxProps {
    src?: string;
    alt?: string;
}

const ComponentImage: React.FC<ComponentImageProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ComponentImageProps>>({
        [Design.DesignD]: ComponentImageD,
    });

    if (!props.src) {
        return <></>;
    }

    return <Component {...props} />;
};

export default ComponentImage;
