import ProductSellableSku from '@models/grouped-product/product-sellable-sku';
import Product from '@models/product';
import Transaction from '@models/transaction';
import EnvironmentUtils from '@utils/environment.utils';

class AcrossService {
    static get isActivated() {
        return EnvironmentUtils.isStaging || EnvironmentUtils.isProduction;
    }

    static orderSuccessAnalytics(transaction: Transaction) {
        if (!this.isActivated) {
            return;
        }

        /** 빅데이터 활용 스크립트 */
        try {
            window.adn_order_items_param = window.adn_order_items_param || [];
            window.adn_order_items_param = window.adn_order_items_param.concat(
                transaction.transactionSkus.map((sku) => [
                    {
                        ut: 'PurchaseItems',
                        ui: EnvironmentUtils.ACROSS_ADN_ID,
                        items: [
                            {
                                i: sku.sellableProductId,
                                a: `${sku.quantity}`,
                                p: `${sku.sellPrice}`,
                            },
                        ],
                    },
                ])
            );

            if (typeof fn_across_adn_contain !== 'undefined') {
                const across_adn_order_contain = new (fn_across_adn_contain as any)();
                const across_adn_order_param = {
                    ut: 'Purchase',
                    ui: EnvironmentUtils.ACROSS_ADN_ID,
                    uo: transaction.sn,
                    up: `${transaction.paymentInfo.totalProductAmountWithDeliveryFee}`,
                };
                across_adn_order_contain.init(across_adn_order_param);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static cartAnalytics(products: ProductSellableSku[]) {
        if (!this.isActivated) {
            return;
        }

        const across_adn_cart_contain = new (fn_across_adn_contain as any)();
        const across_adn_cart_param = {
            ut: 'Cart',
            ui: EnvironmentUtils.ACROSS_ADN_ID,
            items: products.map((item) => ({
                i: item.sellableProductId,
                c: '',
                s: item.title,
                q: `${item.quantity}`,
                p: `${item.sellPrice * item.quantity}`,
            })),
        };
        across_adn_cart_contain.init(across_adn_cart_param);
    }

    static productDetailAnalytics(product: Product) {
        if (!this.isActivated) {
            return;
        }

        if (typeof fn_across_adn_contain !== 'undefined') {
            const across_adn_item_contain = new (fn_across_adn_contain as any)();
            const across_adn_item_param = {
                ut: 'Item',
                ui: EnvironmentUtils.ACROSS_ADN_ID,
                items: { i: product.id, s: product.title },
            };
            across_adn_item_contain.init(across_adn_item_param);
        }
    }

    static visitAnalytics() {
        if (!this.isActivated) {
            return;
        }

        if (typeof fn_across_adn_contain !== 'undefined') {
            const across_adn_contain = new (fn_across_adn_contain as any)();
            const across_adn_param = {
                ut: 'Home',
                ui: EnvironmentUtils.ACROSS_ADN_ID,
            };
            across_adn_contain.init(across_adn_param);
        }
    }

    static signUpAnalytics(params: string[]) {
        if (typeof fn_across_adn_btn_ok !== 'undefined') {
            fn_across_adn_btn_ok(params[0], params[1]);
        }
    }

    static eventParticipateAnalytics(params: string[]) {
        if (typeof fn_across_adn_btn_ok !== 'undefined') {
            fn_across_adn_btn_ok(params[0], params[1]);
        }
    }
}

export default AcrossService;
