import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import UnderlineButton from 'src/legacy/components/common/underline-button';
import Star from '@legacy/designs/star';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { ClickableContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import { StarRatingProps } from '..';

const StarRatingB: React.FC<StarRatingProps> = ({ total, onTotalClick, color, starSize, ...props }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    const starList = (): ReactNode => {
        const result = [];
        for (let i = 0; i < 5; i++) {
            result.push(<Star color={color} active={i <= props.averageRating - 1} key={i} size={starSize || '14px'} />);
        }
        return result;
    };

    return (
        <StyledHStack theme={theme}>
            <div>{starList()}</div>
            {total !== undefined && (
                <ClickableContainer onClick={onTotalClick}>
                    <BlockHStack alignItems="center" spacing="2px" height="24px">
                        <UnderlineButton color={theme.gray[800]} fontSize="14px" weight="medium">
                            {t('review.showReviews', { count: total })}
                        </UnderlineButton>
                        <CustomIcon size="12px" color={theme.gray[600]}>
                            chevron_right
                        </CustomIcon>
                    </BlockHStack>
                </ClickableContainer>
            )}
        </StyledHStack>
    );
};

export default StarRatingB;

const StyledHStack = styled.div<IThemeProps>`
    ${({ theme }) => `
        font-family: ${theme.font.secondary.light};
        font-size: 16px;
        letter-spacing: -0.03em;
        line-height: 100%;
        color: ${theme.gray[600]};
        display: flex;
        align-items: center;
        column-gap: 4px;
    `}
`;

const TotalButton = styled(BodyText)`
    ${({ onClick }) => `
        cursor: ${onClick ? 'pointer' : 'default'};
    `}
`;
