import { OfflineStoreInnerProps } from '..';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useHarmony } from '@hooks/use-harmony';
import { Center, HStack } from '@chakra-ui/react';
import BodyText from '@legacy/components/common/text/body-text';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import SwipeProductListContainer from '@legacy/designs/swipe-product-list-container';
import MoreViewButton from '@legacy/designs/more-view-button.tsx';
import ProductListContainer from '@legacy/designs/product-list-container';
import OfflineStoreCard from '@designs/primitives/main/offline-store/offline-store-card';
import RoutesEnum from '@routes';

const OfflineStoreC: React.FC<OfflineStoreInnerProps> = ({ offlineStores }) => {
    const t = useTranslations();
    const router = useRouter();
    const { theme } = useHarmony();

    return (
        <ProductListContainer>
            <HomeComponentHeading title={t('store.storeInfo')} />

            {!offlineStores?.length && (
                <Center w="100%" py="12px">
                    <BodyText size="15px" lineHeight="22px" color={theme.gray[800]} textAlign="center" whiteSpace="pre-wrap">
                        {t('store.noStores')}
                    </BodyText>
                </Center>
            )}

            {offlineStores && (
                <SwipeProductListContainer>
                    <HStack spacing="8px">
                        {offlineStores.map((offlineStore) => (
                            <OfflineStoreCard key={offlineStore.id} offlineStore={offlineStore} />
                        ))}
                    </HStack>
                </SwipeProductListContainer>
            )}

            <MoreViewButton onClick={() => router.push(RoutesEnum.OfflineStore)} />
        </ProductListContainer>
    );
};

export default OfflineStoreC;
