import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import DetailAD from './designD/detail-a';

export interface DetailAProps extends PartialAbstractedTextProps {}

const DetailA: React.FC<DetailAProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DetailAProps>>({
        [Design.DesignD]: DetailAD,
    });

    return <Component {...props} />;
};

export default DetailA;
