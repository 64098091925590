import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import FixedSubmenu from '@legacy/designs/fixed-submenu';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import MoreViewButton from '@legacy/designs/more-view-button.tsx';
import ProductListContainer from '@legacy/designs/product-list-container';
import Submenu from '@legacy/designs/submenu/submenu';
import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { Design } from '@type/harmony-config';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { BlockHStack, BlockLoader } from 'src/legacy/components/blockui';
import SubmenuFix from './submenu-fix';
import SubmenuSwipe from './submenu-swipe';

interface HomeSubmenuProps {
    channelComponent: ChannelComponent;
}

const HomeSubmenu: React.FC<HomeSubmenuProps> = ({ channelComponent }) => {
    const { designSettings } = useHarmony();
    const [active, setActive] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [componentItems, setComponentItems] = useState<ChannelComponentItem[]>(channelComponent.items);

    const containerPadding = useDesignComponents<string>({
        [Design.DesignA]: '16px 0 32px',
        [Design.DesignB]: '16px 0',
        [Design.DesignC]: '10px 0 16px',
    });
    const submenuMargin = useDesignComponents<string>({
        [Design.DesignC]: '4px 0 0 0',
    });

    useEffect(() => {
        const item = componentItems[active];
        if (item.pendingProducts) {
            (async () => {
                setIsLoading(true);
                const updatedItems = [...componentItems];
                await item.updatePendingProducts(designSettings?.isStockBadgeOn);
                updatedItems[active] = item;
                setComponentItems(updatedItems);
                setIsLoading(false);
            })();
        }
    }, [active]);

    const viewMorePagePath: string | undefined = channelComponent.items[active]?.pageUrl;

    return (
        <ProductListContainer padding={containerPadding}>
            <HomeComponentHeading title={channelComponent.title} />
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                <Submenu items={channelComponent.items.map((item) => item.displayName)} index={active} onSelect={setActive} margin={submenuMargin} />
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <FixedSubmenu items={channelComponent.items.map((item) => item.displayName)} index={active} onSelect={setActive} />
            )}
            {isLoading && (
                <BlockHStack align="center" pt="16px">
                    <BlockLoader />
                </BlockHStack>
            )}
            {!isLoading && (
                <>
                    {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && <SubmenuSwipe component={channelComponent} item={componentItems[active]} />}
                    {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && <SubmenuFix component={channelComponent} item={componentItems[active]} />}
                </>
            )}
            {designSettings?.isDesignC && viewMorePagePath && (
                <Link href={viewMorePagePath}>
                    <a>
                        <MoreViewButton />
                    </a>
                </Link>
            )}
        </ProductListContainer>
    );
};

export default HomeSubmenu;
