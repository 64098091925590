import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import ProductCardImage from '@legacy/designs/product-card-image';
import ProductPrice from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { LineClipText, PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import ProductBadges from 'src/legacy/components/product/product-badges';
import RoutesEnum from 'src/routes/routes';

interface SwipeListProductProps {
    product: ComponentProduct;
}

const SwipeListProductDesignA: React.FC<SwipeListProductProps> = ({ product }) => {
    const { theme } = useHarmony();

    return (
        <Link
            href={{
                pathname: RoutesEnum.ProductDetail,
                query: { productId: product.id },
            }}
        >
            <AccordionRankedProduct theme={theme}>
                <ImageContainer theme={theme}>
                    {product.image && <ProductCardImage disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                    <WishlistContainer>
                        <WishListButton className="heart" size="20px" product={product} />
                    </WishlistContainer>
                </ImageContainer>
                <ContentContainer isBadgeInList={product.isSoldOut || product.timesaleAppliedNow}>
                    <LineClipText color={theme.gray[800]} size="18px" lines={1}>
                        {product.showBrandOnProductCard && (
                            <BodyText size="18px" lineHeight="20px" color={theme.primary.default}>
                                [{product.brand}]{' '}
                            </BodyText>
                        )}
                        {product.title}
                    </LineClipText>
                    <ProductPrice compact product={product} />
                    <PaddedContainer padding="6px 0 0 0">
                        <TextBadge />
                    </PaddedContainer>
                </ContentContainer>
                <BadgeContainer>
                    <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
                </BadgeContainer>
            </AccordionRankedProduct>
        </Link>
    );
};

export default SwipeListProductDesignA;

const AccordionRankedProduct = styled('div')<IThemeProps>`
    display: flex;
    margin: 0 1rem;
    position: relative;
    gap: 4px;
`;
const ImageContainer = styled.div`
    position: relative;
    width: 86px;
    height: 86px;
    flex-shrink: 0;
`;
const WishlistContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
`;

const ContentContainer = styled.div<{ isBadgeInList: boolean }>`
    display: flex;
    flex-direction: column;
    margin-right: ${({ isBadgeInList }) => (isBadgeInList ? '50px' : '2px')};
`;

const BadgeContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 8px;
`;
