import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import PaginatedControls from '@legacy/designs/paginated-controls';
import { FixedRankListProps } from '..';
import Link from 'next/link';
import RoutesEnum from '@routes';
import { HorizPadContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import BodyText from 'src/legacy/components/common/text/body-text';

const FixedRankListA: React.FC<FixedRankListProps> = ({ productList, page, totalPage, itemsPerPage, prev, next }) => {
    const { theme } = useHarmony();

    return (
        <RankListContainer>
            {productList?.map((product, i) => (
                <Link
                    href={{
                        pathname: RoutesEnum.ProductDetail,
                        query: { productId: product.id },
                    }}
                    key={i.toString()}
                >
                    <HorizPadContainer padding="12px">
                        <RankItemContainer theme={theme} isLast={i + 1 === productList.length}>
                            <RankItem>
                                <RankNumber>
                                    <BodyText size="18px" weight="bold" color={theme.primary.default}>
                                        {itemsPerPage * (page - 1) + (i + 1)}.
                                    </BodyText>
                                </RankNumber>
                                <BodyText size="17px" color={theme.gray[800]}>
                                    {product.title}
                                </BodyText>
                            </RankItem>
                        </RankItemContainer>
                    </HorizPadContainer>
                </Link>
            ))}
            {totalPage > 1 && <PaginatedControls margin="11px 0 12px" active={page - 1} total={totalPage} prev={prev} next={next} />}
        </RankListContainer>
    );
};

export default FixedRankListA;

const RankListContainer = styled.div`
    margin: 0 16px;
    padding-bottom: 4px;
    background-color: #ffffff;
`;

const RankItemContainer = styled.div<IThemeProps & { isLast: boolean }>`
    padding: 12px 0;
    border-bottom: ${({ isLast, theme }) => (isLast ? 'none' : `1px solid ${theme.gray[50]}`)};
`;

const RankItem = styled.div`
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const RankNumber = styled.div`
    min-width: 24px;
`;
