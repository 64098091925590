import { Swiper, SwiperSlide } from 'swiper/react';
import LegacyPagination from '@legacy/designs/legacy-pagination';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import Link from 'next/link';
import { useWindowSize } from '@hooks/use-window-size';
import CustomButton from '@legacy/designs/custom-button/custom-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from 'src/legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import { ImageContainer } from '..';
import CustomImage from 'src/legacy/components/common/custom-image';
import ThemeUtils from '@utils/theme.utils';
import { useGATracking } from '@hooks/use-ga-tracking';

const CLOSE_BUTTON_HEIGHT = 40;
const CUSTOM_BUTTON_HEIGHT = 80;
const PADDING = 16 * 2;

const CenterFloatingPopupA: React.FC<PopupProps> = ({ useButton, buttonName, imageRatio, items, handleIgnoreForToday, onClose }) => {
    const { theme } = useHarmony();
    const { logEvent } = useGATracking();
    const [width, height] = useWindowSize({ maxWidth: true });
    const [index, setIndex] = useState<number>(0);
    const t = useTranslations();

    const buttonHeight = useButton ? CLOSE_BUTTON_HEIGHT + CUSTOM_BUTTON_HEIGHT : CLOSE_BUTTON_HEIGHT;

    const popupWidth = useMemo(() => {
        if (height - buttonHeight < (width - PADDING) * imageRatio) return (height - buttonHeight) / imageRatio;
        return width;
    }, [width, height, imageRatio, buttonHeight]);

    return (
        <BlockHStack align="center" alignItems="center" width="100%" height="100%" spacing="none">
            <PopUpContents>
                <BlockHStack align="distribute" alignItems="center" width="100%" height="40px" spacing="none">
                    <PopUpButton onClick={handleIgnoreForToday}>
                        <BodyText color={theme.white} lineHeight="18px" size="16px">
                            {t('home.ignoreForToday')}
                        </BodyText>
                    </PopUpButton>
                    <PopUpButton onClick={onClose}>
                        <CustomIcon color={theme.white} size="24px">
                            close
                        </CustomIcon>
                    </PopUpButton>
                </BlockHStack>
                <SwiperContainer width={popupWidth - PADDING} height={(popupWidth - PADDING) * imageRatio}>
                    <Swiper
                        loop={items.length > 1}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        onSlideChange={(swiper) => {
                            width && setIndex(swiper.realIndex);
                        }}
                    >
                        {items.map((popupItem, i) => (
                            <SwiperSlide key={i}>
                                {popupItem.image && (
                                    <Link href={popupItem.linkPageUrl}>
                                        <a>
                                            <ImageContainer width={`${popupWidth - PADDING}px`} height={`${(popupWidth - PADDING) * imageRatio}px`}>
                                                <CustomImage
                                                    src={popupItem.image}
                                                    layout="fill"
                                                    objectFit="cover"
                                                    onClick={() => {
                                                        logEvent('select_promotion', {
                                                            promotion_name: popupItem.linkPageUrl,
                                                        });
                                                    }}
                                                />
                                            </ImageContainer>
                                        </a>
                                    </Link>
                                )}
                            </SwiperSlide>
                        ))}
                        {items.length > 1 && <LegacyPagination index={index} total={items.length} />}
                    </Swiper>
                </SwiperContainer>
                {useButton && (
                    <DetailButtonContainer>
                        <Link href={items[index].linkPageUrl}>
                            <CustomButton full color={theme.primary.default} onClick={() => logEvent('select_promotion', { promotion_name: items[index].linkPageUrl })}>
                                {buttonName || t('home.goDetailsPage')}
                            </CustomButton>
                        </Link>
                    </DetailButtonContainer>
                )}
            </PopUpContents>
        </BlockHStack>
    );
};

export default CenterFloatingPopupA;

const PopUpContents = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    padding: 16px;
    & div {
        z-index: ${ThemeUtils.zIndex.alert};
    }
`;

const SwiperContainer = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`;

const PopUpButton = styled.button`
    background-color: transparent;
    color: #fff;
    padding: 0 16px;
    z-index: ${ThemeUtils.zIndex.alert};
`;

const DetailButtonContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 16px;
`;
