import { VStack } from '@chakra-ui/react';
import Body2B from '@designs/primitives/typography/body-2-b';
import Title1A from '@designs/primitives/typography/title-1-a';
import { useHarmony } from '@hooks/use-harmony';
import { MainSectionTitleProps } from '..';

const MainSectionTitleD: React.FC<MainSectionTitleProps> = ({ title, subTitle, ...props }) => {
    const { theme } = useHarmony();

    return (
        <VStack as="hgroup" spacing="0px" w="100%" p="8px 16px" bgColor={theme.background.white} {...props}>
            {title && (
                <Title1A as="h1" color={theme.text.title1} textAlign="center" noOfLines={1}>
                    {title}
                </Title1A>
            )}
            {subTitle && (
                <Body2B as="h2" color={theme.text.body2} textAlign="center" noOfLines={2}>
                    {subTitle}
                </Body2B>
            )}
        </VStack>
    );
};

export default MainSectionTitleD;
