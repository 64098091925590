class SanitationUtils {
    /**
     * This function will trim all non-numbers
     */
    static numberOnly(number?: string | number): string {
        const numberOnly = number?.toString().replace(/[^0-9]/g, '') || '';

        return numberOnly;
    }

    /**
     * Ensures commas are added to number strings
     */
    static sanitizeNumberWithCommas(number?: string | number): string {
        const numberOnly = number?.toString().replace(/[^0-9]/g, '');

        const withoutComma = (numberOnly as string).replaceAll(',', '');

        return (+withoutComma).toLocaleString();
    }

    static revertNumberWithCommas(number: string): number {
        const stringToNumber = number.replace(/,/g, '');

        return +stringToNumber;
    }

    static sanitizePhoneNumber(phoneNumber: string): string {
        return phoneNumber.replace(/(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/, '$1-$2-$3');
    }

    static addDashOnCouponCode(couponCode: string): string {
        return couponCode
            .replace(/[^A-Za-z0-9]/g, '')
            .replace(/(.{4})/g, '$1-')
            .replace(/-$/, '');
    }

    static EnglishAndKoreanOnly(name: string): string {
        return name?.replace(/[^(가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z\-)]/gi, '');
    }
}

export default SanitationUtils;
