import styled from '@emotion/styled';

const BannerContainerC = styled.div`
    position: relative;

    .disabled {
        pointer-events: none;
    }
`;

export default BannerContainerC;
