import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { BaseProductCardProps } from '@type/theme';
import { ComponentType } from 'react';
import SwipeProductCardD from './designD/swipe-product-card';
import { withProductCardHOC } from '../with-product-card-hoc';

export interface SwipeProductCardProps extends BaseProductCardProps {}

const SwipeProductCard: React.FC<SwipeProductCardProps> = withProductCardHOC((props) => {
    const Component = useDesignComponents<ComponentType<SwipeProductCardProps>>({
        [Design.DesignD]: SwipeProductCardD,
    });

    return <Component {...props} />;
});

export default SwipeProductCard;
