import { useExtension } from '@hooks/use-extension';
import isMobile from 'is-mobile';
import Script from 'next/script';

const AcrossMainPanel = () => {
    const { acrossSettings } = useExtension();

    if (!acrossSettings) {
        return null;
    }

    return isMobile() ? (
        <>
            <Script id="across-adn-main-panel">{`
                var adn_mobile_pinad_param = adn_mobile_pinad_param || [];
                adn_mobile_pinad_param.push([{
                    ui:'${acrossSettings.panelScripts.mainPanelValue.ui}',	
                    ci:'${acrossSettings.panelScripts.mainPanelValue.ci}',
                    gi:'${acrossSettings.panelScripts.mainPanelValue.gi}'
                }]);
          `}</Script>
            <Script src="//fin.rainbownine.net/js/across_adn_mainpanel_mobile_ad_1.0.4.js"></Script>
        </>
    ) : (
        <>
            <Script id="across-adn-main-panel">{`
                var adn_pinad_param = adn_pinad_param || [];
                    adn_pinad_param.push([{
                    ui:'${acrossSettings.panelScripts.mainPanelValue.ui}',	
                    ci:'${acrossSettings.panelScripts.mainPanelValue.ci}',
                    gi:'${acrossSettings.panelScripts.mainPanelValue.gi}'
                }]);
          `}</Script>
            <Script src="//fin.rainbownine.net/js/across_adn_mainpanel_pc_ad_1.0.5.js"></Script>
        </>
    );
};

export default AcrossMainPanel;
