import { HStack, VStack } from '@chakra-ui/react';
import SwipeProductCard from '@designs/grouped/common/product-card/swipe-product-card';
import DesignedContainer from '@designs/primitives/common/designed-container';
import MoreButton from '@designs/primitives/main/main-section-title/more-button';
import { useGATracking } from '@hooks/use-ga-tracking';
import { SwipeProductCardSectionProps } from '..';

const SwipeProductCardSectionD: React.FC<SwipeProductCardSectionProps> = ({ products, onProductClick, trackItemSelect, viewMoreHref }) => {
    const { logEvent } = useGATracking();

    return (
        <DesignedContainer overflow="hidden">
            <VStack spacing="24px" pb="16px">
                <HStack w="100%" p="16px 16px 0" spacing="8px" overflowX="auto" align="flex-start">
                    {products.map((product) => (
                        <SwipeProductCard key={product.id} product={product} onClick={onProductClick} trackItemSelect={trackItemSelect} />
                    ))}
                </HStack>
                {!!viewMoreHref && <MoreButton pagePath={viewMoreHref} />}
            </VStack>
        </DesignedContainer>
    );
};

export default SwipeProductCardSectionD;
