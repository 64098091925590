import safeWindow from '@services/safe-window.service';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useScrollY } from './use-scroll-y';

type LazyLoad = {
    shouldLoad: boolean;
    ref: RefObject<HTMLDivElement>;
};

/**
 * Hook that indicates whether or not to start loading an item based on a given scroll offset.
 * This will rerender on scroll so it is advised to use memoization for children components of the
 * component that utilizes this hook.
 * @param offset defaults to 1 screen height below the visible part of the page.
 */
export function useLazyLoad(offset?: number): LazyLoad {
    const [shouldLoad, setShouldLoad] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollY = useScrollY();

    useEffect(() => {
        const screenHeight = safeWindow?.screen.height || 0;
        if (!shouldLoad && containerRef.current && containerRef.current.offsetTop <= scrollY + screenHeight + (offset || screenHeight)) {
            setShouldLoad(true);
        }
    }, [shouldLoad, scrollY, containerRef, offset]);

    return useMemo<LazyLoad>(() => ({ ref: containerRef, shouldLoad }), [containerRef, shouldLoad]);
}
