import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { ProductId } from '@type/models';
import { useRouter } from 'next/router';
import RoutesEnum from 'src/routes/routes';
import SwipeRankedProductA from './designA/swipe-ranked-product';
import SwipeRankedProductB from './designB/swipe-ranked-product';
import SwipeRankedProductC from './designC/swipe-ranked-product';

export type SharedProductProps = {
    isDiscounted: boolean;
    onClick: () => void;
};

export type ProductStyleProps = {
    disabled?: boolean;
};

export type RankedProductContainerProps = {
    columnWidth: number;
};

export interface SwipeRankedProductProps extends ProductStyleProps {
    product: ComponentProduct;
    rank?: number;
    width?: number;
    focused?: boolean;
    onClick?: (productId: ProductId) => void;
}

const SwipeRankedProduct: React.FC<SwipeRankedProductProps> = ({ onClick, ...props }) => {
    const Component = useDesignComponents<typeof SwipeRankedProductA | typeof SwipeRankedProductB | typeof SwipeRankedProductC>({
        [Design.DesignA]: SwipeRankedProductA,
        [Design.DesignB]: SwipeRankedProductB,
        [Design.DesignC]: SwipeRankedProductC,
    });
    const router = useRouter();

    return (
        <Component
            onClick={() => {
                if (!props.disabled) {
                    if (!!onClick) {
                        onClick(props.product.id);
                    } else {
                        router.push({
                            pathname: RoutesEnum.ProductDetail,
                            query: { productId: props.product.id },
                        });
                    }
                }
            }}
            {...props}
        />
    );
};

export default SwipeRankedProduct;
