import { Button, Circle, Icon, Text, VStack } from '@chakra-ui/react';
import { useAlert } from '@hooks/use-alert';
import { DataPrepVerificationType, useNicePassModule } from '@hooks/use-nice-pass-module';
import EnvironmentUtils from '@utils/environment.utils';

type PassModuleTestProps = {};

export const PassModuleTester = ({}: PassModuleTestProps) => {
    const { showAlert } = useAlert();

    const { openModuleNiceVerification } = useNicePassModule({
        productIds: [],
        callback: (data) => {
            showAlert({ variant: 'alert', type: 'modal', message: `${data}` });
        },
        key: 'register',
        verificationType: DataPrepVerificationType.Registration,
    });

    return (
        <>
            {EnvironmentUtils.isDev && (
                <Button
                    variant="unstyled"
                    onClick={openModuleNiceVerification}
                    pos="fixed"
                    bottom="120px"
                    left="32px"
                    zIndex="10000"
                    filter="drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4))"
                >
                    <Circle bgColor="crimson" size="48px">
                        <VStack spacing="0px" color="white">
                            <Icon>gpp_good</Icon>
                            <Text fontSize="10px">PASS</Text>
                        </VStack>
                    </Circle>
                </Button>
            )}
        </>
    );
};
