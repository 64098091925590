import { Badge } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { BadgeType, ProductBadgeProps } from '..';
import BadgeA from '@designs/primitives/typography/badge-a';

const BadgeD: React.FC<ProductBadgeProps> = ({ badgeType, children, ...props }) => {
    const { theme } = useHarmony();

    const background = {
        [BadgeType.Special]: theme.background.lighten200,
        [BadgeType.SoldOut]: theme.background.error,
        [BadgeType.Normal]: theme.background.gray100,
        [BadgeType.System1]: theme.background.info,
        [BadgeType.System2]: theme.status.success,
    };

    const color = {
        [BadgeType.Special]: theme.text.body1,
        [BadgeType.SoldOut]: theme.white,
        [BadgeType.Normal]: theme.text.body1,
        [BadgeType.System1]: theme.white,
        [BadgeType.System2]: theme.white,
    };

    return (
        <Badge borderRadius="100px" py="4px" px="6px" height="20px" bgColor={background[badgeType]} {...props}>
            <BadgeA color={color[badgeType]} lineHeight="12px">
                {children}
            </BadgeA>
        </Badge>
    );
};

export default BadgeD;
