import { useDesignComponents } from '@hooks/use-design-components';

import { useFixedSubMenuHeight } from '@hooks/use-sub-menu-height';
import { Design } from '@type/harmony-config';
import { BaseSelectableComponentProps } from '@type/theme';
import { ComponentType, MutableRefObject } from 'react';
import FixedSubMenuD from './designD/fixed-sub-menu';

export interface FixedSubMenuProps<T> extends BaseSelectableComponentProps<T> {}

export interface FixedSubMenuInnerProps<T> extends FixedSubMenuProps<T> {
    containerRef: MutableRefObject<HTMLDivElement | null>;
    cellHeight: string;
}

const FixedSubMenu = <T,>(props: FixedSubMenuProps<T>) => {
    const Component = useDesignComponents<ComponentType<FixedSubMenuInnerProps<T>>>({
        [Design.DesignD]: FixedSubMenuD,
    });

    const { containerRef, cellHeight } = useFixedSubMenuHeight([props.items]);

    return <Component containerRef={containerRef} cellHeight={cellHeight} {...props} />;
};

export default FixedSubMenu;
