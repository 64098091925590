import ComponentProduct from '@models/product/component-product';
import GalleryProductC from '@legacy/designs/gallery-product-list/designC/gallery-product';
import Link from 'next/link';
import RoutesEnum from '@routes';
import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { useHarmony } from '@hooks/use-harmony';

interface SwipeListProductProps {
    product: ComponentProduct;
}

const AccordionRankedProductC: React.FC<SwipeListProductProps> = ({ product }) => {
    const { theme } = useHarmony();

    return (
        <Link
            href={{
                pathname: RoutesEnum.ProductDetail,
                query: { productId: product.id },
            }}
        >
            <ProductWrapper theme={theme}>
                <GalleryProductC product={product} />
            </ProductWrapper>
        </Link>
    );
};

export default AccordionRankedProductC;

const ProductWrapper = styled.div<IThemeProps>`
    padding-bottom: 9px;

    ${({ theme }) => `
      border-bottom: 1px solid ${theme.gray[50]}
    `}
`;
