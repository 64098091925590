import BrandCollectionComponent from '@legacy/designs/brand-collection';
import ChannelComponent from '@models/channel-component';

interface BrandCollectionProps {
    channelComponent: ChannelComponent;
}

const BrandCollection: React.FC<BrandCollectionProps> = ({ channelComponent }) => {
    return <BrandCollectionComponent channelComponent={channelComponent} />;
};

export default BrandCollection;
