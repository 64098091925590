import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { ProductListContainerProps } from '..';

const ProductListContainerB = styled.div<ProductListContainerProps & IThemeProps>`
    width: 100%;
    padding: 16px 0;

    ${({ theme, padding }) => `
      background-color: ${theme.background.config};
      padding: ${padding};
  `}
`;

export default ProductListContainerB;
