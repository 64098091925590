import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ImageListD from './designD/image-list';

export interface ImageListProps {
    channelComponent: ChannelComponent;
}

const ImageList: React.FC<ImageListProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ImageListProps>>({
        [Design.DesignD]: ImageListD,
    });

    return <Component {...props} />;
};

export default ImageList;
