import { NiceModulePrepDataDto } from '@models/customer-identity-verification/responseDto/nice-module-prep-data.dto';

export class NiceModulePrepData {
    private readonly _tokenVersionId: string;
    private readonly _encData: string;
    private readonly _integrityValue: string;
    private readonly _customerId?: string;

    constructor(customerIdentityVerificationDto: NiceModulePrepDataDto) {
        this._tokenVersionId = customerIdentityVerificationDto.tokenVersionId;
        this._encData = customerIdentityVerificationDto.encData;
        this._integrityValue = customerIdentityVerificationDto.integrityValue;
        this._customerId = customerIdentityVerificationDto.customerId;
    }

    get tokenVersionId(): string {
        return this._tokenVersionId;
    }

    get encData(): string {
        return this._encData;
    }

    get integrityValue(): string {
        return this._integrityValue;
    }

    get customerId(): string | undefined {
        return this._customerId;
    }
}
