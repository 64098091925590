import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import ProductCardImage from '@legacy/designs/product-card-image';
import ProductPrice from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { LineClipText, PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import ProductBadges from 'src/legacy/components/product/product-badges';

interface GalleryProductAProps {
    product: ComponentProduct;
}

const GalleryProductA: React.FC<GalleryProductAProps> = ({ product }) => {
    const { theme } = useHarmony();

    return (
        <ProductButton theme={theme}>
            <ImageContainer>
                {product.image && <ProductCardImage disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                <WishlistContainer>
                    <WishListButton size="25px" product={product} />
                </WishlistContainer>
            </ImageContainer>
            <ContentContainer isBadgeInList={product.isSoldOut || product.timesaleAppliedNow}>
                <LineClipText size="16px" lines={2} lineHeight="18px">
                    {product.showBrandOnProductCard && (
                        <BodyText size="16px" lineHeight="18px" color={theme.primary.default}>
                            [{product.brand}]
                        </BodyText>
                    )}
                    {product.title}
                </LineClipText>
                <ProductPrice compact product={product} />
                <PaddedContainer padding="6px 0 0 0">
                    <TextBadge />
                </PaddedContainer>
            </ContentContainer>
            <BadgeContainer>
                <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
            </BadgeContainer>
        </ProductButton>
    );
};

export default GalleryProductA;

const ProductButton = styled('div')<IThemeProps>`
    ${({ theme }) =>
        `display: flex;
    min-width: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    &::nth-last-of-type(1) {
      margin-bottom: 0px;
    }`}
`;

const ImageContainer = styled.div`
    width: 80px;
    height: 80px;
    margin-right: 6px;
    position: relative;
`;

const ContentContainer = styled.div<{ isBadgeInList: boolean }>`
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-right: ${({ isBadgeInList }) => (isBadgeInList ? '50px' : '2px')};
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
`;

const BadgeContainer = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
`;
