import { IOfflineStoreItemDto } from './responseDto/offline-store-item.dto';

class OfflineStoreItem {
    private _id: string;
    private _name: string;
    private _phone: string;
    private _postalCode: string;
    private _baseAddress: string;
    private _detailAddress: string;
    private _imageUrl: string;
    private _isOpenForBusiness: boolean;
    private _latitude: number;
    private _longitude: number;
    private _relativeDistance: number | null;

    constructor(dto: IOfflineStoreItemDto) {
        this._id = dto.id;
        this._name = dto.name;
        this._phone = dto.phone;
        this._postalCode = dto.postalCode;
        this._baseAddress = dto.baseAddress;
        this._detailAddress = dto.detailAddress;
        this._imageUrl = dto.imageUrl;
        this._isOpenForBusiness = dto.isOpenForBusiness;
        this._latitude = dto.latitude;
        this._longitude = dto.longitude;
        this._relativeDistance = dto.relativeDistance;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get phone() {
        return this._phone;
    }

    get postalCode() {
        return this._postalCode;
    }

    get baseAddress() {
        return this._baseAddress;
    }

    get detailAddress() {
        return this._detailAddress;
    }

    get imageUrl() {
        return this._imageUrl;
    }

    get isOpenForBusiness() {
        return this._isOpenForBusiness;
    }

    get latitude() {
        return this._latitude;
    }

    get longitude() {
        return this._longitude;
    }

    get relativeDistance() {
        return this._relativeDistance;
    }

    get relativeDistanceString() {
        if (this._relativeDistance === null) {
            return '-';
        }

        if (this._relativeDistance < 1000) {
            return `${this._relativeDistance}m`;
        }

        return `${Math.round(this._relativeDistance / 1000)}km`;
    }
}

export default OfflineStoreItem;
