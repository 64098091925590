import CustomImage from 'src/legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { AbsoluteContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { NoProductProps } from '..';

const NoProductB: React.FC<NoProductProps> = ({ size = '100%', large }) => {
    const { theme } = useHarmony();
    const imageSize = large ? 200 : 130;
    return (
        <NoProductBContainer size={size} theme={theme}>
            <AbsoluteContainer top="50%" left="50%" transform="translate(-50%, -50%)">
                <ImageContainer size={imageSize}>
                    <CustomImage src="/image/no_product.png" alt="no product" layout="fill" objectFit="contain" />
                </ImageContainer>
            </AbsoluteContainer>
        </NoProductBContainer>
    );
};

const NoProductBContainer = styled.div<NoProductProps & IThemeProps>`
    ${({ size, theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${size};
    padding-bottom: ${size};
    position: relative;
    border: 1px solid ${theme.gray[200]};
    background-color: ${theme.gray[50]};
    border-radius: 16px;
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
`}
`;

const ImageContainer = styled.div<{ size: number }>`
    ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
`}
`;
export default NoProductB;
