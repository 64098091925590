import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { LinkProps } from 'next/link';
import { ComponentType } from 'react';
import SwipeProductCardSectionD from './designD/swipe-product-card-section';

export interface SwipeProductCardSectionProps {
    products: ComponentProduct[];
    onProductClick?: (productId: string) => void;
    viewMoreHref?: LinkProps['href'];
    trackItemSelect?: boolean;
}

const SwipeProductCardSection: React.FC<SwipeProductCardSectionProps> = (props) => {
    const Component = useDesignComponents<ComponentType<SwipeProductCardSectionProps>>({
        [Design.DesignD]: SwipeProductCardSectionD,
    });

    return <Component {...props} />;
};

export default SwipeProductCardSection;
