import { Box } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import CounterBar from '@designs/primitives/common/counter-bar';
import ImgCounter from '@designs/primitives/common/img-counter';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import { useHarmony } from '@hooks/use-harmony';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BannerProps } from '..';
import { useGATracking } from '@hooks/use-ga-tracking';

const BannerD: React.FC<BannerProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const [index, setIndex] = useState<number>(0);
    const { logEvent } = useGATracking();

    if (!channelComponent.items[index]) {
        return <></>;
    }

    return (
        <Box as="section" py="16px" bgColor={theme.background.white}>
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <CustomLink href={channelComponent.items[index].pageUrl || ''}>
                    <Box
                        px="16px"
                        onClick={() => {
                            logEvent('select_promotion', {
                                promotion_name: channelComponent.items[index].pageUrl || '',
                            });
                        }}
                    >
                        <ComponentImage src={channelComponent.items[index].image} ratio={channelComponent.imageRatio} borderRadius="4px" />
                    </Box>
                </CustomLink>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Rol && (
                <Box w="100%" pos="relative">
                    <Swiper
                        loop={true}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        onSlideChange={(swiper) => {
                            setIndex(swiper.realIndex);
                        }}
                        slidesPerView={1.13}
                        centeredSlides
                        spaceBetween={8}
                    >
                        {channelComponent.items.map((item, index) => (
                            <SwiperSlide key={index}>
                                <CustomLink href={item.pageUrl || ''}>
                                    <Box
                                        onClick={() => {
                                            logEvent('select_promotion', {
                                                promotion_name: item.pageUrl || '',
                                            });
                                        }}
                                    >
                                        <ComponentImage src={item.image} ratio={channelComponent.imageRatio} borderRadius="4px" />
                                    </Box>
                                </CustomLink>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <ImgCounter index={index} total={channelComponent.items.length} right="calc(8px + 6.5%)" />
                    <Box mt="12px">
                        <CounterBar currentCount={index + 1} maxCount={channelComponent.items.length} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default BannerD;
