import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { PaginationProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const LegacyPaginationA: React.FC<PaginationProps> = ({ index, total }) => {
    const { theme } = useHarmony();

    return (
        <Pagination theme={theme}>
            <BodyText color={theme.white} weight="bold" size="11px">
                {index + 1}
            </BodyText>
            <BodyText color={theme.gray[400]} size="11px">
                {' '}
                /{' '}
            </BodyText>
            <BodyText color={theme.gray[400]} weight="regular" size="11px">
                {total}
            </BodyText>
        </Pagination>
    );
};

export default LegacyPaginationA;

const Pagination = styled.div<IThemeProps>`
    ${({ theme }) => `
        position: absolute;
        right: 16px;
        bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 19px;
        z-index: ${ThemeUtils.zIndex.above};
        background-color: rgba(0, 0, 0, 0.6);
        color: ${theme.background.config};
        padding: 3px 7px;
        white-space: pre;
    `}
`;
