import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useFixedSubMenuHeight } from '@hooks/use-sub-menu-height';
import { IThemeProps } from '@type/common';
import BodyText from 'src/legacy/components/common/text/body-text';
import { FixedSubmenuProps } from '..';

const FixedSubmenuC: React.FC<FixedSubmenuProps> = ({ items, index, onSelect }) => {
    const { theme } = useHarmony();
    const menuList = items.length % 3 === 0 ? items : items.concat(new Array(3 - (items.length % 3)).fill(null));

    const { containerRef, cellHeight } = useFixedSubMenuHeight([items]);

    return (
        <StyledSubmenu theme={theme} ref={containerRef}>
            {menuList.map((item, i) => {
                const isActive = i === index;
                return (
                    <SubmenuButton key={i} theme={theme} onClick={() => !!item && onSelect(i)} height={cellHeight}>
                        <BodyText
                            width="96px"
                            size="15px"
                            color={isActive ? theme.primary[500] : theme.gray[600]}
                            weight={isActive ? 'bold' : 'regular'}
                            wordBreak={'break-all'}
                            textAlign="center"
                            lineHeight="18px"
                        >
                            {item}
                        </BodyText>
                    </SubmenuButton>
                );
            })}
        </StyledSubmenu>
    );
};

export default FixedSubmenuC;

const StyledSubmenu = styled.div<IThemeProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    ${({ theme }) => `
      background-color: ${theme.white};
      border-top: 1px solid ${theme.gray['50']};
      border-left: 1px solid ${theme.gray['50']};
      > button {
        border-right: 1px solid ${theme.gray['50']};
        border-bottom: 1px solid ${theme.gray['50']};
      }
  `}
`;

const SubmenuButton = styled.button<IThemeProps & { height: string }>`
    display: flex;
    height: ${({ height }) => height};
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 21px 16px;

    ${({ theme }) => `
      background-color: ${theme.white};
  `}
`;
