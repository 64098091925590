import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import Link from 'next/link';
import GalleryListA from './designA/gallery-list';
import GalleryListB from './designB/gallery-list';
import GalleryListC from './designC/gallery-list';
import GalleryProductA from './designA/gallery-product';
import GalleryProductB from './designB/gallery-product';
import GalleryProductC from './designC/gallery-product';

interface GalleryProductListProps {
    products: ComponentProduct[];
}

const GalleryProductList: React.FC<GalleryProductListProps> = ({ products }) => {
    const ListComponent = useDesignComponents<typeof GalleryListA | typeof GalleryListB | typeof GalleryListC>({
        [Design.DesignA]: GalleryListA,
        [Design.DesignB]: GalleryListB,
        [Design.DesignC]: GalleryListC,
    });
    const GalleryProduct = useDesignComponents<typeof GalleryProductA | typeof GalleryProductB | typeof GalleryProductC>({
        [Design.DesignA]: GalleryProductA,
        [Design.DesignB]: GalleryProductB,
        [Design.DesignC]: GalleryProductC,
    });

    return (
        <ListComponent>
            {products.map((product) => {
                return (
                    <Link
                        key={product.id}
                        href={{
                            pathname: RoutesEnum.ProductDetail,
                            query: { productId: product.id },
                        }}
                    >
                        <div>
                            <GalleryProduct product={product} />
                        </div>
                    </Link>
                );
            })}
        </ListComponent>
    );
};

export default GalleryProductList;
