import CustomIcon from 'src/legacy/components/common/custom-icon';
import { StarComponentProps } from '..';

const StarC: React.FC<StarComponentProps> = ({ active, size = '20px', ...props }) => {
    return (
        <CustomIcon {...props} size={size} color={'#FFB800'} fill={active}>
            grade
        </CustomIcon>
    );
};

export default StarC;
