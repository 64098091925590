import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import FooterContainer from '@legacy/designs/footer-container';
import InnerHtml from '@designs/primitives/common/inner-html';
import RoutesEnum from '@routes';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React from 'react';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import CustomTermDrawer from 'src/legacy/components/common/custom-term-drawer';
import BodyText from 'src/legacy/components/common/text/body-text';
import { CustomFooterProps } from '..';

const CustomFooterC: React.FC<CustomFooterProps> = ({ footerFrame, terms, term, open, openDrawer, onClose }) => {
    const { theme, designSettings, settings } = useHarmony();
    const { extensionStatus } = useExtension();
    const t = useTranslations('footer');

    return (
        <>
            <FooterContainer>
                <BlockVStack spacing="8px">
                    <BlockVStack spacing="none">
                        <BodyText weight="bold" size="18px" lineHeight="22px">
                            {t('customerCenter')} {settings?.customerServiceInfo.contactNumber}
                        </BodyText>
                        <BodyText size="16px">{settings?.customerServiceInfo.csHours}</BodyText>
                    </BlockVStack>
                    {footerFrame && (
                        <div
                            css={css`
                                font-size: ${designSettings?.getLegacyFontSize('14px')};
                                font-family: ${theme.font.primary};
                                text-align: left;
                                h1,
                                h3 {
                                    font-size: ${designSettings?.getLegacyFontSize('14px')};
                                    color: ${theme.gray[800]};
                                }
                                h2 {
                                    font-size: ${designSettings?.getLegacyFontSize('14px')};
                                    color: ${theme.gray[800]};
                                }
                                p {
                                    text-align: left;
                                    font-size: ${designSettings?.getLegacyFontSize('14px')};
                                    color: ${theme.gray[800]};
                                    margin: 8px 0;
                                }
                                li {
                                    margin-bottom: 8px;
                                    text-align: left;
                                    color: ${theme.gray[800]};
                                    span {
                                        margin-right: 4px;
                                        color: ${theme.gray[800]};
                                    }
                                }
                            `}
                        >
                            <InnerHtml content={footerFrame?.footerBusinessInfo} />
                        </div>
                    )}
                </BlockVStack>
                <BlockVStack spacing="8px">
                    <BlockHStack spacing="12px">
                        <Link href={RoutesEnum.Faq}>
                            <TermButton color={theme.primary.default} size="15px" lineHeight="22px">
                                {t('faq')}
                            </TermButton>
                        </Link>
                        {extensionStatus?.inquiry.isEnabled && (
                            <Link href={RoutesEnum.Inquiry}>
                                <TermButton color={theme.primary.default} size="15px" lineHeight="22px">
                                    {t('inquiry')}
                                </TermButton>
                            </Link>
                        )}
                        {extensionStatus?.noticeBoard.isEnabled && (
                            <Link href={RoutesEnum.Notice}>
                                <TermButton color={theme.primary.default} size="15px" lineHeight="22px">
                                    {t('notice')}
                                </TermButton>
                            </Link>
                        )}
                    </BlockHStack>
                    <BlockHStack spacing="16px">
                        {terms.map((term) => (
                            <TermButton key={term.id} size="14px" lineHeight="22px" onClick={openDrawer(term)}>
                                {term.title}
                            </TermButton>
                        ))}
                    </BlockHStack>
                    {footerFrame && (
                        <InnerHtml
                            css={css`
                                > * {
                                    font-size: ${designSettings?.getLegacyFontSize('14px')};
                                }
                            `}
                            color={theme.gray[600]}
                            content={footerFrame.footerCopyright}
                        />
                    )}
                </BlockVStack>
            </FooterContainer>
            <CustomTermDrawer term={term} open={open} onClose={onClose} />
        </>
    );
};

export default CustomFooterC;

const TermButton = styled(BodyText)`
    cursor: pointer;
`;
