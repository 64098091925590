import { AccordionButton, AccordionItem, AccordionPanel, Box, Circle, HStack } from '@chakra-ui/react';
import ListProductCard from '@designs/grouped/common/product-card/list-product-card';
import ExpandIcon from '@designs/primitives/common/expand-icon';
import Body1D from '@designs/primitives/typography/body-1-d';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import RoutesEnum from '@routes';
import { useRouter } from 'next/router';
import { RankingItemProps } from '..';

const RankingItemD: React.FC<RankingItemProps> = ({ product, ranking, isAccordion, ...props }) => {
    const { theme } = useHarmony();
    const router = useRouter();

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <Box boxShadow={theme.dropShadow.rankingItem} borderRadius="8px" bgColor={theme.background.white} p="6px 8px" {...props}>
                    <AccordionButton
                        onClick={() => {
                            if (isAccordion) {
                                return;
                            }

                            router.push({ pathname: RoutesEnum.ProductDetail, query: { productId: product.id } });
                        }}
                    >
                        <HStack spacing="8px" w="100%">
                            <Circle bgColor={theme.primary.lighten[200]} size="28px">
                                <Body1D color={theme.text.title1}>{ranking}</Body1D>
                            </Circle>
                            <Body2B textAlign="center" noOfLines={1} flex={1}>
                                {product.title}
                            </Body2B>
                            {isAccordion && <ExpandIcon isOpen={isExpanded} />}
                        </HStack>
                    </AccordionButton>
                    {isAccordion && (
                        <AccordionPanel pt="8px">
                            <ListProductCard product={product} />
                        </AccordionPanel>
                    )}
                </Box>
            )}
        </AccordionItem>
    );
};

export default RankingItemD;
