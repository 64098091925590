import { useHarmony } from '@hooks/use-harmony';
import { BrandCollectionProps } from '..';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import CustomImage from '@legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { useWindowSize } from '@hooks/use-window-size';
import BodyText from '@legacy/components/common/text/body-text';
import { BlockVStack } from '@legacy/components/blockui';
import CustomResizeableImage from '@legacy/components/common/custom-resizeable-image';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useMemo } from 'react';
import Link from 'next/link';
import RoutesEnum from '@routes';
import ProductListContainer from '@legacy/designs/product-list-container';
import { IThemeProps } from '@type/common';
import { useDragScroll } from '@hooks/use-mouse-drag';

const BrandCollectionB: React.FC<BrandCollectionProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const collectionImage = channelComponent.firstItem?.image;
    const [width] = useWindowSize({ maxWidth: true });
    const dragScroll = useDragScroll();
    const girdColumnGap = useMemo(() => {
        switch (channelComponent.brandCols) {
            case 3:
                return 13;
            case 4:
                return 8;
            case 5:
                return 9;
            default:
                return 12;
        }
    }, [channelComponent]);

    return (
        <ProductListContainer>
            <HomeComponentHeading title={channelComponent.title} subtitle={channelComponent.subtitle} />
            <BrandCollectionContainer theme={theme}>
                <ImageContainer height={(width - 32) * channelComponent.imageHeightFactor}>
                    {collectionImage && (
                        <Link href={channelComponent.firstItem?.pageUrl || ''}>
                            <a className={!channelComponent.firstItem?.pageUrl ? 'disabled' : ''}>
                                <CustomResizeableImage
                                    imageHeightFactor={channelComponent.imageHeightFactor}
                                    src={collectionImage}
                                    alt={channelComponent.title}
                                    layout="fill"
                                    objectFit="cover"
                                />
                            </a>
                        </Link>
                    )}
                </ImageContainer>
                {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                    <BrandGrid row={channelComponent.brandRows} col={channelComponent.brandCols} colGap={girdColumnGap}>
                        {channelComponent.brandItems?.map((brand) => (
                            <Link href={{ pathname: RoutesEnum.BrandDetail, query: { brandId: brand.brandId } }} key={brand.brandId}>
                                <BrandItem>
                                    {brand.brandImageUrl && (
                                        <IconWrapper height={`${(width - 64 - girdColumnGap * (channelComponent.brandCols - 1)) / channelComponent.brandCols}px`}>
                                            <CustomImage src={brand.brandImageUrl} alt={brand.channelBrandName} layout="fill" objectFit="cover" />
                                        </IconWrapper>
                                    )}
                                    <BlockVStack alignItems="center">
                                        <BodyText weight="medium" staticSize="12px" color={theme.black} wordBreak="break-all" textAlign="center" lineHeight="18px">
                                            {brand.channelBrandName}
                                        </BodyText>
                                    </BlockVStack>
                                </BrandItem>
                            </Link>
                        ))}
                    </BrandGrid>
                )}
                {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                    <BrandSwipeContainer {...dragScroll}>
                        {channelComponent.brandItems?.map((brand) => (
                            <Link href={{ pathname: RoutesEnum.BrandDetail, query: { brandId: brand.brandId } }} key={brand.brandId}>
                                <BrandItem>
                                    {brand.brandImageUrl && (
                                        <IconWrapper width="84px" height="84px">
                                            <CustomImage src={brand.brandImageUrl} alt={brand.channelBrandName} layout="fill" objectFit="cover" />
                                        </IconWrapper>
                                    )}
                                    <BlockVStack align="center" alignItems="center" width="84px">
                                        <BodyText weight="medium" staticSize="12px" color={theme.black} wordBreak="break-all" textAlign="center" lineHeight="18px">
                                            {brand.channelBrandName}
                                        </BodyText>
                                    </BlockVStack>
                                </BrandItem>
                            </Link>
                        ))}
                    </BrandSwipeContainer>
                )}
            </BrandCollectionContainer>
        </ProductListContainer>
    );
};

export default BrandCollectionB;

const BrandCollectionContainer = styled.div<IThemeProps>`
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    margin: 16px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.white};
`;

const ImageContainer = styled.div<{ height: number }>`
    position: relative;
    width: 100%;
    height: ${({ height }) => height}px;

    .disabled {
        pointer-events: none;
    }
`;

const BrandGrid = styled.ul<{ row: number; col: number; colGap: number }>`
    ${({ row, col, colGap }) => `
    display: grid;
    grid-gap: 8px ${colGap}px;
    padding: 16px;
    grid-template-rows: repeat(${row}, 1fr);
    grid-template-columns: repeat(${col}, 1fr);
    `}
`;

const BrandSwipeContainer = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: nowrap;
    width: 100%;
    padding: 16px;
    overflow-x: auto;
    user-select: none;
`;

const BrandItem = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
`;

const IconWrapper = styled.div<{ width?: string; height: string }>`
    width: ${({ width }) => (width ? `${width}` : '100%')};
    height: ${({ height }) => height};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 1px solid ${({ theme }) => theme.gray[200]};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;
