import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import MagazineD from './designD/magazine';

export interface MagazineProps {
    channelComponent: ChannelComponent;
}

const Magazine: React.FC<MagazineProps> = (props) => {
    const Component = useDesignComponents<ComponentType<MagazineProps>>({
        [Design.DesignD]: MagazineD,
    });

    return <Component {...props} />;
};

export default Magazine;
