import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useDesignComponents } from '@hooks/use-design-components';
import { useExtension } from '@hooks/use-extension';
import MainContentLegacy from '@legacy/pages/main-content';
import { IChannelComponentDto } from '@models/channel-component/responseDto/channel-component.dto';
import { IPopupDto } from '@models/popup/responseDto/popup.dto';
import { MainProps } from '@pages/index';
import AcrossService from '@services/across.service';
import safeWindow from '@services/safe-window.service';
import { Design } from '@type/harmony-config';
import { ComponentType, useEffect } from 'react';
import useSWR from 'swr';
import MainContentD from './designD/main-content';

interface MainContentProps extends MainProps {}

export interface MainContentInnerProps extends MainContentProps {
    componentsDto: IChannelComponentDto[] | undefined;
    popupDto: IPopupDto | undefined;
    isLoading: boolean;
}

const MainContent: React.FC<MainContentProps> = ({ authToken, tempSettingId, ...props }) => {
    const Component = useDesignComponents<ComponentType<MainContentInnerProps>>({
        [Design.DesignA]: MainContentLegacy,
        [Design.DesignB]: MainContentLegacy,
        [Design.DesignC]: MainContentLegacy,
        [Design.DesignD]: MainContentD,
    });

    const { extensionStatus } = useExtension();

    const { data: componentsDto, isValidating } = useSWR<IChannelComponentDto[]>(ChannelCacheKeys.channelComponentsCacheKey(), () =>
        ChannelClient.getChannelComponents().catch(() => {
            safeWindow?.alert('에러가 발생했습니다. 새로고침을 해주세요.');
            return [];
        })
    );
    const { data: popupDto } = useSWR<IPopupDto>(extensionStatus?.popup.isEnabled && ChannelCacheKeys.popupCacheKey, () => ChannelClient.getPopup());
    useCustomer({
        externalAuthToken: authToken,
    });

    const isLoading = isValidating && (componentsDto?.length || 0) < 1;

    useEffect(() => {
        AcrossService.visitAnalytics();
    }, []);

    return <Component authToken={authToken} componentsDto={componentsDto} popupDto={popupDto} isLoading={isLoading} {...props} />;
};

export default MainContent;
