import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import ChannelComponent from '@models/channel-component';
import { ComponentType } from 'react';
import BrandCollectionA from './designA/brand-collection';
import BrandCollectionB from './designB/brand-collection';
import BrandCollectionC from './designC/brand-collection';

export interface BrandCollectionProps {
    channelComponent: ChannelComponent;
}

const BrandCollectionComponent: React.FC<BrandCollectionProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BrandCollectionProps>>({
        [Design.DesignA]: BrandCollectionA,
        [Design.DesignB]: BrandCollectionB,
        [Design.DesignC]: BrandCollectionC,
    });

    return <Component {...props} />;
};

export default BrandCollectionComponent;
