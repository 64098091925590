import { ICreateCartLineDto } from '@models/cart-line/requestDto/create-cart-line.dto';
import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { PreservedFormData } from './types';

/**
 * Local Storage service class to encapsulate any local storage accessing logic and related static keys.
 */
class StorageService {
    private static readonly _CART_LINES = 'cartLines';
    private static readonly _FLOATING_ICON_CLOSED = 'floatingIconClosed';
    private static readonly _AVAILABLE_COUPON_INDICATOR_CLOSED = 'availableCouponIndicatorClosed';
    private static readonly _FORM_DATA = 'formData';
    private static readonly _SALES_TYPE = 'salesType';

    static get isReady(): boolean {
        return typeof window !== undefined;
    }

    /**
     * Gets cart items
     */
    static getCartLines(): ICreateCartLineDto[] {
        const cartItems = sessionStorage.getItem(this._CART_LINES);
        return cartItems ? JSON.parse(cartItems) : [];
    }

    /**
     * Sets cart items
     */
    static setCartLines(cartLines: ICreateCartLineDto[]): void {
        sessionStorage.setItem(this._CART_LINES, JSON.stringify(cartLines));
    }

    static clearCartLines(): void {
        sessionStorage.removeItem(this._CART_LINES);
    }

    static setSalesType(salesType: SalesTypeEnum): void {
        sessionStorage.setItem(this._SALES_TYPE, salesType);
    }

    static getSalesType(): SalesTypeEnum | null {
        return sessionStorage.getItem(this._SALES_TYPE) as SalesTypeEnum;
    }

    static getFloatingIconClosed(): boolean {
        return sessionStorage.getItem(this._FLOATING_ICON_CLOSED) ? true : false;
    }

    static setFloatingIconClosed(closed: boolean): void {
        sessionStorage.setItem(this._FLOATING_ICON_CLOSED, closed ? 'true' : 'false');
    }

    static getAvailableTokenIndicatorClosed(): Date | null {
        const storageData = localStorage.getItem(this._AVAILABLE_COUPON_INDICATOR_CLOSED);
        return storageData ? new Date(storageData) : null;
    }

    static setAvailableTokenIndicatorClosed(expire: Date) {
        localStorage.setItem(this._AVAILABLE_COUPON_INDICATOR_CLOSED, expire.toISOString());
    }

    static getFormDataKey(key: string): string {
        return `${this._FORM_DATA}-${key}`;
    }

    static getFormData(key: string): PreservedFormData | undefined {
        const formKey = this.getFormDataKey(key);
        const formData = sessionStorage.getItem(formKey);
        if (formData) {
            this.clearFormData(formKey);
        }
        return formData ? JSON.parse(formData) : undefined;
    }

    static setFormData(key: string, formData: PreservedFormData): void {
        sessionStorage.setItem(this.getFormDataKey(key), JSON.stringify(formData));
    }

    static clearFormData(key: string): void {
        sessionStorage.removeItem(this.getFormDataKey(key));
    }
}

export default StorageService;
