import CustomImage from 'src/legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { NoProductProps } from '..';

const NoProductA: React.FC<NoProductProps> = ({ size = '200px' }) => {
    return (
        <NoProductContainer size={size}>
            <CustomImage src="/image/no_product.png" alt="no product" layout="fill" objectFit="contain" />
        </NoProductContainer>
    );
};

const NoProductContainer = styled.div<NoProductProps>`
    ${({ size }) => `
        width: ${size};
        height: ${size};
        position: relative;
    `}
`;

export default NoProductA;
