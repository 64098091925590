import { HStack } from '@chakra-ui/react';
import ButtonLeft from '@designs/primitives/common/pagination/button-left';
import ButtonRight from '@designs/primitives/common/pagination/button-right';
import { useHarmony } from '@hooks/use-harmony';
import { InternalPaginationProps } from '..';
import Body2B from '@designs/primitives/typography/body-2-b';

const PaginationD: React.FC<InternalPaginationProps> = ({ leftArrowActive, rightArrowActive, totalPages, currentPage, onLeftClick, onRightClick }) => {
    const { theme } = useHarmony();

    return (
        <HStack spacing={'8px'} alignItems={'center'}>
            <ButtonLeft isDisabled={!leftArrowActive} onClick={onLeftClick} />
            <HStack spacing={'4px'} alignItems={'center'}>
                <Body2B color={theme.text.body1}>{currentPage}</Body2B>
                <Body2B color={theme.text.body1}>/</Body2B>
                <Body2B color={theme.text.body1}>{totalPages}</Body2B>
            </HStack>
            <ButtonRight isDisabled={!rightArrowActive} onClick={onRightClick} />
        </HStack>
    );
};

export default PaginationD;
