import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface IconMenuAProps {
    channelComponent: ChannelComponent;
}

const ICONS_PER_GROUP = 9;

const IconMenuA: React.FC<IconMenuAProps> = ({ channelComponent }) => {
    const [active, setActive] = useState<number>(0);
    const { theme } = useHarmony();

    const itemGroups = useMemo(() => {
        return channelComponent.itemGroups(ICONS_PER_GROUP);
    }, [channelComponent]);

    const dotList = [...new Array(itemGroups.length)].map((_, index: number) => {
        return <div key={index} className={'dot' + (active === index ? ' active' : '')}></div>;
    });

    const iconMenuGroups = itemGroups.map((group, i: number) => (
        <SwiperSlide key={i}>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: repeat(${group.length < 3 ? group.length : 3}, 1fr);
                    gap: 17px 0px;
                    place-content: start center;
                    padding: 16px 19px;
                    ${itemGroups.length > 1 ? `height: 287px` : `height: auto`};
                    .item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: column;
                        width: 100%;
                        .icon {
                        }
                        .text {
                            width: 100%;
                            text-align: center;
                            margin-top: 6px;
                        }
                    }
                `}
            >
                {group.map((item: ChannelComponentItem, i: number) => {
                    return (
                        <Link key={i} href={item.legacyLink}>
                            <div className="item">
                                {item.image && (
                                    <IconContainer theme={theme} isIcon={item.isIcon}>
                                        <CustomImage src={item.image} alt={item.name} width={24} height={24} layout={item.isIcon ? 'fixed' : 'fill'} objectFit="contain" />
                                    </IconContainer>
                                )}
                                <BodyText size="15px" className="text">
                                    {item.displayName}
                                </BodyText>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </SwiperSlide>
    ));

    return (
        <div
            css={css`
                width: 100%;
                padding: 13px;
                background-color: ${theme.background.config};
                margin-bottom: 8px;
            `}
        >
            <Swiper onSlideChange={(swiper) => setActive(swiper.activeIndex)}>{iconMenuGroups}</Swiper>
            {itemGroups.length > 1 && (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-top: 19px;
                        .dot {
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            margin-right: 8px;
                            background-color: ${theme.gray[50]};
                            transition: background-color 0.1s ease-in-out;
                            &:nth-last-of-type(1) {
                                margin-right: 0px;
                            }

                            &.active {
                                background-color: ${theme.primary.default};
                            }
                        }
                    `}
                >
                    {dotList}
                </div>
            )}
        </div>
    );
};

export default IconMenuA;

const IconContainer = styled.div<IThemeProps & { isIcon: boolean }>`
    ${({ theme, isIcon }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: ${isIcon ? theme.gray[50] : 'none'};
        border-radius: 8px;
        cursor: pointer;
        position: relative;
    `}
`;
