import { useDesignComponents } from '@hooks/use-design-components';
import OfflineStoreItem from '@models/offline-store-item';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import OfflineStoreCardA from './designA/offline-store-card';
import OfflineStoreCardB from './designB/offline-store-card';
import OfflineStoreCardC from './designC/offline-store-card';
import OfflineStoreCardD from './designD/offline-store-card';

export interface OfflineStoreCardProps {
    offlineStore: OfflineStoreItem;
}

const OfflineStoreCard: React.FC<OfflineStoreCardProps> = (props) => {
    const Component = useDesignComponents<ComponentType<OfflineStoreCardProps>>({
        [Design.DesignA]: OfflineStoreCardA,
        [Design.DesignB]: OfflineStoreCardB,
        [Design.DesignC]: OfflineStoreCardC,
        [Design.DesignD]: OfflineStoreCardD,
    });

    return <Component {...props} />;
};

export default OfflineStoreCard;
