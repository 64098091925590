import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { BaseProductCardProps } from '@type/theme';
import { ComponentType } from 'react';
import RankingProductCardD from './designD/ranking-product-card';
import { withProductCardHOC } from '../with-product-card-hoc';

export interface RankingProductCardProps extends BaseProductCardProps {
    ranking?: number;
}

const RankingProductCard: React.FC<RankingProductCardProps> = withProductCardHOC((props) => {
    const Component = useDesignComponents<ComponentType<RankingProductCardProps>>({
        [Design.DesignD]: RankingProductCardD,
    });

    return <Component {...props} />;
});

export default RankingProductCard;
