import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { ItemTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import RoutesEnum from 'src/routes/routes';
import { FullWidthContainer, PaddedContainer } from '@styles/styled-components';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { CategoryQuery } from '@queries/category-detail';
import ChevronButton, { ArrowDirectionEnum } from '@legacy/designs/chevron-button';
import { useHarmony } from '@hooks/use-harmony';

interface SubmenuButtonProps {
    component: ChannelComponent;
    item: ChannelComponentItem;
}

const SubmenuButton: React.FC<SubmenuButtonProps> = ({ component, item }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <FullWidthContainer>
            {component.itemType === ItemTypeEnum.Self && item.pageUrl && (
                <PaddedContainer padding="24px 16px 0 16px">
                    <Link href={{ pathname: item.pageUrl }}>
                        <ChevronButton color={theme.primary.default} arrowDirection={ArrowDirectionEnum.Right}>
                            {t('common.goToPage', { page: item.categoryName })}
                        </ChevronButton>
                    </Link>
                </PaddedContainer>
            )}
            {component.itemType === ItemTypeEnum.Category && item.categoryId && (
                <PaddedContainer padding="24px 16px 0 16px">
                    <Link
                        href={{
                            pathname: RoutesEnum.CategoryDetail,
                            query: { [CategoryQuery.CategoryId]: item.categoryId, [CategoryQuery.RankPage]: true },
                        }}
                    >
                        <ChevronButton color={theme.primary.default} arrowDirection={ArrowDirectionEnum.Right}>
                            {t('home.goToCategory')}
                        </ChevronButton>
                    </Link>
                </PaddedContainer>
            )}
        </FullWidthContainer>
    );
};

export default SubmenuButton;
