import { Box, Center, forwardRef } from '@chakra-ui/react';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import Body1C from '@designs/primitives/typography/body-1-c';
import { useHarmony } from '@hooks/use-harmony';
import { ImageMenuCellProps } from '..';

const ImageMenuCellD: React.FC<ImageMenuCellProps> = forwardRef<ImageMenuCellProps, 'div'>(({ imageUrl, ratio, title }, ref) => {
    const { theme } = useHarmony();

    return (
        <Box pos="relative" borderRadius="4px" overflow="hidden" ref={ref}>
            <ComponentImage src={imageUrl} ratio={ratio} />
            <Box pos="absolute" inset="0" bgColor="rgba(0, 0, 0, 0.40)" />
            <Center pos="absolute" inset="0" px="8px">
                <Body1C color={theme.text.white} wordBreak="break-all" align="center">
                    {title}
                </Body1C>
            </Center>
        </Box>
    );
});

export default ImageMenuCellD;
