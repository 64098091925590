import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType, useEffect } from 'react';
import TimeSaleD from './designD/time-sale';
import { useGATracking } from '@hooks/use-ga-tracking';

export interface TimeSaleProps {
    channelComponent: ChannelComponent;
}

const TimeSale: React.FC<TimeSaleProps> = (props) => {
    const Component = useDesignComponents<ComponentType<TimeSaleProps>>({
        [Design.DesignD]: TimeSaleD,
    });
    const { logEvent } = useGATracking();

    useEffect(() => {
        logEvent('view_promotion', {
            promotion_name: props.channelComponent.title,
            items: [],
        });
    }, []);

    return <Component {...props} />;
};

export default TimeSale;
