import Header from 'src/legacy/components/common/header';
import FloatingMenu from '@legacy/designs/floating-menu/floating-menu';
import PopupHeader from '@legacy/designs/popup-header';
import styled from '@emotion/styled';
import { FloatingMenuPadding } from '@styles/styled-components';
import { ReactNode } from 'react';

interface DefaultLayoutProps {
    children: ReactNode;
    showPopupHeader?: boolean;
    headerTitle?: string;
    backgroundColor?: string;
    onBack?: () => void;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ backgroundColor, showPopupHeader, headerTitle, onBack, children }) => {
    return (
        <StyledLayout backgroundColor={backgroundColor || ''}>
            {showPopupHeader ? <PopupHeader title={headerTitle} onBack={onBack} hasShadow={false} /> : <Header />}
            {children}
            <FloatingMenu />
        </StyledLayout>
    );
};

export default DefaultLayout;

const StyledLayout = styled(FloatingMenuPadding)<{ backgroundColor: string }>`
    position: relative;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
