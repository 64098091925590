import SwipeRankedProduct from '@legacy/designs/swipe-ranked-product';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useWindowSize } from '@hooks/use-window-size';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwipeRankListProps } from '..';

const SwipeRankListB: React.FC<SwipeRankListProps> = ({ rankedProductList, rankTotal, index, setIndex, currentProductScale }) => {
    const [width] = useWindowSize({ maxWidth: true });

    return (
        <SwipeContainer>
            <Swiper
                width={width * currentProductScale + 1}
                loop={rankTotal > 1}
                loopAdditionalSlides={1}
                slidesOffsetBefore={rankTotal > 1 ? width * 0.155 : 0}
                onSlideChange={(swiper) => {
                    setIndex(swiper.realIndex);
                }}
                css={css`
                    padding: 8px 0;
                    .swiper-wrapper {
                        align-items: center;
                    }
                `}
            >
                {rankedProductList?.map((product, i) => {
                    return (
                        <SwiperSlide key={i} style={{ width: `${width * currentProductScale}px`, height: '100%' }}>
                            <SwipeRankedProduct product={product} width={width} rank={i + 1} focused={index === i} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </SwipeContainer>
    );
};

export default SwipeRankListB;

const SwipeContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
`;
