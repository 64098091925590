import { useDesignComponents } from '@hooks/use-design-components';

import { FullModalProps } from '@components/full-modal';
import Term from '@models/term';
import { Design } from '@type/harmony-config';
import { Selectable } from '@type/theme';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import TermsFullModalD from './designD/terms-full-modal';

interface TermsFullModalProps extends Omit<FullModalProps, 'children'> {
    term: Term | null;
}

export interface TermsFullModalInnerProps extends Omit<TermsFullModalProps, 'term'> {
    termsHistoryItems: Selectable<Term>[];
    selectedTerm: Selectable<Term> | undefined;
    onTermChange: (termItem: Selectable<Term>) => void;
}

const TermsFullModal: React.FC<TermsFullModalProps> = ({ term, ...props }) => {
    const Component = useDesignComponents<ComponentType<TermsFullModalInnerProps>>({
        [Design.DesignD]: TermsFullModalD,
    });

    const termHistoryItems = useMemo<Selectable<Term>[]>(
        () => (!term ? [] : [{ label: term.createDateString, value: term }, ...term.histories.map((term) => ({ label: term.createDateString, value: term }))]),
        [term]
    );
    const [selectedTerm, setSelectedTerm] = useState<Selectable<Term> | undefined>(termHistoryItems[0]);

    useEffect(() => {
        setSelectedTerm(termHistoryItems[0]);
    }, [term]);

    return <Component termsHistoryItems={termHistoryItems} selectedTerm={selectedTerm} onTermChange={setSelectedTerm} {...props} />;
};

export default TermsFullModal;
