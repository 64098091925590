import { useDesignComponents } from '@hooks/use-design-components';

import ArrowButton from '@legacy/designs/arrow-button';
import ChannelComponent from '@models/channel-component';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import { useHarmony } from '@hooks/use-harmony';
import { BlockHStack } from 'src/legacy/components/blockui';
import { Design } from '@type/harmony-config';
import NoProduct from '@legacy/designs/no-product';
import MoreViewButton from '@legacy/designs/more-view-button.tsx';
import SwipeListProduct from '@legacy/designs/swipe-list-product';
import ComponentProduct from '@models/product/component-product';
import FixedListProduct from '@legacy/designs/fixed-list-product/fixed-list-product';
import ProductListContainer from '@legacy/designs/product-list-container';
import FixedProductListContainer from '@legacy/designs/fixed-product-list-container';
import SwipeProductListContainer from '@legacy/designs/swipe-product-list-container';
import { useGATracking } from '@hooks/use-ga-tracking';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import RoutesEnum from '@routes';

interface ProductListProps {
    channelComponent: ChannelComponent;
}

const ProductList: React.FC<ProductListProps> = ({ channelComponent }) => {
    const { designSettings } = useHarmony();
    const t = useTranslations();
    const hasArrowButton = designSettings?.isDesignA || designSettings?.isDesignB;
    const { logEvent } = useGATracking();
    const router = useRouter();

    const noProductSwipeImageSize = useDesignComponents<string>({
        [Design.DesignA]: '200px',
        [Design.DesignB]: '200px',
        [Design.DesignC]: '140px',
    });

    const noProductPb = useDesignComponents<string>({
        [Design.DesignA]: '16px',
        [Design.DesignB]: '16px,',
        [Design.DesignC]: '0px',
    });

    useEffect(() => {
        logEvent('view_item_list', {
            items: channelComponent.firstItem?.mainDisplayProductList?.map((product) => ({
                item_id: product.id,
                item_name: product.title,
            })),
        });
    }, [channelComponent]);

    return (
        <ProductListContainer>
            <HomeComponentHeading title={channelComponent.title}>
                {hasArrowButton && channelComponent.firstItem?.pageUrl && (
                    <Link href={channelComponent.firstItem.pageUrl}>
                        <ArrowButton>{t('home.view')}</ArrowButton>
                    </Link>
                )}
            </HomeComponentHeading>
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                <SwipeProductListContainer>
                    {channelComponent.firstItem?.mainDisplayProductList &&
                        channelComponent.firstItem.mainDisplayProductList.map((product: ComponentProduct) => {
                            return (
                                <SwipeListProduct
                                    key={product.id}
                                    product={product}
                                    onClick={() => {
                                        logEvent('select_item', {
                                            items: [
                                                {
                                                    item_id: product.productId,
                                                    item_name: product.title,
                                                },
                                            ],
                                        });
                                    }}
                                />
                            );
                        })}
                    {(channelComponent.firstItem?.mainDisplayProductList.length || 0) < 1 && (
                        <BlockHStack align="center" width="100%">
                            <NoProduct size={noProductSwipeImageSize} />
                        </BlockHStack>
                    )}
                </SwipeProductListContainer>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <FixedProductListContainer cols={channelComponent.productCols}>
                    {channelComponent.firstItem?.mainDisplayProductList &&
                        channelComponent.firstItem.mainDisplayProductList.map((product: ComponentProduct) => {
                            return (
                                <FixedListProduct
                                    key={product.id}
                                    product={product}
                                    cols={channelComponent.productCols}
                                    onClick={() => {
                                        logEvent('select_item', {
                                            items: [
                                                {
                                                    item_id: product.productId,
                                                    item_name: product.title,
                                                },
                                            ],
                                        });
                                        router.push({
                                            pathname: RoutesEnum.ProductDetail,
                                            query: { productId: product.productId },
                                        });
                                    }}
                                />
                            );
                        })}
                    {(channelComponent.firstItem?.mainDisplayProductList.length || 0) < 1 &&
                        [...new Array(channelComponent.productCols)].map((_, i) => (
                            <BlockHStack key={i} align="center" pb={noProductPb}>
                                <NoProduct large={channelComponent.productCols === 1} />
                            </BlockHStack>
                        ))}
                </FixedProductListContainer>
            )}

            {designSettings?.isDesignC && channelComponent.firstItem?.pageUrl && (
                <Link href={channelComponent.firstItem.pageUrl}>
                    <a>
                        <MoreViewButton />
                    </a>
                </Link>
            )}
        </ProductListContainer>
    );
};

export default ProductList;
