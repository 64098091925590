import { useEffect, useState } from 'react';

/**
 *
 * @returns true if the code is running on the client side
 */
export function useIsClientSide(): boolean {
    const [isClientSide, setIsClientSide] = useState<boolean>(false);

    useEffect(() => {
        setIsClientSide(true);
    }, []);

    return isClientSide;
}
