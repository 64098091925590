import { useDesignComponents } from '@hooks/use-design-components';

import { BlockVStack } from 'src/legacy/components/blockui';
import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { Dispatch, SetStateAction } from 'react';
import SwipeRankListA from './designA/swipe-rank-list';
import SwipeRankListB from './designB/swipe-rank-list';
import SwipeRankListC from './designC/swipe-rank-list';

export interface SwipeRankListProps {
    rankedProductList?: ComponentProduct[];
    page: number;
    totalPage: number;
    itemsPerPage: number;
    prev: () => void;
    next: () => void;
    rankTotal: number;
    index: number;
    setIndex: Dispatch<SetStateAction<number>>;
    currentProductScale: number;
}

const SwipeRankList: React.FC<SwipeRankListProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof SwipeRankListA | typeof SwipeRankListB | typeof SwipeRankListC>({
        [Design.DesignA]: SwipeRankListA,
        [Design.DesignB]: SwipeRankListB,
        [Design.DesignC]: SwipeRankListC,
    });

    return (
        <BlockVStack spacing="none">
            <Component {...props} />
        </BlockVStack>
    );
};

export default SwipeRankList;
