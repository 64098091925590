import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import BrandD from './designD/brand';

export interface BrandProps {
    channelComponent: ChannelComponent;
}

const Brand: React.FC<BrandProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BrandProps>>({
        [Design.DesignD]: BrandD,
    });

    return <Component {...props} />;
};

export default Brand;
