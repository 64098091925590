import { ImageRatio } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import PopupItem from './popup-item';
import { IPopupDto, PopupPosition } from './responseDto/popup.dto';

class Popup {
    private _position: PopupPosition;
    private _useButton: boolean;
    private _buttonName: string;
    private _imageRatio: ImageRatio;
    private _items: PopupItem[];

    constructor(popupDto: IPopupDto) {
        this._position = popupDto.position;
        this._useButton = popupDto.useButton;
        this._buttonName = popupDto.buttonName;
        this._imageRatio = popupDto.imageRatio;
        this._items = (popupDto.items || []).map((item) => new PopupItem(item));
    }

    get position(): PopupPosition {
        return this._position;
    }

    get useButton(): boolean {
        return this._useButton;
    }

    get imageRatio(): number {
        if (!this._imageRatio) {
            return 0;
        }

        const [w, h] = this._imageRatio;
        return h / w;
    }

    get buttonName(): string {
        return this._buttonName;
    }

    get items(): PopupItem[] {
        return this._items;
    }

    get firstItem(): PopupItem | undefined {
        return this._items[0];
    }

    get imageHeightFactor(): number {
        return ConversionUtils.imageRatioToHeightFactor(this._imageRatio);
    }
}

export default Popup;
