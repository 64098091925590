import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const BannerContainerB = styled.div<IThemeProps>`
    ${({ theme }) => `
        padding: 16px;
        position: relative;
        background-color: ${theme.background.config};

        .disabled {
            pointer-events: none;
        }
    `}
`;

export default BannerContainerB;
