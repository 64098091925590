import { useDesignComponents } from '@hooks/use-design-components';
import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import React from 'react';
import ImageMenuA from './designA/image-menu';
import ImageMenuB from './designB/image-menu';
import ImageMenuC from './designC/image-menu';

export interface ImageMenuProps {
    channelComponent: ChannelComponent;
}

const ImageMenu: React.FC<ImageMenuProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof ImageMenuA | typeof ImageMenuB | typeof ImageMenuC>({
        [Design.DesignA]: ImageMenuA,
        [Design.DesignB]: ImageMenuB,
        [Design.DesignC]: ImageMenuC,
    });
    return <Component {...props} />;
};

export default ImageMenu;
