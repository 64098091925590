import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import FooterFrame from '@models/frame-collection/frame-footer';
import Term from '@models/term';
import CustomFooterA from './designA/custom-footer';
import CustomFooterB from './designB/custom-footer';
import CustomFooterC from './designC/custom-footer';

export interface CustomFooterProps {
    footerFrame: FooterFrame | undefined;
    terms: Term[];
    term?: Term;
    open: boolean;
    onClose: () => void;
    openDrawer: (term: Term) => () => void;
}

const CustomFooter: React.FC<CustomFooterProps> = (props) => {
    const Component = useDesignComponents<typeof CustomFooterA | typeof CustomFooterB | typeof CustomFooterC>({
        [Design.DesignA]: CustomFooterA,
        [Design.DesignB]: CustomFooterB,
        [Design.DesignC]: CustomFooterC,
    });

    return <Component {...props} />;
};

export default CustomFooter;
