import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body1DD from './designD/body-1-d';

export interface Body1DProps extends PartialAbstractedTextProps {}

const Body1D: React.FC<Body1DProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body1DProps>>({
        [Design.DesignD]: Body1DD,
    });

    return <Component {...props} />;
};

export default Body1D;
