import ChannelComponent from '@models/channel-component';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useWindowSize } from '@hooks/use-window-size';
import MagazineItem from '@legacy/designs/magazine-item';
import ProductListContainer from '@legacy/designs/product-list-container';

interface MagazineProps {
    channelComponent: ChannelComponent;
}

const Magazine: React.FC<MagazineProps> = ({ channelComponent }) => {
    const [width] = useWindowSize({ maxWidth: true });
    const MAGAZINE_WIDTH = channelComponent.displayType === ComponentDisplayTypeEnum.Swipe ? width * 0.78 : width - 32;
    const MAGAZINE_RATIO = channelComponent.imageHeightFactor;

    return (
        <ProductListContainer>
            {channelComponent.displayType !== ComponentDisplayTypeEnum.Swipe ? (
                <MagazineItem channelComponent={channelComponent} width={MAGAZINE_WIDTH} ratio={MAGAZINE_RATIO} />
            ) : (
                <MagazineItem channelComponent={channelComponent} width={MAGAZINE_WIDTH} ratio={MAGAZINE_RATIO} isSwipe />
            )}
        </ProductListContainer>
    );
};

export default Magazine;
