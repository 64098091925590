import { useEffect, useMemo, useState } from 'react';

interface Params<T> {
    data: T[];
    itemsPerPage: number;
    initialPage?: number;
}

export const usePagination = <T>({ data, itemsPerPage, initialPage = 1 }: Params<T>) => {
    const totalPages = useMemo(() => Math.ceil(data.length / itemsPerPage), [data.length, itemsPerPage]);
    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const [paginatedData, setPaginatedData] = useState<T[]>([]);

    useEffect(() => {
        const firstPaginatedItemIndex = (currentPage - 1) * itemsPerPage;
        setPaginatedData(data.slice(firstPaginatedItemIndex, firstPaginatedItemIndex + itemsPerPage));
    }, [currentPage, data, itemsPerPage]);

    return { paginatedData, totalPages, currentPage, setCurrentPage };
};
