import SwipeRankedProduct from '@legacy/designs/swipe-ranked-product';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useWindowSize } from '@hooks/use-window-size';
import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwipeRankListProps } from '..';

const SwipeRankListC: React.FC<SwipeRankListProps> = ({ rankTotal, rankedProductList, index, currentProductScale, setIndex }) => {
    const [width] = useWindowSize({ maxWidth: true });
    const isSwipe = useMemo(() => rankedProductList && rankedProductList.length > 2, [rankedProductList]);

    return (
        <SwipeContainer>
            <Swiper
                centeredSlides
                loop={isSwipe}
                loopAdditionalSlides={1}
                slidesPerView={1.3}
                spaceBetween={16}
                onSlideChange={(swiper) => {
                    setIndex(swiper.realIndex);
                }}
                css={css`
                    .swiper-slide-prev,
                    .swiper-slide-next {
                        transform: translateY(16px);
                        transition: transform 0.3s;
                    }
                `}
            >
                {rankedProductList?.map((product, i) => {
                    return (
                        <SwiperSlide className={'ranking'} key={i}>
                            <SwipeRankedProduct product={product} rank={i + 1} focused={index === i} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </SwipeContainer>
    );
};

export default SwipeRankListC;

const SwipeContainer = styled.div`
    display: 'block';
    /* display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 16px; */
`;
