import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { FixedSubmenuProps } from '..';

const FixedSubmenuB: React.FC<FixedSubmenuProps> = ({ items, index, onSelect, ...props }) => {
    const { theme } = useHarmony();
    return (
        <StyledSubmenu {...props}>
            {items.map((item, i) => {
                const active = i === index;
                return (
                    <SubmenuButton key={i} theme={theme} active={active} onClick={() => onSelect(i)}>
                        <BodyText size="15px" color={active ? theme.white : theme.gray[800]} weight="medium">
                            {item}
                        </BodyText>
                    </SubmenuButton>
                );
            })}
        </StyledSubmenu>
    );
};

export default FixedSubmenuB;

const StyledSubmenu = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    width: 100%;
    padding: 0 16px;
`;

const SubmenuButton = styled.button<IThemeProps & { active: boolean }>`
    ${({ theme, active }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68px;
        border-radius: 12px;
        cursor: pointer;
        border: solid 1px ${active ? theme.primary.default : theme.gray[200]};
        background: ${active ? theme.primary.default : 'white'};
    `}
`;
