import { useDesignComponents } from '@hooks/use-design-components';
import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import TimeSaleA from './designA/time-sale';
import TimeSaleB from './designB/time-sale';
import TimeSaleC from './designC/time-sale';
import { useEffect } from 'react';
import { useGATracking } from '@hooks/use-ga-tracking';

export interface TimeSaleProps {
    channelComponent: ChannelComponent;
}

const TimeSale: React.FC<TimeSaleProps> = (props) => {
    const { logEvent } = useGATracking();
    const Component = useDesignComponents<typeof TimeSaleA | typeof TimeSaleB | typeof TimeSaleC>({
        [Design.DesignA]: TimeSaleA,
        [Design.DesignB]: TimeSaleB,
        [Design.DesignC]: TimeSaleC,
    });

    useEffect(() => {
        logEvent('view_promotion', {
            promotion_name: props.channelComponent.title,
            items: [],
        });
    }, []);

    return <Component {...props} />;
};

export default TimeSale;
