import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import AccordionRankedProductA from './designA/accordion-ranked-product';
import AccordionRankedProductB from './designB/accordion-ranked-product';
import AccordionRankedProductC from './designC/accordion-ranked-product';

export interface AccordionRankedProductProps {
    product: ComponentProduct;
}

const AccordionRankedProduct: React.FC<AccordionRankedProductProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof AccordionRankedProductA | typeof AccordionRankedProductB | typeof AccordionRankedProductC>({
        [Design.DesignA]: AccordionRankedProductA,
        [Design.DesignB]: AccordionRankedProductB,
        [Design.DesignC]: AccordionRankedProductC,
    });
    return <Component {...props} />;
};

export default AccordionRankedProduct;
