import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import BottomStickyPopupA from './designA/bottom-sticky-popup';
import BottomStickyPopupB from './designB/bottom-sticky-popup';
import BottomStickyPopupC from './designC/bottom-sticky-popup';

const BottomStickyPopup: React.FC<PopupProps> = (props) => {
    const Component = useDesignComponents<typeof BottomStickyPopupA | typeof BottomStickyPopupB | typeof BottomStickyPopupC>({
        [Design.DesignA]: BottomStickyPopupA,
        [Design.DesignB]: BottomStickyPopupB,
        [Design.DesignC]: BottomStickyPopupC,
    });

    return <Component {...props} />;
};

export default BottomStickyPopup;
