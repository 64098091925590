import BodyText from 'src/legacy/components/common/text/body-text';
import Star from '@legacy/designs/star';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { ReactNode } from 'react';
import { StarRatingProps } from '..';

const StarRatingA: React.FC<StarRatingProps> = ({ total, onTotalClick, color, starSize, ...props }) => {
    const { theme } = useHarmony();

    const starList = (): ReactNode => {
        const result = [];
        for (let i = 0; i < 5; i++) {
            result.push(<Star color={color} active={i <= props.averageRating - 1} key={i} size={starSize} />);
        }
        return result;
    };

    return (
        <StyledHStack theme={theme}>
            <div>{starList()}</div>
            {total !== undefined && (
                <TotalButton color={theme.gray[500]} size="14px" role="button" onClick={onTotalClick}>
                    ({total})
                </TotalButton>
            )}
        </StyledHStack>
    );
};

export default StarRatingA;

const StyledHStack = styled.div<IThemeProps>`
    display: flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: -0.03em;
    line-height: 100%;
    gap: 2px;
    ${({ theme }) => `
        font-family: ${theme.font.secondary.light};
        color: ${theme.gray[600]};
    `}
`;

const TotalButton = styled(BodyText)`
    font-weight: 300;
    text-decoration-line: underline;
    ${({ onClick }) => `
        cursor: ${onClick ? 'pointer' : 'default'};
    `}
`;
