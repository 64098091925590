import ChannelCacheKeys from '@api/channel/cache-keys';
import { ProductClient } from '@api/product/product.client';
import { Box, Center } from '@chakra-ui/react';
import Loader from '@designs/primitives/common/loader';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { useLazyLoad } from '@hooks/use-lazy-load';
import { default as ChannelComponentType } from '@models/channel-component';
import { ChannelComponentTypeEnum, IChannelComponentDto, IChannelComponentItemDto } from '@models/channel-component/responseDto/channel-component.dto';
import { useMemo } from 'react';
import useSWR from 'swr';
import Banner from './banner';
import Brand from './brand';
import Divider from './divider';
import IconMenu from './icon-menu';
import ImageList from './image-list';
import ImageMenu from './image-menu';
import Magazine from './magazine';
import OfflineStore from './offline-store';
import Points from './points';
import ProductList from './product-list';
import Ranking from './ranking';
import SubMenu from './sub-menu';
import TimeSale from './time-sale';

interface ChannelComponentProps {
    index: number;
    componentDto: IChannelComponentDto;
}

/** C&P from LegacyHomeChannelComponent. */
const ChannelComponent: React.FC<ChannelComponentProps> = ({ index, componentDto }) => {
    const { shouldLoad, ref } = useLazyLoad();
    const { designSettings } = useHarmony();
    const { extensionStatus } = useExtension();

    const { data: componentItemDtos, isValidating } = useSWR<IChannelComponentItemDto[] | undefined>(
        shouldLoad ? ChannelCacheKeys.channelComponentProductsCacheKey(index) : undefined,
        () => {
            if (shouldLoad) {
                return Promise.all(
                    componentDto.items.map(async (item, i) => {
                        if (item.mainDisplayProductList && (channelComponent.componentType !== ChannelComponentTypeEnum.SubMenu || i === 0)) {
                            const paginatedProducts = await ProductClient.postComponentProducts({
                                productIds: item.mainDisplayProductList as string[],
                                displayOutOfStock: designSettings?.isStockBadgeOn,
                            });
                            return {
                                ...item,
                                mainDisplayProductList: paginatedProducts.items,
                            };
                        } else {
                            return item;
                        }
                    })
                );
            } else {
                return undefined;
            }
        }
    );

    const channelComponent = useMemo<ChannelComponentType>(() => {
        return new ChannelComponentType(componentDto, componentItemDtos);
    }, [componentDto, componentItemDtos]);

    const componentElement = useMemo(() => {
        switch (channelComponent.componentType) {
            case ChannelComponentTypeEnum.Banner:
                return <Banner channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.IconMenu:
                return <IconMenu channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.ImageList:
                return <ImageList channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.ProductList:
                return <ProductList channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.SubMenu:
                return <SubMenu channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.TimeSale:
                return <TimeSale channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.RankList:
                return <Ranking channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.Magazine:
                return <Magazine channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.ImageMenu:
                return <ImageMenu channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.Divider:
                return <Divider channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.Points:
                return <Points />;
            case ChannelComponentTypeEnum.Brand:
                return <Brand channelComponent={channelComponent} />;
            case ChannelComponentTypeEnum.OfflineStore:
                return <OfflineStore channelComponent={channelComponent} />;
            default:
                return <></>;
        }
    }, [channelComponent]);

    const minHeight = (() => {
        switch (channelComponent.componentType) {
            case ChannelComponentTypeEnum.Divider:
            case ChannelComponentTypeEnum.Points:
                return 0;
            case ChannelComponentTypeEnum.Banner:
            case ChannelComponentTypeEnum.IconMenu:
                return 100;
            default:
                return 250;
        }
    })();

    if (channelComponent.componentType === ChannelComponentTypeEnum.OfflineStore && !extensionStatus?.offlineStore.isEnabled) {
        return null;
    }

    return (
        <Box ref={ref} minHeight={minHeight}>
            {(componentItemDtos || channelComponent.isNoItemComponent) && componentElement}
            {isValidating && !componentItemDtos && (
                <Center minHeight={minHeight}>
                    <Loader />
                </Center>
            )}
        </Box>
    );

    return null;
};

export default ChannelComponent;
