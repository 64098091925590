import { Grid } from '@chakra-ui/react';
import FixedSubMenuCell from '@designs/primitives/main/sub-menu/fixed-sub-menu-cell';
import { useHarmony } from '@hooks/use-harmony';
import { FixedSubMenuInnerProps } from '..';

const FixedSubMenuD = <T,>({ items, onChange, selectedItem, cellHeight, containerRef }: FixedSubMenuInnerProps<T>) => {
    const { theme } = useHarmony();

    return (
        <Grid ref={containerRef} templateColumns="repeat(3, 1fr)" borderRadius="4px" borderTop="1px solid" borderColor={theme.stroke.divider} boxShadow={theme.dropShadow.shadow3}>
            {items.map((item, index) => (
                <FixedSubMenuCell key={index} onClick={() => onChange(item)} isActive={item.value === selectedItem?.value} height={cellHeight}>
                    {item.label}
                </FixedSubMenuCell>
            ))}
        </Grid>
    );
};

export default FixedSubMenuD;
