import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useWindowSize } from '@hooks/use-window-size';
import ChannelComponent from '@models/channel-component';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import { useMemo, useRef } from 'react';
import { BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';

interface IconMenuBProps {
    channelComponent: ChannelComponent;
}

const MAX_COLUMN_COUNT = 6;
const MAX_ITEMS_COUNT = 18;

const DEVICE_WIDTH = {
    SMALL: '(max-width: 360px)',
    MEDIUM_1: '(min-width: 361px) and (max-width: 390px)',
    MEDIUM_2: '(min-width: 391px) and (max-width: 414px)',
    LARGE_1: '(min-width: 415px) and (max-width: 430px)',
    LARGE_2: '(min-width: 430px)',
};

const IconMenuB: React.FC<IconMenuBProps> = ({ channelComponent }) => {
    const [screenWidth] = useWindowSize();
    const iconMenuContainerRef = useRef<HTMLDivElement>(null);
    const { theme } = useHarmony();
    const itemGroups = useMemo(() => {
        return channelComponent.itemGroups(
            Math.ceil(channelComponent.items.length <= MAX_COLUMN_COUNT ? channelComponent.items.length : channelComponent.items.length / 2),
            MAX_ITEMS_COUNT
        );
    }, [channelComponent]);
    return (
        <ScrollContainer theme={theme} ref={iconMenuContainerRef}>
            <BlockVStack padding={`16px`} spacing="12px">
                {itemGroups.map((group, i) => (
                    <IconRow key={i} centered={itemGroups.length === 1 || Number(iconMenuContainerRef.current?.offsetWidth) <= screenWidth}>
                        {group.map((item, j) => (
                            <InlineContainer centered={itemGroups.length === 1 || Number(iconMenuContainerRef.current?.offsetWidth) <= screenWidth} key={j}>
                                <Link href={item.legacyLink}>
                                    <a className={!item.legacyLink ? 'disabled' : ''}>
                                        <ClickableVStack spacing="3px">
                                            {item.image && (
                                                <IconContainer theme={theme} isIcon={item.isIcon}>
                                                    <StyledImage theme={theme} isIcon={item.isIcon} src={item.image} alt={item.name} />
                                                </IconContainer>
                                            )}
                                            <BodyText weight="medium" size="14px" lineHeight="18px" textAlign="center" wordBreak="break-word">
                                                {item.displayName}
                                            </BodyText>
                                        </ClickableVStack>
                                    </a>
                                </Link>
                            </InlineContainer>
                        ))}
                    </IconRow>
                ))}
            </BlockVStack>
        </ScrollContainer>
    );
};

export default IconMenuB;

const ScrollContainer = styled.div<IThemeProps>`
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: ${({ theme }) => theme.background.config};
    .disabled {
        pointer-events: none;
    }
`;

const InlineContainer = styled.div<{ centered: boolean }>`
    display: flex;
    margin: 0;
    justify-content: center;
    width: ${({ centered }) => (centered ? '100%' : 'auto')};
`;

const IconRow = styled.div<{ centered: boolean }>`
    @media ${DEVICE_WIDTH.SMALL} {
        gap: 10px;
    }
    @media ${DEVICE_WIDTH.MEDIUM_1} {
        gap: 13px;
    }
    @media ${DEVICE_WIDTH.MEDIUM_2} {
        gap: 10px;
    }
    @media ${DEVICE_WIDTH.LARGE_1} {
        gap: 12px;
    }
    @media ${DEVICE_WIDTH.LARGE_2} {
        gap: 17px;
    }

    display: flex;
    & > div:last-child {
        padding-right: ${({ centered }) => (centered ? '0' : '16px')};
    }
`;

const IconContainer = styled.div<IThemeProps & { isIcon: boolean }>`
    ${({ theme, isIcon }) => `
        @media ${DEVICE_WIDTH.SMALL} {
            width: 50px;
            height: 66px;
        }
        @media ${DEVICE_WIDTH.MEDIUM_1}  {
            width: 60px;
            height: 79px;
        }
        @media ${DEVICE_WIDTH.MEDIUM_2}  {
            width: 60px;
            height: 79px;
        }
        @media ${DEVICE_WIDTH.LARGE_1}  {
            width: 60px;
            height: 79px;
        }
        @media ${DEVICE_WIDTH.LARGE_2} {
            width: 74px;
            height: 97px;
        }
        background: ${isIcon ? `${theme.primary[300]}` : 'transparent'};
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;

const StyledImage = styled.img<IThemeProps & { isIcon: boolean }>`
    ${({ isIcon, theme }) =>
        isIcon
            ? `
        filter: ${ThemeUtils.colorHexToCssFilter(theme.primary.default)} drop-shadow(0px 0px 6px ${theme.primary[400]});
        @media ${DEVICE_WIDTH.SMALL} {
            width: 24px;
            height: 24px;
        }
        @media ${DEVICE_WIDTH.MEDIUM_1} {
            width: 28px;
            height: 28px;
        }
        @media ${DEVICE_WIDTH.LARGE_2} {
            width: 34px;
            height: 34px;
        }
    `
            : `
        width: 100%;
        height: 100%;
        object-fit:cover;
    `}
`;

const ClickableVStack = styled(BlockVStack)`
    @media ${DEVICE_WIDTH.SMALL} {
        width: 67px;
    }
    @media ${DEVICE_WIDTH.MEDIUM_1} {
        width: 68px;
    }
    @media ${DEVICE_WIDTH.MEDIUM_2} {
        width: 68px;
    }
    @media ${DEVICE_WIDTH.LARGE_1} {
        width: 68px;
    }
    @media ${DEVICE_WIDTH.LARGE_2} {
        width: 86px;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
`;
