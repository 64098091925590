import styled from '@emotion/styled';
import { GridContainerProps } from '..';

const GridContainerC = styled.div<GridContainerProps>`
    ${({ cols, gap, padding }) => `
        display: grid;
        grid-template-columns: repeat(${cols}, 1fr);
        gap: ${gap || '24px 8px'};
        place-content: center center;
        width: 100%;
        padding: ${padding || '8px 16px'};
        min-height: 160px;
`}
`;

export default GridContainerC;
