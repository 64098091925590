import { Box, VStack } from '@chakra-ui/react';
import MagazineBlock from '@designs/primitives/main/magazine/magazine-block';
import { useHarmony } from '@hooks/use-harmony';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MagazineProps } from '..';

const MagazineD: React.FC<MagazineProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <>
            {channelComponent.displayType === ComponentDisplayTypeEnum.List && (
                <VStack as="section" spacing="16px" p="16px" align="stretch">
                    {channelComponent.items.map((item, index) => (
                        <MagazineBlock key={index} componentItem={item} imageRatio={channelComponent.imageRatio} />
                    ))}
                </VStack>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                <Box w="100%" py="16px">
                    <Swiper slidesPerView={1.4} spaceBetween={8} style={{ padding: '16px 16px', margin: '-16px 0px' }}>
                        {channelComponent.items.map((componentItem, i) => (
                            <SwiperSlide key={i}>
                                <MagazineBlock componentItem={componentItem} imageRatio={channelComponent.imageRatio} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            )}
        </>
    );
};

export default MagazineD;
