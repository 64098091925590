import { useHarmony } from '@hooks/use-harmony';
import { OfflineStoreCardProps } from '..';
import { Box, VStack } from '@chakra-ui/react';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import RoutesEnum from '@routes';
import SanitationUtils from '@utils/sanitation.utils';

const OfflineStoreCardA: React.FC<OfflineStoreCardProps> = ({ offlineStore }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Link href={`${RoutesEnum.OfflineStore}/${offlineStore.id}`}>
            <VStack align="flex-start" w="120px">
                <Box w="120px" h="120px" pos="relative" flexShrink={0} border="1px solid" borderColor={theme.gray[50]}>
                    <CustomImage src={offlineStore.imageUrl} layout="fill" objectFit="cover" />
                </Box>
                <BodyText size="16px" weight="bold" lineHeight="20px" color={theme.gray[800]} ellipsis lineClamp={2} wordBreak="break-all">
                    {offlineStore.name}
                </BodyText>
                <VStack w="100%" align="flex-start" spacing="4px">
                    <BodyText size="16px" color={theme.gray[800]} ellipsis lineClamp={2} wordBreak="break-all" lineHeight="20px">
                        {offlineStore.baseAddress} {offlineStore.detailAddress}
                    </BodyText>
                    <BodyText size="16px" color={theme.gray[800]} lineHeight="20px">
                        {SanitationUtils.sanitizePhoneNumber(offlineStore.phone)}
                    </BodyText>
                    <Box
                        display="flex"
                        h="22px"
                        p="3px 8px 5px"
                        bgColor={offlineStore.isOpenForBusiness ? theme.status.information : theme.gray[400]}
                        boxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.12)"
                    >
                        <BodyText size="16px" weight="medium" color={theme.white} lineHeight="14px">
                            {offlineStore.isOpenForBusiness ? t('store.open') : t('store.ready')}
                        </BodyText>
                    </Box>
                </VStack>
            </VStack>
        </Link>
    );
};

export default OfflineStoreCardA;
