import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import ComponentProduct from '@models/product/component-product';
import FixedRankListA from './designA/fixed-rank-list';
import FixedRankListB from './designB/fixed-rank-list';
import FixedRankListC from './designC/fixed-rank-list';

export interface FixedRankListProps {
    productList: ComponentProduct[] | undefined;
    page: number;
    totalPage: number;
    itemsPerPage: number;
    prev: () => void;
    next: () => void;
    rankTotal: number;
}

const FixedRankList: React.FC<FixedRankListProps> = (props) => {
    const Component = useDesignComponents<typeof FixedRankListA | typeof FixedRankListB | typeof FixedRankListC>({
        [Design.DesignA]: FixedRankListA,
        [Design.DesignB]: FixedRankListB,
        [Design.DesignC]: FixedRankListC,
    });

    return <Component {...props} />;
};

export default FixedRankList;
