import { ImageUrl } from '@type/models';
import { IPopupItemDto } from './responseDto/popup-item.dto';
import { Url } from '@type/common';

class PopupItem {
    private _index: number;
    private _image: ImageUrl;
    private _linkPageId: string;
    private _linkPagePath: Url;
    private _linkPageUrl: Url;
    private _startDt: string;
    private _endDt: string;
    private _isContinuousExposure: boolean;

    constructor(popupItemDto: IPopupItemDto) {
        this._index = popupItemDto.index;
        this._image = popupItemDto.image;
        this._linkPageId = popupItemDto.linkPageId;
        this._linkPagePath = popupItemDto.linkPagePath;
        this._linkPageUrl = popupItemDto.linkPageUrl;
        this._startDt = popupItemDto.startDt;
        this._endDt = popupItemDto.endDt;
        this._isContinuousExposure = popupItemDto.isContinuousExposure;
    }

    get index(): number {
        return this._index;
    }

    get image(): ImageUrl {
        return this._image;
    }

    get linkPageId(): string {
        return this._linkPageId;
    }

    get linkPagePath(): Url {
        return this._linkPagePath || '';
    }

    get linkPageUrl(): Url {
        return this._linkPageUrl || '';
    }

    get startDt(): string {
        return this._startDt;
    }

    get endDt(): string {
        return this._endDt;
    }

    get isContinuousExposure(): boolean {
        return this._isContinuousExposure;
    }
}

export default PopupItem;
