import { BadgeAProps } from '..';
import AbstractedText from '@components/abstracted-text';

const BadgeAD: React.FC<BadgeAProps> = (props) => {
    return (
        <AbstractedText fontSize="12px" weight="medium" lineHeight="12px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default BadgeAD;
