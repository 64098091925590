import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { ProductListContainerProps } from '..';

const ProductListContainerC = styled.div<ProductListContainerProps & IThemeProps>`
    width: 100%;
    padding: 10px 0 16px;
    border-radius: 20px;

    ${({ theme, padding }) => `
      background-color: ${theme.white};
      padding: ${padding};
  `}
`;

export default ProductListContainerC;
