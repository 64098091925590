import { BlockAccordion, BlockAccordionContent, BlockAccordionItem, BlockAccordionTrigger, BlockAccordionTypeEnum } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import AccordionRankedProduct from '@legacy/designs/accordion-ranked-product';
import PaginatedControls from '@legacy/designs/paginated-controls';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { HorizPadContainer } from '@styles/styled-components';
import { AccordionRankListProps } from '..';

const AccordionRankListA: React.FC<AccordionRankListProps> = ({ paginatedProductList, itemsPerPage, page, totalPage, prev, next }) => {
    const { theme } = useHarmony();

    return (
        <>
            <BlockAccordion type={BlockAccordionTypeEnum.single} defaultValue="0">
                {paginatedProductList?.map((product, i) => (
                    <BlockAccordionItem value={i.toString()} key={i}>
                        <HorizPadContainer>
                            <BlockAccordionTrigger>
                                <RankItemContainer>
                                    <BodyText size="18px" weight="extraBold" font="secondary" color={theme.primary.default}>
                                        {itemsPerPage * (page - 1) + (i + 1)}.
                                    </BodyText>{' '}
                                    <BodyText size="18px">{product.title}</BodyText>
                                </RankItemContainer>
                            </BlockAccordionTrigger>
                        </HorizPadContainer>
                        <BlockAccordionContent>
                            <AccordionRankedProduct product={product} />
                        </BlockAccordionContent>
                    </BlockAccordionItem>
                ))}
            </BlockAccordion>
            {totalPage > 1 && <PaginatedControls margin={'18px 0 0'} active={page - 1} total={totalPage} prev={prev} next={next} />}
        </>
    );
};

export default AccordionRankListA;

const RankItemContainer = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
