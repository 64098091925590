import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { PaginatedControlsProps } from '..';

const PaginatedControlsA: React.FC<PaginatedControlsProps> = ({ active, total, prev, next }) => {
    const { theme } = useHarmony();

    return (
        <StyledControls theme={theme}>
            <div className="icon" onClick={prev}>
                <CustomIcon size="18px" color={theme.gray[500]}>
                    chevron_left
                </CustomIcon>
            </div>
            <PaddedContainer padding="0xp 20px">
                <BodyText size="15px">{active + 1}</BodyText>
                <BodyText size="15px" color={theme.gray[500]}>
                    {' '}
                    / {total}
                </BodyText>
            </PaddedContainer>
            <div className="icon" onClick={next}>
                <CustomIcon size="18px" color={theme.gray[500]}>
                    chevron_right
                </CustomIcon>
            </div>
        </StyledControls>
    );
};

export default PaginatedControlsA;

const StyledControls = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.gray[600]};
        padding: 32px 0 0 0;
        gap: 20px;
        .icon {
            display: flex;
            align-items: center;
            height: 28px;
            padding: 0px 20px;
            border: 1px solid ${theme.gray[50]};
            cursor: pointer;
        }
    `}
`;
