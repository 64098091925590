import { Box, HStack } from '@chakra-ui/react';
import { OfflineStoreInnerProps } from '..';
import OfflineStoreCard from '@designs/primitives/main/offline-store/offline-store-card';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import DesignedContainer from '@designs/primitives/common/designed-container';
import MoreButton from '@designs/primitives/main/main-section-title/more-button';
import { useTranslations } from 'next-intl';
import RoutesEnum from '@routes';
import EmptyMessage from '@designs/grouped/common/empty-message';

const OfflineStoreD: React.FC<OfflineStoreInnerProps> = ({ offlineStores }) => {
    const t = useTranslations();
    return (
        <>
            <MainSectionTitle title={t('store.storeInfo')} />

            <Box px="8px">
                <DesignedContainer py="16px">
                    {!offlineStores?.length && <EmptyMessage>{t('store.noStores')}</EmptyMessage>}
                    {offlineStores && (
                        <HStack w="100%" px="12px" spacing="8px" overflowX="auto" align="flex-start">
                            {offlineStores.map((offlineStore) => (
                                <OfflineStoreCard key={offlineStore.id} offlineStore={offlineStore} />
                            ))}
                        </HStack>
                    )}

                    <Box m="24px 12px 0px 20px">
                        <MoreButton pagePath={RoutesEnum.OfflineStore} />
                    </Box>
                </DesignedContainer>
            </Box>
        </>
    );
};

export default OfflineStoreD;
