import { Box } from '@chakra-ui/react';
import Timer from '@designs/grouped/common/timer';
import DesignedContainer from '@designs/primitives/common/designed-container';
import { TimeSaleProductCardProps } from '..';
import FixedProductCard from '../../fixed-product-card';

const TimeSaleProductCardD: React.FC<TimeSaleProductCardProps> = ({ isEnded, isStarted, timeRemaining, isDisabled, ...productCardProps }) => {
    return (
        <DesignedContainer w="100%" overflow="hidden">
            <Timer isStarted={isStarted} isEnded={isEnded} timeRemaining={timeRemaining} />

            <Box p="8px 8px 16px">
                <FixedProductCard {...productCardProps} isDisabled={isDisabled || !isStarted || isEnded} isEllipsisBrandName={false} />
            </Box>
        </DesignedContainer>
    );
};

export default TimeSaleProductCardD;
