import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import React from 'react';
import GridContainerA from './designA/grid-container';
import GridContainerB from './designB/grid-container';
import GridContainerC from './designC/grid-container';

export interface GridContainerProps {
    cols: number;
    padding?: string;
    gap?: string;
    marginTop?: string;
    children: React.ReactNode;
}

const GridContainer: React.FC<GridContainerProps> = ({ children, ...props }) => {
    const Component = useDesignComponents<typeof GridContainerA | typeof GridContainerB | typeof GridContainerC>({
        [Design.DesignA]: GridContainerA,
        [Design.DesignB]: GridContainerB,
        [Design.DesignC]: GridContainerC,
    });
    return <Component {...props}>{children}</Component>;
};

export default GridContainer;
