import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import theme from '@styles/theme';

interface IAgeVerification {
    width?: string;
    height?: string;
}

export const AgeVerificationIcon: React.FC<IAgeVerification> = ({ width = '24px', height = '24px' }) => {
    return (
        <Center backgroundColor={theme.white} width={width} height={height} borderRadius="50%" border={`1px solid ${theme.text.error}`}>
            <Text color={theme.text.error}>19</Text>
        </Center>
    );
};
