import CustomImage from 'src/legacy/components/common/custom-image';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import ProductListContainer from '@legacy/designs/product-list-container';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useWindowSize } from '@hooks/use-window-size';
import { LineClipText } from '@styles/styled-components';
import Link from 'next/link';
import { ImageMenuProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const ImageMenuB: React.FC<ImageMenuProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const [width] = useWindowSize({ maxWidth: true });

    const categories = channelComponent.items;

    return (
        <ProductListContainer padding={'16px 0'}>
            <HomeComponentHeading title={channelComponent.title} />
            <GridContainer>
                {categories?.length &&
                    categories.map((category) => (
                        <Link href={category.pageUrl || ''} key={category.categoryId}>
                            <a className={!category.pageUrl ? 'disabled' : ''}>
                                <CategoryBox windowWidth={width} heightRatio={channelComponent.imageHeightFactor}>
                                    {category.image && <BoxBackground src={category.image} alt={category.name} layout="fill" objectFit="cover" />}
                                    <BoxLabel color={theme.white} size="18px" lineHeight="20px" weight="bold" lines={2}>
                                        {category.imageOverlayText}
                                    </BoxLabel>
                                </CategoryBox>
                            </a>
                        </Link>
                    ))}
            </GridContainer>
        </ProductListContainer>
    );
};

export default ImageMenuB;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: 8px 16px;
    gap: 16px;

    .disabled {
        pointer-events: none;
    }
`;

const CategoryBox = styled.div<{ windowWidth: number; heightRatio: number }>`
    ${({ windowWidth, heightRatio }) => `
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(((${windowWidth}px - 32px) / 2) * ${heightRatio});
        filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 2px 3px)
    `}
`;

const BoxBackground = styled(CustomImage)`
    filter: brightness(0.75);
`;

const BoxLabel = styled(LineClipText)`
    z-index: ${ThemeUtils.zIndex.above};
    text-align: center;
    text-transform: uppercase;
    padding: 0 16px;
    letter-spacing: -0.01em;
`;
