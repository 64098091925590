import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { PaginatedControlsProps } from '..';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';

const PaginatedControlsC: React.FC<PaginatedControlsProps> = ({ active, total, prev, next, margin }) => {
    const { theme } = useHarmony();

    return (
        <BlockHStack align="center" margin={margin}>
            <StyledControls>
                <CustomIconButton size="24px" color={active <= 0 ? theme.gray[200] : theme.gray[800]} onClick={prev}>
                    chevron_left
                </CustomIconButton>
                <TextWrapper align="center" spacing="6px">
                    <BodyText size="17px" color={theme.gray[900]}>
                        {active + 1}
                    </BodyText>
                    <BodyText size="17px" color={theme.gray[600]}>
                        /
                    </BodyText>
                    <BodyText size="17px" color={theme.gray[600]}>
                        {total}
                    </BodyText>
                </TextWrapper>
                <CustomIconButton size="24px" color={active >= total - 1 ? theme.gray[200] : theme.gray[700]} onClick={next}>
                    chevron_right
                </CustomIconButton>
            </StyledControls>
        </BlockHStack>
    );
};

export default PaginatedControlsC;

const StyledControls = styled.div`
    background: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    height: 40px;
    gap: 14px;
`;

const TextWrapper = styled(BlockHStack)`
    span {
        display: inline-block;
        text-align: center;
        width: 16px;
    }
`;
