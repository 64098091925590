import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import StyledImage from '@legacy/designs/product-card-image/designA/product-card-image';
import { ListPrice } from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import { LineClipText } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges from 'src/legacy/components/product/product-badges';
import { RankedProductContainerProps, SwipeRankedProductProps } from '..';
import { Overlay, ProductDetailsContainer } from '../designB/swipe-ranked-product';
import ThemeUtils from '@utils/theme.utils';
import { useCustomer } from '@hooks/use-customer/use-customer';

const SwipeRankedProductA: React.FC<SwipeRankedProductProps> = ({ product, rank, width = 1, focused, onClick }) => {
    const { theme, designSettings } = useHarmony();
    const t = useTranslations();
    const { currencyService } = useGlobalization();
    const slideWidth = width * 0.6;
    const { isAuth } = useCustomer();

    return (
        <RelativeContainer onClick={() => onClick?.(product.id)}>
            <BlockVStack spacing="8px" width={`${slideWidth}px`}>
                <BlockVStack spacing="4px">
                    <Ranking theme={theme}>
                        <BodyText font="secondary" weight="bold" size="26px" color={theme.primary[600]}>
                            {String(rank)?.padStart(2, '0')}.
                        </BodyText>
                    </Ranking>
                    <Banner columnWidth={slideWidth} theme={theme} focused={!!focused}>
                        <Overlay active={!focused} />
                        {product.image && (
                            <div className="image">
                                <StyledImage src={product.image} alt="TODO" layout="fill" theme={theme} disabled={product.isSoldOut} />
                            </div>
                        )}
                        <BadgeContainer>
                            <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
                        </BadgeContainer>
                        <WishlistContainer>
                            <WishListButton className="heart" size="24px" product={product} />
                        </WishlistContainer>
                    </Banner>
                </BlockVStack>
                <ProductDetailsContainer spacing="1px" focused={!!focused}>
                    <BlockVStack spacing="3px">
                        <LineClipText size="15px" lineHeight="18px" textAlign="left" lines={2} letterSpacing={'-0.01em'} weight={'medium'}>
                            {product.showBrandOnProductCard && (
                                <BodyText size="15px" lineHeight="18px" color={theme.primary.default} letterSpacing={'-0.01em'} weight={'bold'}>
                                    [{product.brand}]
                                </BodyText>
                            )}
                            {product.title}
                        </LineClipText>
                        <BlockVStack spacing="none">
                            {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                                <ListPrice size="15px" color={theme.gray[400]}>
                                    {product.marketPriceString}
                                </ListPrice>
                            )}
                            <BlockHStack alignItems="flex-end" spacing="4px">
                                {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                                    <BodyText weight="bold" size="18px" lineHeight="18px" color={theme.primary.default}>
                                        {product.discountRate}%
                                    </BodyText>
                                )}
                                <BlockHStack alignItems="flex-end" spacing="2px">
                                    {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                                        <>
                                            {product.isSubscriptionSalesType && (
                                                <BodyText size="15px" lineHeight="16px" textAlign="center">
                                                    {t('common.month')}/
                                                </BodyText>
                                            )}
                                            <BlockHStack alignItems="flex-end" spacing="none">
                                                <BodyText weight="bold" size="18px" lineHeight="18px" textAlign={'center'}>
                                                    {product.timesaleAppliedNow ? product.minSellPriceAfterReduction : product.minSellPriceString}
                                                </BodyText>
                                                <BodyText size="15px" lineHeight="18px" textAlign={'center'}>
                                                    {currencyService.currencySymbol}
                                                </BodyText>
                                            </BlockHStack>
                                        </>
                                    ) : (
                                        <BodyText weight="bold" size="18px" lineHeight="18px" textAlign={'center'}>
                                            {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                                        </BodyText>
                                    )}
                                </BlockHStack>
                            </BlockHStack>
                        </BlockVStack>
                    </BlockVStack>
                    <BlockVStack width="fit-content">
                        <TextBadge />
                    </BlockVStack>
                </ProductDetailsContainer>
            </BlockVStack>
        </RelativeContainer>
    );
};

export default SwipeRankedProductA;

const Banner = styled.div<RankedProductContainerProps & IThemeProps & { focused: boolean }>`
    ${({ columnWidth, theme }) => `
        width: ${columnWidth}px;
        position: relative;
        height: ${columnWidth}px;
        overflow: hidden;
        border: 1px solid ${theme.gray[200]}};
    `}
`;

const Ranking = styled.div<IThemeProps>`
    ${({ theme }) => `
        height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    `}
`;

const BadgeContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: ${ThemeUtils.zIndex.badge};
`;

const RelativeContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    position: relative;
    cursor: pointer;
`;

const WishlistContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;
