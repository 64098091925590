import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import PaginationA from './designA/legacy-pagination';
import PaginationB from './designB/legacy-pagination';
import PaginationC from './designC/legacy-pagination';

export interface PaginationProps {
    index: number;
    total: number;
}

const LegacyPagination: React.FC<PaginationProps> = (props) => {
    const Component = useDesignComponents<typeof PaginationA | typeof PaginationB | typeof PaginationC>({
        [Design.DesignA]: PaginationA,
        [Design.DesignB]: PaginationB,
        [Design.DesignC]: PaginationC,
    });

    return <Component {...props} />;
};

export default LegacyPagination;
