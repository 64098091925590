import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import RankingD from './designD/ranking';

export interface RankingProps {
    channelComponent: ChannelComponent;
}

const Ranking: React.FC<RankingProps> = (props) => {
    const Component = useDesignComponents<ComponentType<RankingProps>>({
        [Design.DesignD]: RankingD,
    });

    return <Component {...props} />;
};

export default Ranking;
