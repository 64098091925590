import styled from '@emotion/styled';
import { SwipeProductListContainerProps } from '..';

const SwipeProductListContainerA = styled.div<SwipeProductListContainerProps>`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 8px 16px;
    overflow-y: auto;
    user-select: none;
`;

export default SwipeProductListContainerA;
