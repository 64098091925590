import { useDesignComponents } from '@hooks/use-design-components';

import { BlockHStack } from 'src/legacy/components/blockui';
import FixedListProduct from '@legacy/designs/fixed-list-product/fixed-list-product';
import GridContainer from '@legacy/designs/grid-container';
import NoProduct from '@legacy/designs/no-product';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { Design } from '@type/harmony-config';
import { Swiper, SwiperSlide } from 'swiper/react';
import SubmenuButton from './submenu-button';

interface SubmenuSwipeProps {
    component: ChannelComponent;
    item?: ChannelComponentItem;
}

const SubmenuSwipe: React.FC<SubmenuSwipeProps> = ({ component, item }) => {
    const { designSettings } = useHarmony();

    const marginTop = useDesignComponents<string>({
        [Design.DesignA]: '8px',
        [Design.DesignB]: '16px',
    });
    const noProductPb = useDesignComponents<string>({
        [Design.DesignA]: '16px',
        [Design.DesignB]: '16px',
        [Design.DesignC]: '0',
    });
    const noProductSize = useDesignComponents<string>({
        [Design.DesignA]: '200px',
        [Design.DesignB]: '100%',
        [Design.DesignC]: '',
    });
    const hasSubmenuButton = designSettings?.isDesignA || designSettings?.isDesignB;

    return item ? (
        <SwipeContainer>
            <Swiper>
                {item.paginatedProductList.map((page, i) => (
                    <SwiperSlide key={i}>
                        <GridContainer cols={item.productCols} marginTop={marginTop}>
                            {page.map((product) => {
                                return <FixedListProduct key={product.id} product={product} cols={item.productCols} />;
                            })}
                            {page.length < 1 && [...new Array(item.productCols)].map((_, i) => <NoProduct key={i} size={'100%'} />)}
                        </GridContainer>
                    </SwiperSlide>
                ))}

                {item.paginatedProductList.length < 1 && (
                    <GridContainer cols={item.productCols} marginTop={marginTop}>
                        {[...new Array(designSettings?.isDesignC ? item.productCols : item.productCols * item.productRows)].map((_, i) => (
                            <BlockHStack key={i} pb={noProductPb} align="center" alignItems="center">
                                <NoProduct size={noProductSize} large={item.productCols === 1} />
                            </BlockHStack>
                        ))}
                    </GridContainer>
                )}
            </Swiper>
            {hasSubmenuButton && <SubmenuButton component={component} item={item} />}
        </SwipeContainer>
    ) : (
        <span></span>
    );
};

export default SubmenuSwipe;

const SwipeContainer = styled.div`
    width: 100%;

    .swiper-slide {
        height: 100%;
    }
`;
