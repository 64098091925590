import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import React from 'react';
import ProductListContainerA from './designA/product-list-container';
import ProductListContainerB from './designB/product-list-container';
import ProductListContainerC from './designC/product-list-container';

export interface ProductListContainerProps {
    padding?: string;
    children: React.ReactNode;
}

const ProductListContainer: React.FC<ProductListContainerProps> = (props) => {
    const { theme } = useHarmony();
    const Component = useDesignComponents<typeof ProductListContainerA | typeof ProductListContainerB | typeof ProductListContainerC>({
        [Design.DesignA]: ProductListContainerA,
        [Design.DesignB]: ProductListContainerB,
        [Design.DesignC]: ProductListContainerC,
    });

    return <Component theme={theme} {...props} />;
};

export default ProductListContainer;
