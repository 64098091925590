import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { PaginationProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const LegacyPaginationC: React.FC<PaginationProps> = ({ index, total }) => {
    const { theme } = useHarmony();

    return (
        <Pagination theme={theme}>
            <BodyText key={index} size="14px" color={theme.white}>
                {index + 1} / {total}
            </BodyText>
        </Pagination>
    );
};

export default LegacyPaginationC;

const Pagination = styled.div`
    position: absolute;
    top: 12px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    z-index: ${ThemeUtils.zIndex.above};
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 100px;
`;
