import { DetailAProps } from '..';
import AbstractedText from '@components/abstracted-text';

const DetailAD: React.FC<DetailAProps> = (props) => {
    return (
        <AbstractedText fontSize="12px" weight="thin" lineHeight="18px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default DetailAD;
