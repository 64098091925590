import { ImageRatio } from '@type/models';
import PopupItem from '../popup-item';

export enum PopupPosition {
    CenterFloating = 'center_floating',
    BottomSticky = 'bottom_sticky',
}

export interface IPopupDto {
    position: PopupPosition;
    useButton: boolean;
    buttonName: string;
    imageRatio: ImageRatio;
    items: PopupItem[];
}
