import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import MainSectionTitleD from './designD/main-section-title';

export interface MainSectionTitleProps extends BoxProps {
    title?: string;
    subTitle?: string;
}

const MainSectionTitle: React.FC<MainSectionTitleProps> = (props) => {
    const Component = useDesignComponents<ComponentType<MainSectionTitleProps>>({
        [Design.DesignD]: MainSectionTitleD,
    });

    return <Component {...props} />;
};

export default MainSectionTitle;
