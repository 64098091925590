import { useDesignComponents } from '@hooks/use-design-components';

import { css } from '@emotion/react';
import { useHarmony } from '@hooks/use-harmony';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import ImageListImage from '@legacy/designs/image-list-image';
import ProductListContainer from '@legacy/designs/product-list-container';
import ChannelComponent from '@models/channel-component';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { Design } from '@type/harmony-config';
import Link from 'next/link';
import { Fragment } from 'react';
import ProductGallery from 'src/legacy/components/product/product-gallery';
import { ImageListProductList } from './product-list';

interface ImageListProps {
    channelComponent: ChannelComponent;
}

const ImageList: React.FC<ImageListProps> = ({ channelComponent }) => {
    const { designSettings } = useHarmony();

    const firstItemMarginTop = useDesignComponents<string>({
        [Design.DesignA]: '16px',
        [Design.DesignB]: '16px',
        [Design.DesignC]: '8px',
    });
    const marginTop = useDesignComponents<string>({
        [Design.DesignA]: '24px',
        [Design.DesignB]: '24px',
        [Design.DesignC]: '16px',
    });

    return (
        <ProductListContainer>
            <HomeComponentHeading title={channelComponent.title} subtitle={channelComponent.subtitle} />
            {channelComponent.displayType === ComponentDisplayTypeEnum.Scroll &&
                channelComponent.items.map((item, index: number) => {
                    const isLastItem = index === channelComponent.items.length - 1;

                    return (
                        <Fragment key={index}>
                            {item.image && (
                                <div
                                    css={css`
                                        width: 100%;
                                        height: auto;
                                        padding: 0px 16px;
                                        margin-top: ${index === 0 ? firstItemMarginTop : marginTop};
                                    `}
                                >
                                    {item.pageUrl && (
                                        <Link href={item.pageUrl}>
                                            <ImageListImage
                                                imageHeightFactor={channelComponent.imageHeightFactor}
                                                src={item.image}
                                                alt={item.name}
                                                layout={'fill'}
                                                objectFit="cover"
                                            />
                                        </Link>
                                    )}
                                    {!item.pageUrl && (
                                        <ImageListImage imageHeightFactor={channelComponent.imageHeightFactor} src={item.image} alt={item.name} layout={'fill'} objectFit="cover" />
                                    )}
                                </div>
                            )}
                            {item.mainDisplayProductList.length > 0 && (
                                <ImageListProductList paddingBottom={designSettings?.isDesignC && isLastItem ? '6px' : ''} productList={item.mainDisplayProductList} />
                            )}
                        </Fragment>
                    );
                })}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Button && <ProductGallery channelComponent={channelComponent} />}
        </ProductListContainer>
    );
};

export default ImageList;
