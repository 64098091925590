import styled from '@emotion/styled';
import { useDragScroll } from '@hooks/use-mouse-drag';
import SwipeListProduct from '@legacy/designs/swipe-list-product';
import ComponentProduct from '@models/product/component-product';

type ImageListProductListProps = {
    paddingBottom: string;
    productList: ComponentProduct[];
};

export const ImageListProductList = ({ paddingBottom, productList }: ImageListProductListProps) => {
    const dragScroll = useDragScroll();

    return (
        <ProductList paddingBottom={paddingBottom} {...dragScroll}>
            {productList.map((product) => {
                return <SwipeListProduct key={product.id} product={product} />;
            })}
        </ProductList>
    );
};

const ProductList = styled.div<{ paddingBottom: string }>`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 16px 16px 0;
    overflow-x: auto;
    overflow-y: hidden;
    user-select: none;
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;
