import { Box, VStack } from '@chakra-ui/react';
import TimeSaleProductCard from '@designs/grouped/common/product-card/time-sale-product-card';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import MoreButton from '@designs/primitives/main/main-section-title/more-button';
import { useCountdown } from '@hooks/use-countdown';
import { useHarmony } from '@hooks/use-harmony';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TimeSaleProps } from '..';
import { useGATracking } from '@hooks/use-ga-tracking';

const TimeSaleD: React.FC<TimeSaleProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const { time, end } = useCountdown(channelComponent.endDate);
    const { logEvent } = useGATracking();

    return (
        <VStack as="section" spacing="8px" pt="8px" pb="16px" align="stretch">
            <MainSectionTitle title={channelComponent.title} />
            <VStack spacing="16px" align="stretch">
                <Box sx={{ '.swiper-slide': { opacity: 0.5, transition: 'opacity 0.2s ease-in-out' }, '.swiper-slide-active': { opacity: 1 } }}>
                    <Swiper
                        loop={(channelComponent.firstItem?.mainDisplayProductList.length || 0) > 1}
                        slidesPerView={1.22}
                        centeredSlides
                        spaceBetween={8}
                        style={{ padding: '32px 0px', margin: '-32px 0px' }}
                    >
                        {channelComponent.firstItem?.mainDisplayProductList.map((product, index) => (
                            <SwiperSlide key={index}>
                                <Box
                                    onClick={() => {
                                        logEvent('select_promotion', {
                                            promotion_name: channelComponent.title,
                                        });
                                    }}
                                >
                                    <TimeSaleProductCard product={product} timeRemaining={time} isStarted={!channelComponent.isBeforeStart} isEnded={end} />
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
                {!!channelComponent.firstItem?.path && <MoreButton pagePath={channelComponent.firstItem.path} />}
            </VStack>
        </VStack>
    );
};

export default TimeSaleD;
