import AuthClient from '@api/auth/auth.client';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import AcrossMainPanel from '@components/across-adn/across-main-panel';
import { PassModuleTester } from '@components/pass-module-tester';
import MainContent from '@designs/pages/main-content';
import { PostData } from '@hooks/use-customer/types';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';
import { GetServerSidePropsContext } from 'next';
import { parseBody } from 'next/dist/server/api-utils/node';
import Image from 'next/image';
import React from 'react';
import SEO from 'src/legacy/components/common/seo';

export interface MainProps {
    /**Authorization token extracted from the request headers as required by some marketplaces (such as Joymall) */
    authToken?: string;
    /**
     * For preview from HarmonyCVM
     *
     * @link https://travelflan-dev.visualstudio.com/TravelFlan/_git/abb4d8df-cd8a-4369-8a3d-6c0b95e3e6ae/pullrequest/19193
     */
    tempSettingId?: string;
    postData: PostData;
}

const Main: React.FC<MainProps> = (props) => {
    const disclosure = useDisclosure({ defaultIsOpen: true });

    return (
        <>
            <SEO />
            <main>
                <MainContent {...props} />
                <AcrossMainPanel />
                {DomainUtils.isToyplus && EnvironmentUtils.isDev && <PassModuleTester />}
                {DomainUtils.isToyplus && EnvironmentUtils.isDev && disclosure.isOpen && (
                    <>
                        <Box pos="absolute" bottom="72px" right="24px" w="240px" h="240px">
                            <Button onClick={disclosure.onClose} pos="absolute" top="12px" right="12px" zIndex={10000}>
                                X
                            </Button>
                            <Image src="https://imagedelivery.net/f-OJ2bAl4-q_fsFAf4Z2iw/fc153678-bd0b-41ff-be0a-3a38e9e12b00/public" alt="test" layout="fill" />
                        </Box>
                    </>
                )}
            </main>
        </>
    );
};

export default Main;

export async function getServerSideProps(context: GetServerSidePropsContext) {
    const postData = context.req.method === 'POST' ? await parseBody(context.req, '10mb') : null;
    console.log('POST DATA', postData);
    console.log('QUERY STRING', context.req.url);

    return {
        props: {
            authToken: AuthClient.extractAuthorizationHeader(context.req.headers),
            tempSettingId: context.query?.temp_setting_id || null,
            postData,
        },
    };
}
