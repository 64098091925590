import { useDesignComponents } from '@hooks/use-design-components';

import FixedListProduct from '@legacy/designs/fixed-list-product/fixed-list-product';
import { BlockHStack } from 'src/legacy/components/blockui';
import ChannelComponent from '@models/channel-component';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import SubmenuButton from './submenu-button';
import { Design } from '@type/harmony-config';
import NoProduct from '@legacy/designs/no-product';
import { useHarmony } from '@hooks/use-harmony';
import GridContainer from '@legacy/designs/grid-container';
import styled from '@emotion/styled';

interface SubmenuFixProps {
    component: ChannelComponent;
    item?: ChannelComponentItem;
}

const SubmenuFix: React.FC<SubmenuFixProps> = ({ component, item }) => {
    const { designSettings } = useHarmony();

    const gap = useDesignComponents<string>({
        [Design.DesignA]: '14px 8px',
        [Design.DesignB]: '16px 16px',
        [Design.DesignC]: '24px 8px',
    });
    const padding = useDesignComponents<string>({
        [Design.DesignA]: '8px 16px 0',
        [Design.DesignB]: '16px 16px 0',
        [Design.DesignC]: '8px 16px',
    });
    const hasSubmenuButton = designSettings?.isDesignA || designSettings?.isDesignB;

    return item ? (
        <>
            <StyledGridContainer cols={component.productCols} gap={gap} padding={padding}>
                {item.mainDisplayProductList.length > 0 &&
                    item.mainDisplayProductList.map((product, index: number) => {
                        return <FixedListProduct product={product} cols={component.productCols} key={index} />;
                    })}
                {item.mainDisplayProductList.length < 1 &&
                    [...new Array(component.productCols)].map((_, i) => (
                        <BlockHStack key={i} align="center" spacing="16px">
                            <NoProduct size="160px" />
                        </BlockHStack>
                    ))}
            </StyledGridContainer>

            {hasSubmenuButton && <SubmenuButton component={component} item={item} />}
        </>
    ) : (
        <span></span>
    );
};

export default SubmenuFix;

const StyledGridContainer = styled(GridContainer)`
    .icon {
        font-size: 12px;
        margin-left: 8px;
    }
`;
