import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { InternalHomePointsProps } from '..';

const HomePointsC: React.FC<InternalHomePointsProps> = ({ availablePoints, pointSystem }) => {
    // TODO: integrate with api when api is ready
    const { theme } = useHarmony();
    return (
        <PointsContainer theme={theme}>
            <CustomImage src="/image/logo.png" width={28} height={28} />
            <BodyText weight="bold" color={theme.primary.default} size="18px" lineHeight="16px">
                {availablePoints}{' '}
                <BodyText size="16px" lineHeight="16px" color={theme.gray[600]}>
                    {pointSystem.toUpperCase()}
                </BodyText>
            </BodyText>
        </PointsContainer>
    );
};

export default HomePointsC;

const PointsContainer = styled.div<IThemeProps>`
    ${({ theme }) => `
        display: flex;
        justify-content: space-between;
        align-items:center;
        width: 100%;
        border-radius: 20px;
        background-color: ${theme.white};
        padding: 10px 20px;
  `}
`;
