import { VStack, forwardRef } from '@chakra-ui/react';
import RatioBox from '@components/ratio-box';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import { BrandCardProps } from '..';

const BrandCardD: React.FC<BrandCardProps> = forwardRef<BrandCardProps, 'div'>(({ componentItem, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="4px" align="stretch" ref={ref} {...props}>
            <RatioBox borderRadius="full" overflow="hidden" pos="relative">
                <CustomImage src={componentItem.brandImageUrl || ''} alt={componentItem.channelBrandName} layout="fill" objectFit="cover" />
            </RatioBox>
            <Body2B textAlign="center">{componentItem.channelBrandName}</Body2B>
        </VStack>
    );
});

export default BrandCardD;
