import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import ChannelComponentItem from '@models/channel-component/channel-component-item';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import MagazineBlockD from './designD/magazine-block';

export interface MagazineBlockProps extends BoxProps {
    imageRatio: number;
    componentItem: ChannelComponentItem;
}

const MagazineBlock: React.FC<MagazineBlockProps> = (props) => {
    const Component = useDesignComponents<ComponentType<MagazineBlockProps>>({
        [Design.DesignD]: MagazineBlockD,
    });

    return (
        <CustomLink href={props.componentItem.path}>
            <Component {...props} />
        </CustomLink>
    );
};

export default MagazineBlock;
