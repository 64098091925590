import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import BadgeD from './designD';
import { BadgeProps } from '@chakra-ui/react';

export enum BadgeType {
    Special,
    SoldOut,
    Normal,
    System1,
    System2,
}

export interface ProductBadgeProps extends BadgeProps {
    badgeType: BadgeType;
}

const Badge: React.FC<ProductBadgeProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ProductBadgeProps>>({
        [Design.DesignD]: BadgeD,
    });

    return <Component {...props} />;
};

export default Badge;
