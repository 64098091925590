import { useDesignComponents } from '@hooks/use-design-components';

import PaginationD from './designD/pagination';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';

export interface PaginationProps {
    totalPages: number;
    currentPage: number;
    /**
     * Returns the selected page number index with a range starting from 1.
     */
    onPageChange: (page: number) => void;
}

export interface InternalPaginationProps extends Omit<PaginationProps, 'onPageChange'> {
    rightArrowActive: boolean;
    leftArrowActive: boolean;
    onLeftClick: () => void;
    onRightClick: () => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
    const Component = useDesignComponents<ComponentType<InternalPaginationProps>>({
        [Design.DesignD]: PaginationD,
    });

    const rightArrowActive = props.currentPage < props.totalPages;
    const leftArrowActive = props.currentPage > 1;

    const onLeftClick = () => {
        props.onPageChange(Math.max(props.currentPage - 1, 1));
    };

    const onRightClick = () => {
        props.onPageChange(Math.min(props.currentPage + 1, props.totalPages));
    };

    return <Component rightArrowActive={rightArrowActive} leftArrowActive={leftArrowActive} onLeftClick={onLeftClick} onRightClick={onRightClick} {...props} />;
};

export default Pagination;
