import { VStack } from '@chakra-ui/react';
import FixedProductCardSection from '@designs/grouped/main/product-card-section/fixed-product-card-section';
import SwipeProductCardSection from '@designs/grouped/main/product-card-section/swipe-product-card-section';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useMemo } from 'react';
import { ProductListProps } from '..';

const ProductListD: React.FC<ProductListProps> = ({ channelComponent }) => {
    const products = useMemo(
        () => channelComponent.items.flatMap((item) => item.mainDisplayProductList).slice(0, channelComponent.productMaxCount),
        [channelComponent.items, channelComponent.productMaxCount]
    );

    const pagePath = channelComponent.items[0].pageUrl;

    return (
        <VStack as="section" w="100%" align="stretch" spacing="8px" p="8px 16px 16px">
            <MainSectionTitle title={channelComponent.title} subTitle={channelComponent.subtitle} />
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <FixedProductCardSection type={channelComponent.productCols === 1 ? 'L' : 'M'} products={products} viewMoreHref={pagePath} trackItemSelect />
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && <SwipeProductCardSection products={products} viewMoreHref={pagePath} trackItemSelect />}
        </VStack>
    );
};

export default ProductListD;
