import styled from '@emotion/styled';
import { useGATracking } from '@hooks/use-ga-tracking';
import { useHarmony } from '@hooks/use-harmony';
import { useWindowSize } from '@hooks/use-window-size';
import { ImageContainer } from '@legacy/designs/center-floating-popup';
import CustomButton from '@legacy/designs/custom-button/custom-button';
import LegacyPagination from '@legacy/designs/legacy-pagination';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useState } from 'react';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import { Swiper, SwiperSlide } from 'swiper/react';

const BottomStickyPopupB: React.FC<PopupProps> = ({ useButton, buttonName, imageRatio, items, handleIgnoreForToday, onClose }) => {
    const [width] = useWindowSize({ maxWidth: true });
    const [index, setIndex] = useState<number>(0);
    const { theme } = useHarmony();
    const t = useTranslations();
    const { logEvent } = useGATracking();

    return (
        <PopUpContents width={width}>
            <BlockHStack align="distribute" alignItems="center" width="100%" height="40px" spacing="none">
                <PopUpButton onClick={handleIgnoreForToday} width="123px">
                    <BodyText color={theme.white} weight="medium">
                        {t('home.ignoreForToday')}
                    </BodyText>
                </PopUpButton>
                <PopUpButton onClick={onClose} width="54px">
                    <BodyText color={theme.white} weight="medium">
                        {t('common.close')}
                    </BodyText>
                </PopUpButton>
            </BlockHStack>
            <CustomDrawer width={width}>
                <Swiper
                    loop={items.length > 1}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                    onSlideChange={(swiper) => {
                        width && setIndex(swiper.realIndex);
                    }}
                >
                    {items.map((popupItem, i) => (
                        <SwiperSlide key={i}>
                            {popupItem.image && (
                                <Link href={popupItem.linkPageUrl}>
                                    <ImageContainer width={`${width}px`} height={`${width * imageRatio}px`}>
                                        <a>
                                            <CustomImage
                                                src={popupItem.image}
                                                layout="fill"
                                                objectFit="cover"
                                                onClick={() => {
                                                    logEvent('select_promotion', {
                                                        promotion_name: popupItem.linkPageUrl,
                                                        items: [],
                                                    });
                                                }}
                                            />
                                        </a>
                                    </ImageContainer>
                                </Link>
                            )}
                        </SwiperSlide>
                    ))}
                    {items.length > 1 && <LegacyPagination index={index} total={items.length} />}
                </Swiper>
                {useButton && (
                    <ButtonContainer>
                        <Link href={items[index].linkPageUrl}>
                            <CustomButton
                                full
                                color={theme.primary.default}
                                borderRadius="12px"
                                onClick={() => {
                                    logEvent('select_promotion', {
                                        promotion_name: items[index].linkPageUrl,
                                        items: [],
                                    });
                                }}
                            >
                                {buttonName || t('home.goDetailsPage')}
                            </CustomButton>
                        </Link>
                    </ButtonContainer>
                )}
            </CustomDrawer>
        </PopUpContents>
    );
};

export default BottomStickyPopupB;

const PopUpContents = styled.div<{ width: number }>`
    position: fixed;
    bottom: 0;
    width: ${({ width }) => `${width}px`};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100dvh;
    animation: fadeInUp 0.3s;

    @keyframes fadeInUp {
        0% {
            opacity: 0.5;
            transform: translate3d(0, 100%, 0);
        }
        to {
            opacity: 1;
            transform: translateZ(0);
        }
    }
`;

const CustomDrawer = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    height: fit-content;
    z-index: ${ThemeUtils.zIndex.drawer};
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
    background-color: #fff;
`;

const ButtonContainer = styled.div`
    background-color: white;
    padding: 16px;
    width: 100%;
    height: 80px;
`;

const PopUpButton = styled.button<{ width: string }>`
    width: ${({ width }) => width};
    background-color: transparent;
    color: #fff;
    z-index: ${ThemeUtils.zIndex.drawer};
`;
