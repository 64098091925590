import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { useSellPricePolicy } from '@hooks/use-sell-price-policy';
import ProductBadges, { Direction } from '@legacy/components/product/product-badges';
import { ProductStyleProps } from '@legacy/designs/product-card-image';
import StyledImage from '@legacy/designs/product-card-image/designB/product-card-image';
import { ListPrice } from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { LineClipText } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { BlockHStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
interface GalleryProductBProps {
    product: ComponentProduct;
}

const GalleryProductB: React.FC<GalleryProductBProps> = ({ product }) => {
    const { theme, settings, designSettings } = useHarmony();
    const t = useTranslations();
    const { currencyService } = useGlobalization();
    const { getPriceWithUnitPolicy } = useSellPricePolicy();

    return (
        <ProductButton theme={theme}>
            <ImageContainer theme={theme}>
                {product.image && <StyledImage src={product.image} alt={product.title} layout={'fill'} objectFit="cover" theme={theme} disabled={product.isSoldOut} />}
                <WishlistContainer>
                    <WishListButton size="16px" product={product} padding="4px" />
                </WishlistContainer>
            </ImageContainer>
            <ContentContainer>
                <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} direction={Direction.Horizontal} />
                <LineClipText size="15px" lineHeight="18px" letterSpacing={'-0.01em'} weight="regular" textAlign="start" lines={2}>
                    {product.showBrandOnProductCard && (
                        <BodyText size="15px" lineHeight="18px" color={theme.primary.default} letterSpacing={'-0.01em'} weight={'bold'}>
                            [{product.brand}]
                        </BodyText>
                    )}
                    {product.title}
                </LineClipText>
                {designSettings?.isShowMarketPrice && product.hasDiscount && (
                    <ListPrice size="14px" color={theme.gray[400]}>
                        {product.displayedMarketPriceString}
                    </ListPrice>
                )}
                <BlockHStack align="distribute">
                    <BlockHStack spacing="2px" alignItems="center">
                        {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinSubPrice && (
                            <BodyText size="15px" lineHeight="14px">
                                {t('common.month')}/
                            </BodyText>
                        )}
                        <BodyText>
                            <BodyText weight="bold" size="18px" lineHeight="18px">
                                {getPriceWithUnitPolicy(product.displayedSellPrice)}
                            </BodyText>
                            <BodyText size="15px" lineHeight="14px" weight="bold">
                                {currencyService.currencySymbol} {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinPurPrice && `~`}
                            </BodyText>
                        </BodyText>
                    </BlockHStack>

                    {product.hasDiscount && designSettings?.isShowDiscountRate && (
                        <BodyText font="secondary" weight="bold" size="18px" lineHeight="18px" color={theme.primary.default}>
                            {product.discountRate}%
                        </BodyText>
                    )}
                </BlockHStack>
                <TextBadge hasDivider />
            </ContentContainer>
        </ProductButton>
    );
};

export default GalleryProductB;

const ProductButton = styled('div')<IThemeProps>`
    ${({ theme }) =>
        `display: flex;
    min-width: 100%;
    width: 100%;
    cursor: pointer;
    &::nth-last-of-type(1) {
      margin-bottom: 0px;
    }`}
`;

const ImageContainer = styled.div<ProductStyleProps & IThemeProps>`
    width: 100px;
    height: 100px;
    border: ${({ theme }) => `solid 1px ${theme.gray[200]}`};
    border-radius: 16px;
    overflow: hidden;
    margin-right: 8px;
    position: relative;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
`;

const ContentContainer = styled.div`
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 6px;
`;
