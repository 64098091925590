import CustomIcon from 'src/legacy/components/common/custom-icon';
import { useHarmony } from '@hooks/use-harmony';
import { StarComponentProps } from '..';

const StarB: React.FC<StarComponentProps> = ({ color, active, size = '18px', ...props }) => {
    const { theme } = useHarmony();

    return (
        <CustomIcon {...props} size={size} color={active ? color : theme.gray[200]} fill>
            grade
        </CustomIcon>
    );
};

export default StarB;
