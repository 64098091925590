import RatioBox from '@components/ratio-box';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useMemo } from 'react';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import { SwipeRankedProductProps } from '..';

const SwipeRankedProductC: React.FC<SwipeRankedProductProps> = ({ product, rank, focused, onClick }) => {
    const { theme } = useHarmony();

    const isDiscounted = useMemo<boolean>(() => {
        if (product.timesaleApplied) return true;
        return product.timesaleAppliedNow;
    }, [product]);
    const isSoldOut = product instanceof ComponentProduct ? product.isSoldOut : false;
    // TODO : (Allie) add isFreeShipping condition
    const hasProductBadges = product.timesaleAppliedNow || isSoldOut;

    return (
        <Container onClick={() => onClick?.(product.id)}>
            <RatioBox pos="relative" width="100%" borderRadius="4px" overflow="hidden">
                <RankingBadge theme={theme}>
                    <BodyText size="20px" color={theme.white} weight="bold">
                        {rank}
                    </BodyText>
                </RankingBadge>
                {product.image && <ProductCardImage radius={4} disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                <WishlistContainer>
                    <WishListButton size="24px" product={product} />
                </WishlistContainer>
            </RatioBox>
            <ProductDetailWrapper focused={focused}>
                <ProductCardDetails product={product} isDiscounted={isDiscounted} lines={2} />
                {/* TODO : (Allie) need to fix isFreeShipping later if it is needed */}
                <div>
                    {hasProductBadges && <ProductBadges direction={Direction.Horizontal} isSoldOut={isSoldOut} isSpecial={product.timesaleAppliedNow} isFreeShipping={false} />}
                    <TextBadge padding="0px" />
                </div>
            </ProductDetailWrapper>
        </Container>
    );
};

export default SwipeRankedProductC;

const Container = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
`;

const ImageContainer = styled.div<{ width: string }>`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ width }) => width};
    overflow: hidden;
    border-radius: 4px;
`;

const RankingBadge = styled.div<IThemeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    z-index: ${ThemeUtils.zIndex.badge};

    ${({ theme }) => `
    background-color: ${theme.primary.default}
  `}
`;

const ProductDetailWrapper = styled.div<{ focused?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: ${({ focused }) => (focused ? 1 : 0)};
    transition: ease-in-out 0.3s;
    min-height: 80px;
    width: 100%;
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 6px;
`;
