import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export interface RatioBoxProps extends Omit<BoxProps, 'h' | 'height'> {
    /** The ratio of box size. (`height / width`) */
    ratio?: number;
}

/** A box component that determines its size based on the width and fixed ratio. */
const RatioBox: React.FC<RatioBoxProps> = ({ ratio = 1, children, ...props }) => {
    return (
        <Box {...props}>
            <Box pb={`calc(${ratio} * 100%)`}>{children}</Box>
        </Box>
    );
};

export default RatioBox;
