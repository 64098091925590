import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { BaseProductCardProps } from '@type/theme';
import { ComponentType } from 'react';
import ListProductCardD from './designD/list-product-card';
import { withProductCardHOC } from '../with-product-card-hoc';

export interface ListProductCardProps extends BaseProductCardProps {}

const ListProductCard: React.FC<ListProductCardProps> = withProductCardHOC((props) => {
    const Component = useDesignComponents<ComponentType<ListProductCardProps>>({
        [Design.DesignD]: ListProductCardD,
    });

    return <Component {...props} />;
});

export default ListProductCard;
