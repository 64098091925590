import { As, Text, TextProps } from '@chakra-ui/react';
import { useMemo } from 'react';

export enum TextState {
    Active,
    Inactive,
    Disabled,
    Error,
    Warning,
    Success,
    Highlight,
}

type StatefulTextState = {
    /**
     * The text component to render as.
     */
    as: As;

    /**
     * Condition that if true, will apply the state to the text.
     */
    condition: boolean | undefined;

    /**
     * The color to apply to the text.
     */
    color?: string;

    /**
     * Add a name to this state to provide context for the design.
     */
    state?: TextState;
};

interface StatefulTextProps extends TextProps {
    /**
     * The base text component to render as.
     */
    as: As;

    /**
     * Additional possible style states for the text.
     */
    states: StatefulTextState[];
}

const StatefulText: React.FC<StatefulTextProps> = ({ as, states, children, color, ...props }) => {
    const currentState = useMemo<StatefulTextState | undefined>(() => {
        for (const state of states) {
            if (state.condition) {
                return state;
            }
        }
    }, [as, states]);

    return (
        <Text as={currentState?.as ?? as} color={currentState?.color ?? color} {...props}>
            {children}
        </Text>
    );
};

export default StatefulText;
