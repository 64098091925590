import { useDesignComponents } from '@hooks/use-design-components';

import { TimeRemaining } from '@hooks/use-countdown';
import { Design } from '@type/harmony-config';
import { BaseProductCardProps } from '@type/theme';
import { ComponentType } from 'react';
import { withProductCardHOC } from '../with-product-card-hoc';
import TimeSaleProductCardD from './designD/time-sale-product-card';

export interface TimeSaleProductCardProps extends BaseProductCardProps {
    timeRemaining: TimeRemaining;
    isStarted?: boolean;
    isEnded?: boolean;
}

const TimeSaleProductCard: React.FC<TimeSaleProductCardProps> = withProductCardHOC((props) => {
    const Component = useDesignComponents<ComponentType<TimeSaleProductCardProps>>({
        [Design.DesignD]: TimeSaleProductCardD,
    });

    return <Component {...props} />;
});

export default TimeSaleProductCard;
