import CustomIcon from 'src/legacy/components/common/custom-icon';
import { StarComponentProps } from '..';

const StarA: React.FC<StarComponentProps> = ({ color, active, size = '16px', ...props }) => {
    return (
        <CustomIcon {...props} size={size} color={color} fill={active}>
            grade
        </CustomIcon>
    );
};

export default StarA;
