import { authClient } from '@api/auth/auth.client';
import AuthCacheKeys from '@api/auth/cache-keys';
import { NiceModulePrepData } from '@models/customer-identity-verification/nice-module-prep-data';
import { NiceModulePrepDataDto } from '@models/customer-identity-verification/responseDto/nice-module-prep-data.dto';
import RoutesEnum from '@routes';
import { FormlessUtils } from '@utils/formless.utils';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTransformedFetch } from './use-transformed-fetch';

export type NiceVerificationResponse = {
    messageFrom: string;
    encodeData: string;
};

export type NiceVerificationRequest = {
    m: 'service';
    enc_data?: string;
    token_version_id?: string;
    integrity_value?: string;
};

export type UseNiceModule = ReturnType<typeof useNicePassModule>;

export enum DataPrepVerificationType {
    AdultPurchase = 'adult-purchase',
    AuroraInitialResetPassword = 'aurora-initial-reset-pw',
    Registration = 'registration',
    FindId = 'find-id',
    ResetPassword = 'reset-pw',
}

type UseNicePassModuleConfig = {
    productIds?: string[];
    customerId?: string;
    callback: (encodedData: string, temporaryCustomerId?: string) => void;
    key: string;
    verificationType: DataPrepVerificationType;
    isEnabled?: boolean;
};

type UseNicePassModuleReturn = {
    openModuleNiceVerification: () => void;
    niceModulePrepData: NiceModulePrepData | undefined;
};

export const NICE_PASS_LAST_URL = 'nicePassLastUrl';

export const useNicePassModule = ({ productIds = [], customerId, callback, key, verificationType, isEnabled = true }: UseNicePassModuleConfig): UseNicePassModuleReturn => {
    const router = useRouter();
    const verificationComplete = useRef<boolean>(false);
    const moduleName = `nice-verification__${key}`;

    const { transformedData: niceModulePrepData } = useTransformedFetch<NiceModulePrepData, NiceModulePrepDataDto>(
        isEnabled &&
            router.isReady &&
            !router.query.verified &&
            AuthCacheKeys.prepareCustomerIdentityVerificationData({
                productIds,
                customerId,
            }),
        () =>
            authClient.prepareCustomerIdentityVerificationData(
                {
                    productIds,
                    redirectUri: `${window.location.origin}${RoutesEnum.IdentityVerificationProcess}`,
                    customerId,
                },
                verificationType
            ),
        {
            transformer: (niceModulePrepDataDto) => new NiceModulePrepData(niceModulePrepDataDto),
        }
    );

    const openModule = (customerIdentityVerificationPrepData?: NiceModulePrepData) => {
        const niceCheckPlusUrl = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        const m = 'service';
        const encData = customerIdentityVerificationPrepData?.encData;
        const tokenVersionId = customerIdentityVerificationPrepData?.tokenVersionId;
        const integrityValue = customerIdentityVerificationPrepData?.integrityValue;

        const niceRequest: NiceVerificationRequest = {
            m,
            enc_data: encData,
            token_version_id: tokenVersionId,
            integrity_value: integrityValue,
        };

        if (isMobile) {
            localStorage.setItem(NICE_PASS_LAST_URL, window.location.href);
            window.location.href = `${niceCheckPlusUrl}?${queryString.stringify(niceRequest)}`;
            window.name = moduleName;
            sessionStorage.setItem('customerId', `${niceModulePrepData?.customerId}`);
        } else {
            const popupWindow = niceModuleWindow({
                name: moduleName,
                width: 500,
                height: 550,
            });

            if (popupWindow) {
                FormlessUtils.submit({
                    id: 'nice-form',
                    name: 'niceForm',
                    method: 'get',
                    target: moduleName,
                    action: niceCheckPlusUrl,
                    data: niceRequest,
                });
            }
        }
    };

    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        if (isMobile) {
            const customerId = sessionStorage.getItem('customerId') || undefined;
            if (router.isReady) {
                sessionStorage.removeItem('customerId');

                const { encodeData, messageFrom, ...remainingQuery } = router.query as NiceVerificationResponse;
                if (encodeData && !verificationComplete.current) {
                    const callbackAndRedirect = async () => {
                        await callback(encodeData, customerId);
                        verificationComplete.current = true;
                        router.replace({
                            pathname: router.pathname,
                            query: remainingQuery,
                        });
                    };

                    callbackAndRedirect();
                }
            }
        } else {
            const handleReceiveMessage = (e: MessageEvent<NiceVerificationResponse>) => {
                if (e.origin !== window.origin) {
                    return;
                }

                const { messageFrom, encodeData } = e.data;
                if (messageFrom !== moduleName) {
                    return;
                }

                try {
                    callback(encodeData, niceModulePrepData?.customerId);
                } catch (e) {
                    console.log(e);
                }
            };

            window.addEventListener('message', handleReceiveMessage);
            return () => window.removeEventListener('message', handleReceiveMessage);
        }
    }, [niceModulePrepData, isEnabled]);

    return {
        openModuleNiceVerification: () => openModule(niceModulePrepData),
        niceModulePrepData,
    };
};

type OpenWindow = {
    url?: string;
    name?: string;
    width?: number;
    height?: number;
    top?: number;
    left?: number;
};

const niceModuleWindow = ({ url = '', name = '', width = 375, height = 720, top = 100, left = 300 }: OpenWindow) => {
    return window.open(
        url,
        name,
        `width=${width}, height=${height}, top=${top}, left=${left}, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no`
    );
};
