import { Box, Center } from '@chakra-ui/react';
import DesignedContainer from '@designs/primitives/common/designed-container';
import Body1C from '@designs/primitives/typography/body-1-c';
import { useHarmony } from '@hooks/use-harmony';
import { RankingProductCardProps } from '..';
import FixedProductCard from '../../fixed-product-card';

const RankingProductCardD: React.FC<RankingProductCardProps> = ({ ranking, ...productCardProps }) => {
    const { theme } = useHarmony();

    return (
        <DesignedContainer p="8px 8px 16px">
            <Box pos="relative">
                <FixedProductCard {...productCardProps} />
                {!!ranking && (
                    <Center pos="absolute" top="0" left="0" bgColor={theme.primary.default} borderTopLeftRadius="4px" borderBottomRightRadius="4px" w="36px" h="36px">
                        <Body1C color={theme.text.title1}>{ranking}</Body1C>
                    </Center>
                )}
            </Box>
        </DesignedContainer>
    );
};

export default RankingProductCardD;
