import ChannelCacheKeys from '@api/channel/cache-keys';
import { ProductClient } from '@api/product/product.client';
import OfflineStore from '@designs/grouped/main/component-variation/offline-store';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { useLazyLoad } from '@hooks/use-lazy-load';
import Banner from '@legacy/designs/banner';
import IconMenu from '@legacy/designs/icon-menu/icon-menu';
import ImageMenu from '@legacy/designs/image-menu';
import TimeSale from '@legacy/designs/time-sale';
import ChannelComponent from '@models/channel-component';
import { ChannelComponentTypeEnum, IChannelComponentDto, IChannelComponentItemDto } from '@models/channel-component/responseDto/channel-component.dto';
import { useMemo } from 'react';
import { BlockHStack, BlockLoader } from 'src/legacy/components/blockui';
import HomeSubmenu from 'src/legacy/components/home/home-submenu';
import ImageList from 'src/legacy/components/home/image-list';
import ProductList from 'src/legacy/components/home/product-list';
import useSWR from 'swr';
import BrandCollection from './brand-collection';
import HomeDivider from './home-divider';
import Magazine from './magazine';
import HomePointsComponent from './points';
import RankList from './rank-list';
import ReviewComponent from './review';

type MinHeightStyle = {
    minHeight: number;
};
interface HomeChannelComponentProps {
    index: number;
    componentDto: IChannelComponentDto;
}

const LegacyHomeChannelComponent: React.FC<HomeChannelComponentProps> = ({ index, componentDto }) => {
    const { shouldLoad, ref } = useLazyLoad();
    const { designSettings } = useHarmony();
    const { extensionStatus } = useExtension();

    const { data: componentItemDtos, isValidating } = useSWR<IChannelComponentItemDto[] | undefined>(
        shouldLoad ? ChannelCacheKeys.channelComponentProductsCacheKey(index) : undefined,
        () => {
            if (shouldLoad) {
                return Promise.all(
                    componentDto.items.map(async (item, i) => {
                        if (item.mainDisplayProductList && (component.componentType !== ChannelComponentTypeEnum.SubMenu || i === 0)) {
                            const paginatedProducts = await ProductClient.postComponentProducts({
                                productIds: item.mainDisplayProductList as string[],
                                displayOutOfStock: designSettings?.isStockBadgeOn,
                            });
                            return {
                                ...item,
                                mainDisplayProductList: paginatedProducts.items,
                            };
                        } else {
                            return item;
                        }
                    })
                );
            } else {
                return undefined;
            }
        }
    );

    const component = useMemo<ChannelComponent>(() => {
        return new ChannelComponent(componentDto, componentItemDtos);
    }, [componentDto, componentItemDtos]);

    const channelComponent = useMemo(() => {
        switch (component.componentType) {
            case ChannelComponentTypeEnum.Banner:
                return <Banner channelComponent={component} />;
            case ChannelComponentTypeEnum.IconMenu:
                return <IconMenu channelComponent={component} />;
            case ChannelComponentTypeEnum.ImageList:
                return <ImageList channelComponent={component} />;
            case ChannelComponentTypeEnum.ProductList:
                return <ProductList channelComponent={component} />;
            case ChannelComponentTypeEnum.SubMenu:
                return <HomeSubmenu channelComponent={component} />;
            case ChannelComponentTypeEnum.TimeSale:
                return <TimeSale channelComponent={component} />;
            case ChannelComponentTypeEnum.RankList:
                return <RankList channelComponent={component} />;
            case ChannelComponentTypeEnum.Theme:
                return <TimeSale channelComponent={component} />;
            case ChannelComponentTypeEnum.Magazine:
                return <Magazine channelComponent={component} />;
            case ChannelComponentTypeEnum.ImageMenu:
                return <ImageMenu channelComponent={component} />;
            case ChannelComponentTypeEnum.Divider:
                return <HomeDivider channelComponent={component} />;
            case ChannelComponentTypeEnum.Points:
                return <HomePointsComponent />;
            case ChannelComponentTypeEnum.Brand:
                return <BrandCollection channelComponent={component} />;
            case ChannelComponentTypeEnum.Review:
                return <ReviewComponent channelComponent={component} />;
            case ChannelComponentTypeEnum.OfflineStore:
                return <OfflineStore channelComponent={component} />;
            default:
                return <></>;
        }
    }, [component]);

    const renderMinHeight = () => {
        switch (component.componentType) {
            case ChannelComponentTypeEnum.Divider:
            case ChannelComponentTypeEnum.Points:
                return 0;
            case ChannelComponentTypeEnum.Banner:
            case ChannelComponentTypeEnum.IconMenu:
                return 100;
            default:
                return 250;
        }
    };

    if (component.componentType === ChannelComponentTypeEnum.OfflineStore && !extensionStatus?.offlineStore.isEnabled) {
        return null;
    }

    return (
        <ComponentContainer ref={ref} minHeight={renderMinHeight()}>
            {(componentItemDtos || component.isNoItemComponent) && channelComponent}
            {isValidating && !componentItemDtos && (
                <FullHeightHStack align="center" alignItems="center" minHeight={renderMinHeight()}>
                    <BlockLoader />
                </FullHeightHStack>
            )}
        </ComponentContainer>
    );
};

export default LegacyHomeChannelComponent;

const ComponentContainer = styled.div<MinHeightStyle>`
    min-height: ${({ minHeight }) => minHeight}px;
`;

const FullHeightHStack = styled(BlockHStack)<MinHeightStyle>`
    height: ${({ minHeight }) => minHeight}px;
`;
