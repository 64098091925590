import { useDesignComponents } from '@hooks/use-design-components';

import { StackProps } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import { Url } from '@type/common';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import IconMenuItemD from './designD/icon-menu-item';

export interface IconMenuItemProps extends StackProps {
    imageUrl?: string;
    pagePath?: Url;
    label: string;
}

const IconMenuItem: React.FC<IconMenuItemProps> = ({ pagePath, ...props }) => {
    const Component = useDesignComponents<ComponentType<IconMenuItemProps>>({
        [Design.DesignD]: IconMenuItemD,
    });

    return (
        <CustomLink href={pagePath}>
            <Component {...props} />
        </CustomLink>
    );
};

export default IconMenuItem;
