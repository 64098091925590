import { Divider } from 'src/legacy/components/blockui';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';

interface CustomDividerProps {
    borderWidth?: string;
}

const CustomDivider: React.FC<CustomDividerProps> = (props) => {
    const { theme } = useHarmony();
    return <StyledDivider theme={theme} {...props} />;
};

export default CustomDivider;

const StyledDivider = styled(Divider)<IThemeProps & CustomDividerProps>`
    ${({ theme, borderWidth = '1px' }) => `
        border-top: solid ${borderWidth} ${theme.gray[50]};

    `}
`;
