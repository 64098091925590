import { Box, Flex, HStack } from '@chakra-ui/react';
import CONFIG from '@config';
import Body1B from '@designs/primitives/typography/body-1-b';
import Body1D from '@designs/primitives/typography/body-1-d';
import { useHarmony } from '@hooks/use-harmony';
import { PointsInnerProps } from '..';

const PointsD: React.FC<PointsInnerProps> = ({ availablePointsString }) => {
    const { theme } = useHarmony();

    return (
        <Box p="8px 16px">
            <Flex justify="space-between" p="8px 12px" borderRadius="4px" overflow="hidden" bgColor={theme.background.white} shadow={theme.dropShadow}>
                <HStack spacing="4px">
                    <Box w="40px" h="40px" pos="relative" bgColor={theme.gray[200]} borderRadius="full">
                        {/* TODO: Add point emblem image */}
                    </Box>
                    {CONFIG.pointSystem && <Body1B color={theme.text.title1}>{CONFIG.pointSystem}</Body1B>}
                </HStack>
                <HStack spacing="0">
                    <Body1D color={theme.text.body1}>{availablePointsString}</Body1D>
                    <Body1B color={theme.text.body1}>P</Body1B>
                </HStack>
            </Flex>
        </Box>
    );
};

export default PointsD;
