import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import React from 'react';
import NoProductA from './designA/no-product';
import NoProductB from './designB/no-product';
import NoProductC from './designC/no-product';

export interface NoProductProps {
    size?: string;
    large?: boolean;
}

const NoProduct: React.FC<NoProductProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof NoProductA | typeof NoProductB | typeof NoProductC>({
        [Design.DesignA]: NoProductA,
        [Design.DesignB]: NoProductB,
        [Design.DesignC]: NoProductC,
    });
    return <Component {...props} />;
};

export default NoProduct;
