import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useGATracking } from '@hooks/use-ga-tracking';
import BottomStickyPopup from '@legacy/designs/bottom-sticky-popup';
import CenterFloatingPopup from '@legacy/designs/center-floating-popup';
import Popup from '@models/popup';
import PopupItem from '@models/popup/popup-item';
import { PopupPosition } from '@models/popup/responseDto/popup.dto';
import { ScreenSizeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import moment from 'moment';
import { useEffect } from 'react';

export interface PopupProps {
    useButton: boolean;
    buttonName: string;
    imageRatio: number;
    items: PopupItem[];
    onClose: () => void;
    handleIgnoreForToday: () => void;
}

const PopUp: React.FC<{ popup: Popup }> = ({ popup }) => {
    const popUpDisclosure = useDisclosure();
    const { extensionStatus } = useExtension();
    const { logEvent } = useGATracking();

    const today = new Date();
    const expiryDate = typeof window !== 'undefined' && localStorage.getItem('popup_expiry');

    useEffect(() => {
        if (expiryDate && moment(expiryDate).isAfter(today)) return;
        if (!expiryDate || moment(expiryDate).isBefore(today)) popUpDisclosure.onOpen();
        logEvent('view_promotion', {
            promotion_name: popup.firstItem?.linkPageUrl,
            items: [],
        });
    }, []);

    useEffect(() => {
        document.body.style.overflowY = popUpDisclosure.isOpen ? 'hidden' : '';

        return () => {
            document.body.style.overflowY = '';
        };
    }, [popUpDisclosure.isOpen]);

    const handleIgnoreForToday = () => {
        const newExpiryDate = moment(today).endOf('d');
        localStorage.setItem('popup_expiry', String(newExpiryDate));
        popUpDisclosure.onClose();
    };

    return (
        <>
            {popUpDisclosure.isOpen && (
                <PopUpContainer maxScreenWidth={extensionStatus?.maxScreenWidth}>
                    <PopUpOverlay />
                    {popup.position === PopupPosition.CenterFloating ? (
                        <CenterFloatingPopup
                            useButton={popup.useButton}
                            buttonName={popup.buttonName}
                            imageRatio={popup.imageHeightFactor}
                            items={popup.items}
                            onClose={() => popUpDisclosure.onClose()}
                            handleIgnoreForToday={handleIgnoreForToday}
                        />
                    ) : (
                        <BottomStickyPopup
                            useButton={popup.useButton}
                            buttonName={popup.buttonName}
                            imageRatio={popup.imageHeightFactor}
                            items={popup.items}
                            onClose={() => popUpDisclosure.onClose()}
                            handleIgnoreForToday={handleIgnoreForToday}
                        />
                    )}
                </PopUpContainer>
            )}
        </>
    );
};

export default PopUp;

const PopUpContainer = styled.div<ScreenSizeProps>`
    position: fixed;
    width: 100vw;
    max-width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);
    height: 100vh;
    z-index: ${ThemeUtils.zIndex.floating};
`;

const PopUpOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: brightness(40%);
`;
