import { useDesignComponents } from '@hooks/use-design-components';

import { StarColor } from '@legacy/designs/star';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import StarRatingA from './designA/star-rating';
import StarRatingB from './designB/star-rating';
import StarRatingC from './designC/star-rating';

export interface StarRatingProps {
    averageRating: number;
    /**
     * Displays total review count in parenthesis next to the stars.
     */
    total?: number;
    /**@default black */
    color?: StarColor;
    /**
     * Only used in DesignC.
     * In product detail, star rating is displayed as number
     * instead of star icons list.
     */
    showRatingAsNumber?: boolean;
    starSize?: string;
    onTotalClick?: () => void;
}

const StarRating: React.FC<StarRatingProps> = (props) => {
    const Component = useDesignComponents<ComponentType<StarRatingProps>>({
        [Design.DesignA]: StarRatingA,
        [Design.DesignB]: StarRatingB,
        [Design.DesignC]: StarRatingC,
    });

    return <Component {...props} />;
};

export default StarRating;
