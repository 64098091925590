import { forwardRef } from '@chakra-ui/react';
import DesignedContainer from '@designs/primitives/common/designed-container';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { useHarmony } from '@hooks/use-harmony';
import { MagazineBlockProps } from '..';

const MagazineBlockD: React.FC<MagazineBlockProps> = forwardRef<MagazineBlockProps, 'div'>(({ componentItem, imageRatio, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <DesignedContainer ref={ref} {...props}>
            <ComponentImage src={componentItem.image} ratio={imageRatio} />
            <MainSectionTitle p="12px 16px 20px" title={componentItem.title} subTitle={componentItem.subTitle} />
        </DesignedContainer>
    );
});

export default MagazineBlockD;
