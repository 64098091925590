import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import Star from '@legacy/designs/star';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import { StarRatingProps } from '..';

const MAX_RATING = 5;

const StarRatingC: React.FC<StarRatingProps> = ({ total, onTotalClick, color, showRatingAsNumber, starSize, ...props }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    const starList = (): ReactNode => {
        const result = [];
        for (let i = 0; i < MAX_RATING; i++) {
            result.push(<Star color={color} active={i <= props.averageRating - 1} key={i} size={starSize} />);
        }
        return result;
    };

    if (showRatingAsNumber) {
        return (
            <BlockHStack spacing="8px" alignItems="center">
                <BodyText size="17px">
                    <BlockHStack spacing="2px" alignItems="center">
                        <Star active />
                        {Math.floor(props.averageRating || 0)}
                        <span>{'/'}</span>
                        {MAX_RATING}
                    </BlockHStack>
                </BodyText>
                {total !== undefined && (
                    <TotalButton size="16px" color={theme.gray[600]} onClick={onTotalClick}>
                        <BlockHStack spacing="0" alignItems="center">
                            {t('product.totalReviews', { total })}
                            <CustomIcon size="16px" color={theme.gray[600]}>
                                chevron_right
                            </CustomIcon>
                        </BlockHStack>
                    </TotalButton>
                )}
            </BlockHStack>
        );
    }

    return (
        <StyledHStack theme={theme}>
            {starList()}
            {total !== undefined && (
                <TotalButton color={theme.gray[500]} size="14px" role="button" onClick={onTotalClick}>
                    ({total})
                </TotalButton>
            )}
        </StyledHStack>
    );
};

export default StarRatingC;

const StyledHStack = styled.div<IThemeProps>`
    ${({ theme }) => `
        font-family: ${theme.font.secondary.light};
        font-size: 16px;
        letter-spacing: -0.03em;
        line-height: 100%;
        color: ${theme.gray[600]};
        display: flex;
        align-items: center;
    `}
`;

const TotalButton = styled(BodyText)`
    ${({ onClick }) => `
        cursor: ${onClick ? 'pointer' : 'default'};
    `}
`;
