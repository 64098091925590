import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { PaginatedControlsProps } from '..';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';

const PaginatedControlsB: React.FC<PaginatedControlsProps> = ({ active, total, prev, next, margin = '24px 0 0' }) => {
    const { theme } = useHarmony();

    return (
        <BlockHStack align="center" margin={margin}>
            <StyledControls>
                <CustomIconButton size="20px" padding="0" color={active <= 0 ? theme.gray[200] : theme.gray[700]} onClick={prev}>
                    chevron_left
                </CustomIconButton>
                <BlockHStack width="55px" align="center">
                    <BodyText size="16px" lineHeight="18px" color={theme.gray[700]} weight="medium" font="secondary">
                        {active + 1} / {total}
                    </BodyText>
                </BlockHStack>
                <CustomIconButton size="20px" padding="0" color={active >= total - 1 ? theme.gray[200] : theme.gray[700]} onClick={next}>
                    chevron_right
                </CustomIconButton>
            </StyledControls>
        </BlockHStack>
    );
};

export default PaginatedControlsB;

const StyledControls = styled.div`
    background: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 48px;
    height: 36px;
`;
