import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import BadgeAD from './designD/badge-a';

export interface BadgeAProps extends PartialAbstractedTextProps {}

const BadgeA: React.FC<BadgeAProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BadgeAProps>>({
        [Design.DesignD]: BadgeAD,
    });

    return <Component {...props} />;
};

export default BadgeA;
