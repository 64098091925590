import RatioBox from '@components/ratio-box';
import styled from '@emotion/styled';
import { useCountdown } from '@hooks/use-countdown';
import { useHarmony } from '@hooks/use-harmony';
import CountdownText from '@legacy/designs/countdown-text';
import MoreViewButton from '@legacy/designs/more-view-button.tsx';
import NoProduct from '@legacy/designs/no-product';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductListContainer from '@legacy/designs/product-list-container';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import RoutesEnum from '@routes';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TimeSaleProps } from '..';
import { useGATracking } from '@hooks/use-ga-tracking';

const TimeSaleC: React.FC<TimeSaleProps> = ({ channelComponent }) => {
    const router = useRouter();
    const { theme } = useHarmony();
    const t = useTranslations();
    const { logEvent } = useGATracking();
    const { time, end } = useCountdown(channelComponent.endDate);

    const disabled = channelComponent.isBeforeStart || end;
    const hasNoProduct = !channelComponent.firstItem?.mainDisplayProductList || channelComponent.firstItem.mainDisplayProductList.length < 1;

    return (
        <ProductListContainer>
            <BlockHStack align="distribute" alignItems="center" spacing="8px" padding="10px 16px">
                <TitleWrapper>
                    <Title size={'20px'} weight={'bold'} lineHeight={'1'}>
                        {channelComponent.title}
                    </Title>
                </TitleWrapper>

                {channelComponent.isBeforeStart ? (
                    <BodyText weight="bold" size="17px" color={theme.primary.default} lineHeight={'1'}>
                        {t('home.saleStarts')}
                    </BodyText>
                ) : (
                    <>
                        {end ? (
                            <BodyText weight="bold" size="17px" color={theme.primary.default} lineHeight={'1'}>
                                {t('home.saleHasEnded')}
                            </BodyText>
                        ) : (
                            <BlockHStack spacing="4px" alignItems="center">
                                <CustomIcon fill color={theme.primary[600]} size="20px">
                                    alarm_on
                                </CustomIcon>
                                <CountdownText timeRemaining={time} />
                            </BlockHStack>
                        )}
                    </>
                )}
            </BlockHStack>
            {channelComponent.firstItem?.mainDisplayProductList && (
                <Swiper spaceBetween={8} centeredSlides loop={channelComponent.firstItem.mainDisplayProductList.length > 2} slidesPerView={1.3} style={{ margin: '16px 0' }}>
                    {channelComponent.firstItem.mainDisplayProductList.map((product: ComponentProduct) => (
                        <SwiperSlide key={product.id}>
                            <Product
                                theme={theme}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    logEvent('select_promotion', {
                                        promotion_name: channelComponent.title,
                                    });
                                    !disabled && !product.isSoldOut && router.push({ pathname: RoutesEnum.ProductDetail, query: { productId: product.id } });
                                }}
                            >
                                <ImageContainer theme={theme} disabled={disabled || product.isSoldOut}>
                                    <RatioBox width="100%" pos="relative" borderRadius="4px" overflow="hidden">
                                        {product.image && <CustomImage src={product.image} alt={product.title} layout="fill" objectFit="cover" />}
                                        <WishlistContainer>
                                            <WishListButton size="24px" product={product} />
                                        </WishlistContainer>
                                    </RatioBox>
                                </ImageContainer>
                                <ProductCardDetails product={product} lines={2} isEllipsisBrandName={false} />
                                <ProductBadges direction={Direction.Horizontal} isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
                            </Product>
                        </SwiperSlide>
                    ))}
                    {hasNoProduct && (
                        <BlockHStack width="100%" align="center" alignItems="center">
                            <NoProduct size="140px" />
                        </BlockHStack>
                    )}
                </Swiper>
            )}

            {channelComponent.firstItem?.pageUrl && (
                <Link href={channelComponent.firstItem.pageUrl}>
                    <a>
                        <MoreViewButton margin={hasNoProduct ? '' : '16px 0 0 0'} />
                    </a>
                </Link>
            )}
        </ProductListContainer>
    );
};

export default TimeSaleC;

const TitleWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-shrink: 0;
`;

const Title = styled(BodyText)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Product = styled.div<IThemeProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    overflow: hidden;
    line-height: 1;
`;

const ImageContainer = styled.div<{ disabled: boolean }>`
    ${({ disabled }) =>
        disabled &&
        `
        opacity: 0.4;
            &::after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: ${ThemeUtils.zIndex.above};
            }
    `}
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 6px;
`;
