import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { PopupProps } from 'src/legacy/components/home/pop-up';
import styled from '@emotion/styled';
import CenterFloatingPopupA from './designA/center-floating-popup';
import CenterFloatingPopupB from './designB/center-floating-popup';
import CenterFloatingPopupC from './designC/center-floating-popup';

const CenterFloatingPopup: React.FC<PopupProps> = (props) => {
    const Component = useDesignComponents<typeof CenterFloatingPopupA | typeof CenterFloatingPopupB | typeof CenterFloatingPopupC>({
        [Design.DesignA]: CenterFloatingPopupA,
        [Design.DesignB]: CenterFloatingPopupB,
        [Design.DesignC]: CenterFloatingPopupC,
    });

    return <Component {...props} />;
};

export default CenterFloatingPopup;

export const ImageContainer = styled.div<{ width: string; height: string }>`
    position: relative;
    ${({ width, height }) => `
        width: ${width};
        height: ${height};
    `}
`;
