type SubmitPassModuleForm = {
    id?: string;
    name?: string;
    method?: 'get' | 'post' | (string & {});
    action?: string;
    target?: string;
    enctype?: string;
    acceptCharset?: string;
    data: Record<string, string | number | boolean | undefined>;
};

/**
 * A utility for submitting without a form element.
 */
export class FormlessUtils {
    static submit({ data, ...formProps }: SubmitPassModuleForm) {
        if (typeof window === 'undefined') {
            return false;
        }

        const form = this._createForm(formProps);
        Object.entries(data).forEach(([dataKey, value]) => {
            if (value === undefined) {
                return;
            }

            form.appendChild(this._createInput(dataKey, `${value}`));
        });

        document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
            const formInstanceList = document.body.querySelectorAll(`#${formProps.id}`);
            for (let i = 0; i < formInstanceList.length; i++) {
                formInstanceList[i]?.remove();
            }
        }, 5000);
    }

    private static _createForm = (formProps: Omit<SubmitPassModuleForm, 'data'>) => {
        const form = document.createElement('form');
        Object.entries(formProps).forEach(([propsKey, value]) => {
            if (!value) {
                return;
            }

            form[propsKey] = value;
        });

        return form;
    };

    private static _createInput(name: string, value: string) {
        const input = document.createElement('input');
        input.name = name;
        input.id = name;
        input.value = value;
        input.setAttribute('aria-value', value);
        return input;
    }
}
