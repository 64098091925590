import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import React, { ReactNode } from 'react';
import HomeComponentHeadingA from './designA/home-component-heading';
import HomeComponentHeadingB from './designB/home-component-heading';
import HomeComponentHeadingC from './designC/home-component-heading';
export interface HomeComponentHeadingProps {
    title?: string;
    subtitle?: string;
    children?: ReactNode;
}

const HomeComponentHeading: React.FC<HomeComponentHeadingProps> = (props) => {
    const Component = useDesignComponents<typeof HomeComponentHeadingA | typeof HomeComponentHeadingB | typeof HomeComponentHeadingC>({
        [Design.DesignA]: HomeComponentHeadingA,
        [Design.DesignB]: HomeComponentHeadingB,
        [Design.DesignC]: HomeComponentHeadingC,
    });

    return <Component {...props} />;
};

export default HomeComponentHeading;
