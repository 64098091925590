import { useDesignComponents } from '@hooks/use-design-components';

import ArrowButton from '@legacy/designs/arrow-button';
import ChannelComponent from '@models/channel-component';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import { useHarmony } from '@hooks/use-harmony';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useState } from 'react';
import { useWindowSize } from '@hooks/use-window-size';
import styled from '@emotion/styled';
import { Design } from '@type/harmony-config';
import ConversionUtils from '@utils/conversion.utils';
import NoProduct from '@legacy/designs/no-product';
import FixedRankList from '@legacy/designs/fixed-rank-list';
import ProductListContainer from '@legacy/designs/product-list-container';
import AccordionRankList from '@legacy/designs/accordion-rank-list';
import SwipeRankList from '@legacy/designs/swipe-rank-list';

interface RankListProps {
    channelComponent: ChannelComponent;
}

const CURRENT_PRODUCT_SCALE = 0.65;
const ITEMS_PER_PAGE = 5;

const RankList: React.FC<RankListProps> = ({ channelComponent }) => {
    const { designSettings } = useHarmony();
    const t = useTranslations();
    const [index, setIndex] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [width] = useWindowSize({ maxWidth: true });
    const padding = useDesignComponents<string>({
        [Design.DesignA]: '16px 0 32px',
        [Design.DesignB]: '16px 0',
        [Design.DesignC]: '10px 0 16px',
    });

    const rankedProductList = channelComponent.firstItem?.mainDisplayProductList;
    const lengthOfProductList = rankedProductList?.length || 0;
    const paginatedProductList = rankedProductList && ConversionUtils.paginateItemList(rankedProductList, ITEMS_PER_PAGE, page);
    const totalPage = Math.ceil(lengthOfProductList / ITEMS_PER_PAGE);
    const hasArrowButton = designSettings?.isDesignA || designSettings?.isDesignB;

    return (
        <ProductListContainer padding={padding}>
            <HomeComponentHeading title={channelComponent.title}>
                {hasArrowButton && channelComponent.firstItem?.pageUrl && (
                    <Link href={channelComponent.firstItem.pageUrl}>
                        <ArrowButton>{t('home.view')}</ArrowButton>
                    </Link>
                )}
            </HomeComponentHeading>
            {!lengthOfProductList && (
                <BlockHStack align="center">
                    <NoProduct size={`${width * CURRENT_PRODUCT_SCALE - 2}px`} large />
                </BlockHStack>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && lengthOfProductList > 0 && (
                <SwipeRankList
                    rankedProductList={rankedProductList}
                    page={page}
                    totalPage={totalPage}
                    itemsPerPage={ITEMS_PER_PAGE}
                    next={() => page < totalPage && setPage((prev) => prev + 1)}
                    prev={() => page > 1 && setPage((prev) => prev - 1)}
                    rankTotal={lengthOfProductList}
                    index={index}
                    setIndex={setIndex}
                    currentProductScale={CURRENT_PRODUCT_SCALE}
                />
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Accordion && lengthOfProductList > 0 && (
                <AccordionRankList
                    paginatedProductList={paginatedProductList}
                    page={page}
                    totalPage={totalPage}
                    itemsPerPage={ITEMS_PER_PAGE}
                    next={() => page < totalPage && setPage((prev) => prev + 1)}
                    prev={() => page > 1 && setPage((prev) => prev - 1)}
                    rankTotal={lengthOfProductList}
                />
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && lengthOfProductList > 0 && (
                <FixedRankList
                    productList={paginatedProductList}
                    page={page}
                    totalPage={totalPage}
                    itemsPerPage={ITEMS_PER_PAGE}
                    next={() => page < totalPage && setPage((prev) => prev + 1)}
                    prev={() => page > 1 && setPage((prev) => prev - 1)}
                    rankTotal={lengthOfProductList}
                />
            )}
        </ProductListContainer>
    );
};

export default RankList;

const RankListContainer = styled.div`
    margin: 0 16px;
    padding-bottom: 4px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

const RankItemContainer = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const SwipeContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
`;
