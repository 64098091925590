import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import RankingItemD from './designD/ranking-item';

export interface RankingItemProps {
    product: ComponentProduct;
    ranking: number;
    isAccordion?: boolean;
}

const RankingItem: React.FC<RankingItemProps> = (props) => {
    const Component = useDesignComponents<ComponentType<RankingItemProps>>({
        [Design.DesignD]: RankingItemD,
    });

    return <Component {...props} />;
};

export default RankingItem;
