import { Box } from '@chakra-ui/react';
import { AgeVerificationIcon } from '@designs/grouped/common/age-verification-icon';

import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardImage from '@legacy/designs/product-card-image';
import ProductPrice from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { LineClipText } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import RoutesEnum from 'src/routes/routes';

interface SwipeListProductProps {
    product: ComponentProduct;
}

const SwipeListProductDesignB: React.FC<SwipeListProductProps> = ({ product }) => {
    const { theme } = useHarmony();

    return (
        <Link
            href={{
                pathname: RoutesEnum.ProductDetail,
                query: { productId: product.id },
            }}
        >
            <AccordionRankedProduct theme={theme}>
                <ImageContainer theme={theme}>
                    {product.image && <ProductCardImage disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                    {product.isAgeVerificationRequired && (
                        <Box pos="absolute" top="8px" right="7.5px">
                            <AgeVerificationIcon />
                        </Box>
                    )}
                    <WishlistContainer>
                        <WishListButton className="heart" size="16px" product={product} padding="4px" />
                    </WishlistContainer>
                </ImageContainer>
                <ContentContainer isBadgeInList={product.isSoldOut || product.timesaleAppliedNow}>
                    <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} direction={Direction.Horizontal} />
                    <LineClipText size="15px" lineHeight="18px" textAlign="left" lines={2} weight="medium">
                        {product.showBrandOnProductCard && (
                            <BodyText size="15px" lineHeight="18px" color={theme.primary.default} weight="bold">
                                [{product.brand}]{' '}
                            </BodyText>
                        )}
                        <BodyText weight="medium">{product.title}</BodyText>
                    </LineClipText>
                    <Box pt="4px">
                        <ProductPrice compact product={product} />
                    </Box>
                    <TextBadge hasDivider padding="6px 0 0 0" />
                </ContentContainer>
            </AccordionRankedProduct>
        </Link>
    );
};

export default SwipeListProductDesignB;

const AccordionRankedProduct = styled('div')<IThemeProps>`
    display: flex;
    justify-content: between;
    margin: 0 1rem;
    cursor: pointer;
    position: relative;
    gap: 8px;
`;
const ImageContainer = styled.div<IThemeProps>`
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid ${({ theme }) => theme.gray[200]};
    border-radius: 15px;
    overflow: hidden;
`;
const WishlistContainer = styled.div`
    position: absolute;
    right: 6px;
    bottom: 6px;
`;

const ContentContainer = styled.div<{ isBadgeInList: boolean }>`
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
`;
