import styled from '@emotion/styled';
import { useWindowSize } from '@hooks/use-window-size';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { useMemo } from 'react';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';

const WIDTH_FACTOR = 0.28;

interface GalleryProductCProps {
    product: ComponentProduct;
    isTimeSale?: boolean;
}

const GalleryProductC: React.FC<GalleryProductCProps> = ({ product, isTimeSale }) => {
    const [width] = useWindowSize({ maxWidth: true });

    const isDiscounted = useMemo<boolean>(() => {
        if (isTimeSale && product.timesaleApplied) return true;
        return product.timesaleAppliedNow;
    }, [isTimeSale, product]);
    const isSoldOut = product.isSoldOut;
    // TODO : (Allie) add isFreeShipping condition
    const hasProductBadges = product.timesaleAppliedNow || isSoldOut;

    return (
        <ProductButton>
            <ImageContainer width={`${width * WIDTH_FACTOR}px`} height={`${width * WIDTH_FACTOR}px`}>
                {product.image && <ProductCardImage src={product.image} alt={product.title} layout={'fill'} objectFit="cover" disabled={product.isSoldOut} radius={4} />}
            </ImageContainer>
            <ContentContainer>
                <ProductCardDetails product={product} isDiscounted={isDiscounted} isEllipsisBrandName={false} />
                {hasProductBadges && <ProductBadges direction={Direction.Horizontal} isSoldOut={isSoldOut} isSpecial={product.timesaleAppliedNow} isFreeShipping={false} />}
                <TextBadge hasDivider padding="0" />
            </ContentContainer>
            <WishlistContainer>
                <WishListButton size="24px" product={product} />
            </WishlistContainer>
        </ProductButton>
    );
};

export default GalleryProductC;

const ProductButton = styled.div`
    position: relative;
    display: flex;
    gap: 4px;
    min-width: 100%;
    width: 100%;
    cursor: pointer;
`;

const ImageContainer = styled.div<{ width: string; height: string }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 4px;
    overflow: hidden;
    position: relative;
`;

const ContentContainer = styled.div`
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 0px;
`;
