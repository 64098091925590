import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const BannerContainerA = styled.div<IThemeProps>`
    ${({ theme }) => `
        position: relative;
        background-color: ${theme.background.config};
        
        .disabled {
            pointer-events: none;
        }
    `}
`;

export default BannerContainerA;
