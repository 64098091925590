import { Box, Center, HStack } from '@chakra-ui/react';
import OfflineStoreCard from '@designs/primitives/main/offline-store/offline-store-card';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import ArrowButton from '@legacy/designs/arrow-button';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import SwipeProductListContainer from '@legacy/designs/swipe-product-list-container';
import RoutesEnum from '@routes';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { OfflineStoreInnerProps } from '..';

const OfflineStoreB: React.FC<OfflineStoreInnerProps> = ({ offlineStores }) => {
    const t = useTranslations();
    const router = useRouter();
    const { theme } = useHarmony();
    return (
        <Box py="16px">
            <HomeComponentHeading title={t('store.storeInfo')}>
                <ArrowButton onClick={() => router.push(RoutesEnum.OfflineStore)} />
            </HomeComponentHeading>

            {!offlineStores?.length && (
                <Center w="100%">
                    <BodyText size="15px" lineHeight="18px" color={theme.gray[600]} textAlign="center" whiteSpace="pre-wrap">
                        {t('store.noStores')}
                    </BodyText>
                </Center>
            )}

            {offlineStores && (
                <SwipeProductListContainer>
                    <HStack spacing="16px" align="flex-start">
                        {offlineStores.map((offlineStore) => (
                            <OfflineStoreCard key={offlineStore.id} offlineStore={offlineStore} />
                        ))}
                    </HStack>
                </SwipeProductListContainer>
            )}
        </Box>
    );
};

export default OfflineStoreB;
