import { Box, HStack, VStack } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import Pagination from '@designs/grouped/common/pagination/pagination';
import ListProductCard from '@designs/grouped/common/product-card/list-product-card';
import SwipeProductCard from '@designs/grouped/common/product-card/swipe-product-card';
import DesignedContainer from '@designs/primitives/common/designed-container';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { useHarmony } from '@hooks/use-harmony';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { useState } from 'react';
import { ImageListProps } from '..';

const ImageListD: React.FC<ImageListProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    const [componentItemIndex, setComponentItemIndex] = useState<number>(0);
    const currentComponentItem = channelComponent.items[componentItemIndex];
    const products = currentComponentItem.mainDisplayProductList.slice(0, channelComponent.productMaxCount);

    return (
        <VStack as="section" w="100%" align="stretch" spacing="8px" p="8px 16px 16px">
            <MainSectionTitle title={channelComponent.title} subTitle={channelComponent.subtitle} />

            {channelComponent.displayType === ComponentDisplayTypeEnum.Button && (
                <DesignedContainer>
                    <VStack spacing="12px" w="100%" alignItems="stretch">
                        <CustomLink href={currentComponentItem.path}>
                            <Box>
                                <ComponentImage src={currentComponentItem.image} ratio={channelComponent.imageRatio} />
                            </Box>
                        </CustomLink>
                        <VStack spacing="16px" pb="16px" sx={{ _empty: { display: 'none' } }}>
                            {products.length > 0 && (
                                <VStack spacing="12px" px="8px" align="stretch" w="100%">
                                    {products.map((product, index) => (
                                        <ListProductCard key={index} product={product} />
                                    ))}
                                </VStack>
                            )}
                            {channelComponent.items.length > 1 && (
                                <Pagination
                                    totalPages={channelComponent.items.length}
                                    currentPage={componentItemIndex + 1}
                                    onPageChange={(page) => setComponentItemIndex(page - 1)}
                                />
                            )}
                        </VStack>
                    </VStack>
                </DesignedContainer>
            )}

            {channelComponent.displayType === ComponentDisplayTypeEnum.Scroll && (
                <VStack spacing="24px" align="stretch">
                    {channelComponent.items.map((item, index) => (
                        <DesignedContainer key={index}>
                            <VStack spacing="12px" w="100%">
                                <CustomLink href={item.path}>
                                    <Box w="100%" h="100%">
                                        <ComponentImage src={item.image} ratio={channelComponent.imageRatio} />
                                    </Box>
                                </CustomLink>{' '}
                                {item.mainDisplayProductList.length > 0 && (
                                    <HStack w="100%" p="0px 16px 16px" spacing="8px" overflowX="auto" align="flex-start">
                                        {item.mainDisplayProductList.map((product) => (
                                            <SwipeProductCard key={product.id} product={product} />
                                        ))}
                                    </HStack>
                                )}
                            </VStack>
                        </DesignedContainer>
                    ))}
                </VStack>
            )}
        </VStack>
    );
};

export default ImageListD;
