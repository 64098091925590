import { BlockAccordion, BlockAccordionContent, BlockAccordionItem, BlockAccordionTrigger, BlockAccordionTypeEnum } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import AccordionRankedProduct from '@legacy/designs/accordion-ranked-product';
import PaginatedControls from '@legacy/designs/paginated-controls';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { AccordionRankListProps } from '..';

const AccordionRankListC: React.FC<AccordionRankListProps> = ({ paginatedProductList, itemsPerPage, page, totalPage, prev, next, rankTotal }) => {
    const { theme } = useHarmony();

    return (
        <>
            <Container padding="4px 16px">
                <BlockAccordion type={BlockAccordionTypeEnum.single} defaultValue="0">
                    {paginatedProductList?.map((product, index) => (
                        <BlockAccordionItem value={index.toString()} key={index}>
                            <BlockAccordionTrigger color={theme.gray[600]}>
                                <RankItemWrapper theme={theme}>
                                    <BodyText className="rank" size="18px" lineHeight="1" weight="bold" color={theme.primary.default} textAlign="center">
                                        {itemsPerPage * (page - 1) + (index + 1)}.
                                    </BodyText>
                                    <BodyText className="title" size="18px" lineHeight="1" lineClamp={1} wordBreak={'break-all'} ellipsis whiteSpace="nowrap">
                                        {product.title}
                                    </BodyText>
                                </RankItemWrapper>
                            </BlockAccordionTrigger>
                            <BlockAccordionContent>
                                <AccordionRankedProduct product={product} />
                            </BlockAccordionContent>
                        </BlockAccordionItem>
                    ))}
                </BlockAccordion>
            </Container>
            {totalPage > 1 && rankTotal > itemsPerPage - 1 && <PaginatedControls active={page - 1} total={totalPage} prev={prev} next={next} />}
        </>
    );
};

export default AccordionRankListC;

const Container = styled(PaddedContainer)`
    min-height: 318px;
    min-width: 0px;
`;

const RankItemWrapper = styled.div<IThemeProps>`
    min-width: 0px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 14px 0;
    .rank {
        width: 24px;
    }
    .title {
        width: 280px;
    }
`;
