import { useHarmony } from '@hooks/use-harmony';
import { OfflineStoreCardProps } from '..';
import { Box, VStack } from '@chakra-ui/react';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import RoutesEnum from '@routes';
import Link from 'next/link';
import SanitationUtils from '@utils/sanitation.utils';

const OfflineStoreCardB: React.FC<OfflineStoreCardProps> = ({ offlineStore }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Link href={`${RoutesEnum.OfflineStore}/${offlineStore.id}`}>
            <VStack align="flex-start" w="144px">
                <Box
                    w="144px"
                    h="144px"
                    pos="relative"
                    flexShrink={0}
                    boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                    border="1px solid"
                    borderColor={theme.gray[200]}
                    borderRadius="16px"
                    overflow="hidden"
                >
                    <CustomImage src={offlineStore.imageUrl} layout="fill" objectFit="cover" />
                </Box>
                <VStack w="100%" align="flex-start" spacing="4px" px="4px">
                    <BodyText size="16px" weight="bold" ellipsis lineClamp={2} wordBreak="break-all">
                        {offlineStore.name}
                    </BodyText>
                    <BodyText size="14px" color={theme.gray[700]} ellipsis lineClamp={2} wordBreak="break-all" lineHeight="14px">
                        {offlineStore.baseAddress} {offlineStore.detailAddress}
                    </BodyText>
                    <BodyText size="14px" weight="medium">
                        {SanitationUtils.sanitizePhoneNumber(offlineStore.phone)}
                    </BodyText>
                </VStack>
                <Box
                    display="flex"
                    p="4px 4px 3px"
                    h="18px"
                    borderRadius="6px"
                    bgColor={offlineStore.isOpenForBusiness ? '#9CDD9B' : theme.gray[100]}
                    boxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.12)"
                >
                    <BodyText size="13px" weight="bold" color={offlineStore.isOpenForBusiness ? '#266D25' : theme.gray[600]} lineHeight="11px">
                        {offlineStore.isOpenForBusiness ? t('store.open') : t('store.ready')}
                    </BodyText>
                </Box>
            </VStack>
        </Link>
    );
};

export default OfflineStoreCardB;
