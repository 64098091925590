import styled from '@emotion/styled';

const GalleryListC = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export default GalleryListC;
