import CustomImage from 'src/legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { AbsoluteContainer } from '@styles/styled-components';
import { NoProductProps } from '..';

const NoProductC: React.FC<NoProductProps> = ({ size, large }) => {
    const imageSize = size ? size : large ? '328px' : '160px';

    return (
        <Container size={imageSize}>
            <AbsoluteContainer top="50%" left="50%" transform="translate(-50%, -50%)">
                <ImageContainer size={imageSize}>
                    <CustomImage src="/image/no_product.png" alt="no product" layout="fill" objectFit="contain" />
                </ImageContainer>
            </AbsoluteContainer>
        </Container>
    );
};

export default NoProductC;

const Container = styled.div<NoProductProps>`
    position: relative;
    width: 100%;
    display: flex;
    ${({ size }) => `
    height: ${size};
`}
`;

const ImageContainer = styled.div<NoProductProps>`
    ${({ size }) => `
    width: ${size};
    height: ${size};
`}
`;
