import { useDesignComponents } from '@hooks/use-design-components';

import styled from '@emotion/styled';
import { Design } from '@type/harmony-config';
import { HTMLProps } from 'react';

interface CustomChipProps extends Omit<HTMLProps<HTMLDivElement>, 'as'> {
    backgroundColor?: string;
    borderColor?: string;
    borderRadius?: string;
    height?: string;
    padding?: string;
    shadow?: boolean;
}

const CustomChip: React.FC<CustomChipProps> = ({ children, borderRadius, ...props }) => {
    const defaultBorderRadius = useDesignComponents<string>({
        [Design.DesignA]: '9999px',
        [Design.DesignB]: '9999px',
        [Design.DesignC]: '4px',
    });

    return (
        <ChipContainer {...props} borderRadius={borderRadius || defaultBorderRadius}>
            {children}
        </ChipContainer>
    );
};

export default CustomChip;

const ChipContainer = styled.div<CustomChipProps>`
    ${({ backgroundColor, borderColor, borderRadius, height, padding, shadow }) => `
        display: inline-flex;
        flex-shrink: 0;
        border-radius: ${borderRadius};
        padding: ${padding || '3px 6px'};
        background-color: ${backgroundColor || 'white'};
        align-items: center;
        height: ${height};
        box-shadow: ${shadow ? '0px 0px 12px rgba(0, 0, 0, 0.12)' : 'none'};
        ${
            borderColor &&
            `
            border: solid 1px ${borderColor};
        `
        }
    `}
`;
