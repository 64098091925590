import CustomDivider from 'src/legacy/components/common/custom-divider';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import ArrowButton from '@legacy/designs/arrow-button';
import CountdownText from '@legacy/designs/countdown-text';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import NoProduct from '@legacy/designs/no-product';
import ProductCardDetails from '@legacy/designs/product-card-details';
import WishListButton from '@legacy/designs/wish-list-button';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useCountdown } from '@hooks/use-countdown';
import { useHarmony } from '@hooks/use-harmony';
import { useWindowSize } from '@hooks/use-window-size';
import ComponentProduct from '@models/product/component-product';
import RoutesEnum from '@routes';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TimeSaleProps } from '..';
import ThemeUtils from '@utils/theme.utils';
import { useGATracking } from '@hooks/use-ga-tracking';

const SWIPE_WIDTH_FACTOR = 0.64;

const TimeSaleB: React.FC<TimeSaleProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const { logEvent } = useGATracking();
    const { time, end } = useCountdown(channelComponent.endDate);
    const [width] = useWindowSize({ maxWidth: true });
    const router = useRouter();

    const disabled = channelComponent.isBeforeStart || end;

    return (
        <PaddedContainer padding="16px">
            <HomeComponentHeading title={channelComponent.title}>
                {channelComponent.firstItem?.pageUrl && (
                    <Link href={channelComponent.firstItem.pageUrl}>
                        <ArrowButton
                            onClick={() => {
                                logEvent('select_promotion', {
                                    promotion_name: channelComponent.title,
                                });
                            }}
                        >
                            {t('home.view')}
                        </ArrowButton>
                    </Link>
                )}
            </HomeComponentHeading>
            <SwipeContainer theme={theme}>
                <BlockHStack align="center" alignItems="center" pt="14px" spacing="8px">
                    <CustomIcon fill color={theme.primary[600]}>
                        timer
                    </CustomIcon>
                    {channelComponent.isBeforeStart ? (
                        <BodyText weight="bold" size="18px" color={theme.primary.default}>
                            {t('home.saleStarts')}
                        </BodyText>
                    ) : (
                        <>
                            {end ? (
                                <BodyText weight="bold" size="18px" color={theme.primary.default}>
                                    {t('home.saleHasEnded')}
                                </BodyText>
                            ) : (
                                <CountdownText timeRemaining={time} />
                            )}
                        </>
                    )}
                </BlockHStack>
                <PaddedContainer padding={'14px 16px 16px'}>
                    <CustomDivider />
                </PaddedContainer>
                <Swiper width={width * SWIPE_WIDTH_FACTOR + 16} slidesOffsetBefore={width * 0.12} spaceBetween={15} centeredSlides>
                    {channelComponent.firstItem?.mainDisplayProductList &&
                        channelComponent.firstItem.mainDisplayProductList.length > 0 &&
                        channelComponent.firstItem.mainDisplayProductList.map((product: ComponentProduct) => (
                            <SwiperSlide key={product.id} style={{ width: `${width * SWIPE_WIDTH_FACTOR}px`, height: '100%' }}>
                                <Product
                                    theme={theme}
                                    disable={disabled || product.isSoldOut}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        logEvent('select_promotion', {
                                            promotion_name: channelComponent.title,
                                        });
                                        !disabled && router.push({ pathname: RoutesEnum.ProductDetail, query: { productId: product.id } });
                                    }}
                                    imageSize={width * SWIPE_WIDTH_FACTOR}
                                >
                                    <ImageContainer theme={theme}>
                                        {product.image && (
                                            <div className="image">
                                                <CustomImage src={product.image} alt={product.title} layout="fill" objectFit="cover" />
                                            </div>
                                        )}
                                        <AbsoluteContainer>
                                            <BlockHStack align="right">
                                                <PaddedContainer padding="2px">
                                                    <WishListButton size="20px" product={product} padding="5px" />
                                                </PaddedContainer>
                                            </BlockHStack>
                                        </AbsoluteContainer>
                                    </ImageContainer>
                                    <ProductCardDetails product={product} cols={1} lines={2} isEllipsisBrandName={false} />
                                </Product>
                            </SwiperSlide>
                        ))}
                    {(!channelComponent.firstItem?.mainDisplayProductList || channelComponent.firstItem.mainDisplayProductList.length < 1) &&
                        [...new Array(3)].map((_, i) => (
                            <SwiperSlide key={i} style={{ width: `${width * SWIPE_WIDTH_FACTOR}px`, height: '100%' }}>
                                <PaddedContainer padding="0 0 24px">
                                    <NoProduct size={`${width * SWIPE_WIDTH_FACTOR}px`} large />
                                </PaddedContainer>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </SwipeContainer>
        </PaddedContainer>
    );
};

export default TimeSaleB;

const AbsoluteContainer = styled.div`
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
`;

const SwipeContainer = styled.div<IThemeProps>`
    ${({ theme }) => `
        border-radius: 16px;
        background: white;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border: solid 1px ${theme.gray['50']};
        margin-top: 8px;
        position: relative;
        cursor: pointer;
    `}
`;

const Product = styled.div<IThemeProps & { disable: boolean; imageSize?: number }>`
    width: 100%;
    line-height: 1;
    padding-bottom: 24px;
    position: relative;
    overflow: hidden;
    ${({ disable }) =>
        disable
            ? `
        cursor: not-allowed;
        .image {
            opacity: 0.4;
            &::after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: ${ThemeUtils.zIndex.above};
            }
        }
    `
            : ''}
    .image {
        ${({ imageSize }) =>
            `   width: ${imageSize}px; 
                height: ${imageSize}px;
            `}
    }
`;

const ImageContainer = styled.div<IThemeProps>`
    position: relative;
    margin-bottom: 8px;
    border-radius: 12px;
    overflow: hidden;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
    border: 1px solid ${({ theme }) => theme.gray[200]};
`;

const NoProductContainer = styled.div<IThemeProps>`
    ${({ theme }) => `
        position: relative;
        padding-top: 100%;
        border: 1px solid ${theme.gray[200]};
        background: ${theme.gray['50']};
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    `}
`;
