import styled from '@emotion/styled';

const GalleryListB = styled.div`
    margin-top: 16px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export default GalleryListB;
