import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const FooterContainerB = styled.div<IThemeProps>`
    ${({ theme }) => `
        padding: 16px;
        background: ${theme.background.config};
    `}
`;

export default FooterContainerB;
