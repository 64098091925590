import { Center } from '@chakra-ui/react';
import StatefulText from '@components/stateful-text';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { FixedSubMenuCellProps } from '..';

const FixedSubMenuCellD: React.FC<FixedSubMenuCellProps> = ({ isActive, onClick, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Center
            onClick={onClick}
            p="12px 8px"
            cursor="pointer"
            borderBottomWidth="1px"
            borderRightWidth="1px"
            borderColor={theme.stroke.divider}
            borderStyle="solid"
            alignSelf="stretch"
            sx={{ '&:nth-child(3n + 0)': { borderRight: 'none' } }}
            {...props}
        >
            <StatefulText
                as={Body2B}
                color={theme.text.body2}
                textAlign="center"
                states={[
                    {
                        as: Body2C,
                        color: theme.text.title1,
                        condition: isActive,
                    },
                ]}
            >
                {children}
            </StatefulText>
        </Center>
    );
};

export default FixedSubMenuCellD;
