import { Box } from '@chakra-ui/react';
import FixedProductCardSection from '@designs/grouped/main/product-card-section/fixed-product-card-section';
import FixedSubMenu from '@designs/grouped/main/sub-menu/fixed-sub-menu';
import SwipeSubMenu from '@designs/grouped/main/sub-menu/swipe-sub-menu';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { useHarmony } from '@hooks/use-harmony';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { SubMenuInnerProps } from '..';

const SubMenuD: React.FC<SubMenuInnerProps> = ({ channelComponent, subMenuItems, selectedIndex, setSelectedIndex, componentItems }) => {
    const { theme } = useHarmony();

    return (
        <Box as="section" pt="8px" pb="16px">
            <MainSectionTitle title={channelComponent.title} subTitle={channelComponent.subtitle} />

            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                <Box p="8px 16px 4px">
                    <FixedSubMenu items={subMenuItems} onChange={(item) => setSelectedIndex(item.value)} selectedItem={subMenuItems[selectedIndex]} />
                </Box>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                <SwipeSubMenu items={subMenuItems} onChange={(item) => setSelectedIndex(item.value)} selectedItem={subMenuItems[selectedIndex]} />
            )}

            <Box mt="12px" px="16px">
                <FixedProductCardSection
                    type={channelComponent.productCols === 1 ? 'L' : 'M'}
                    products={componentItems[selectedIndex].mainDisplayProductList.slice(0, channelComponent.productMaxCount)}
                    viewMoreHref={componentItems[selectedIndex].path}
                />
            </Box>
        </Box>
    );
};

export default SubMenuD;
