import { Box, Grid, HStack, VStack } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import DesignedContainer from '@designs/primitives/common/designed-container';
import BrandCard from '@designs/primitives/main/brand-card';
import ComponentImage from '@designs/primitives/main/component-variation/component-image';
import MainSectionTitle from '@designs/primitives/main/main-section-title/main-section-title';
import { useHarmony } from '@hooks/use-harmony';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import { BrandProps } from '..';

const BrandD: React.FC<BrandProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <VStack as="section" spacing="8px" pt="8px" pb="16px" align="stretch">
            <MainSectionTitle title={channelComponent.title} subTitle={channelComponent.subtitle} />
            <Box px="16px">
                <DesignedContainer pb="16px">
                    <VStack spacing="16px" w="100%">
                        <CustomLink href={channelComponent.firstItem?.pageUrl}>
                            <ComponentImage src={channelComponent.firstItem?.image} ratio={channelComponent.imageRatio} />
                        </CustomLink>
                    </VStack>
                    {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && (
                        <Grid p="16px 12px 0" columnGap="8px" rowGap="16px" templateColumns={`repeat(${channelComponent.brandCols}, 1fr)`}>
                            {channelComponent.brandItems.map((item, index) => (
                                <BrandCard key={index} componentItem={item} />
                            ))}
                        </Grid>
                    )}
                    {channelComponent.displayType === ComponentDisplayTypeEnum.Swipe && (
                        <HStack spacing="8px" p="16px 12px 0" overflowX="auto">
                            {channelComponent.brandItems.map((item, index) => (
                                <Box key={index} w="86px" flexShrink="0">
                                    <BrandCard componentItem={item} />
                                </Box>
                            ))}
                        </HStack>
                    )}
                </DesignedContainer>
            </Box>
        </VStack>
    );
};

export default BrandD;
