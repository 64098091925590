import { useDesignComponents } from '@hooks/use-design-components';

import TransactionCacheKeys from '@api/transaction/cache-keys';
import { TransactionClient } from '@api/transaction/transaction.client';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { IAvailablePointsDto } from '@models/available-points/responseDto/available-points.dto';
import { Design } from '@type/harmony-config';
import ConversionUtils from '@utils/conversion.utils';
import { ComponentType } from 'react';
import useSWR from 'swr';
import PointsD from './designD/points';

export interface PointsProps {}

export interface PointsInnerProps extends PointsProps {
    availablePointsString: string;
}

const Points: React.FC<PointsProps> = (props) => {
    const Component = useDesignComponents<ComponentType<PointsInnerProps>>({
        [Design.DesignD]: PointsD,
    });

    const { isAuth } = useCustomer();

    const { data } = useSWR<IAvailablePointsDto>(isAuth ? TransactionCacheKeys.availablePointsCacheKey() : undefined, () => TransactionClient.getAvailablePoints());

    if (!isAuth || !data) return <></>;

    return <Component availablePointsString={ConversionUtils.numberToString(data?.totalPointAvailable || 0)} {...props} />;
};

export default Points;
