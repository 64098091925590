import BottomMenu from '@designs/grouped/main/bottom-menu';
import ChannelComponent from '@designs/grouped/main/component-variation/channel-component';
import PopUp from '@designs/grouped/main/pop-up';
import Footer from '@designs/grouped/main/footer';
import Header from '@designs/grouped/main/header';
import TopMenu from '@designs/grouped/main/top-menu';
import { MainContentInnerProps } from '..';

const MainContentD: React.FC<MainContentInnerProps> = ({ componentsDto, popupDto }) => {
    return (
        <>
            <Header type="main" hideOnScroll />
            <TopMenu />
            {componentsDto && componentsDto.map((componentDto, index) => <ChannelComponent key={index} index={index} componentDto={componentDto} />)}
            <Footer />
            <BottomMenu />
            {popupDto && <PopUp popupDto={popupDto} />}
        </>
    );
};

export default MainContentD;
