import { useHarmony } from '@hooks/use-harmony';
import { OfflineStoreCardProps } from '..';
import { useTranslations } from 'next-intl';
import ProductCardImage from '@designs/primitives/common/product-card/product-card-image';
import { Box, VStack } from '@chakra-ui/react';
import Body2C from '@designs/primitives/typography/body-2-c';
import DetailA from '@designs/primitives/typography/detail-a';
import Badge, { BadgeType } from '@designs/primitives/common/badge/product-badge';
import RoutesEnum from '@routes';
import Link from 'next/link';
import SanitationUtils from '@utils/sanitation.utils';

const OfflineStoreCardD: React.FC<OfflineStoreCardProps> = ({ offlineStore }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Link href={`${RoutesEnum.OfflineStore}/${offlineStore.id}`}>
            <VStack align="flex-start">
                <Box pos="relative" w="138px">
                    <ProductCardImage imageUrl={offlineStore.imageUrl} w="100%" />
                </Box>
                <VStack w="100%" align="flex-start" spacing="4px" px="4px">
                    <Body2C color={theme.text.body1}>{offlineStore.name}</Body2C>
                    <DetailA color={theme.text.body1}>
                        {offlineStore.baseAddress} {offlineStore.detailAddress}
                    </DetailA>
                    <DetailA color={theme.text.body1}> {SanitationUtils.sanitizePhoneNumber(offlineStore.phone)}</DetailA>
                </VStack>
                <Badge badgeType={offlineStore.isOpenForBusiness ? BadgeType.System1 : BadgeType.Normal}>
                    {offlineStore.isOpenForBusiness ? t('store.open') : t('store.ready')}
                </Badge>
            </VStack>
        </Link>
    );
};

export default OfflineStoreCardD;
