import { useDesignComponents } from '@hooks/use-design-components';

import ChannelComponent from '@models/channel-component';
import { Design } from '@type/harmony-config';
import { ComponentType, useEffect } from 'react';
import BannerD from './designD/banner';
import { useGATracking } from '@hooks/use-ga-tracking';

export interface BannerProps {
    channelComponent: ChannelComponent;
}

const Banner: React.FC<BannerProps> = (props) => {
    const { logEvent } = useGATracking();
    const Component = useDesignComponents<ComponentType<BannerProps>>({
        [Design.DesignD]: BannerD,
    });

    useEffect(() => {
        props.channelComponent.items.forEach((item) => {
            logEvent('view_promotion', {
                promotion_name: item.pageUrl,
                items: [],
            });
        });
    }, []);

    return <Component {...props} />;
};

export default Banner;
