import { useDesignComponents } from '@hooks/use-design-components';

import CustomLink from '@components/custom-link';
import { Url } from '@type/common';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ImageMenuCellD from './designD/image-menu-cell';
import { Box } from '@chakra-ui/react';

export interface ImageMenuCellProps {
    imageUrl?: string;
    ratio: number;
    title?: string;
    pagePath?: Url;
}

const ImageMenuCell: React.FC<ImageMenuCellProps> = ({ pagePath, ...props }) => {
    const Component = useDesignComponents<ComponentType<ImageMenuCellProps>>({
        [Design.DesignD]: ImageMenuCellD,
    });

    return (
        <CustomLink href={pagePath}>
            <Box>
                <Component {...props} />
            </Box>
        </CustomLink>
    );
};

export default ImageMenuCell;
