import { useDesignComponents } from '@hooks/use-design-components';

import { css } from '@emotion/react';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { FontSizes, FontWeight } from '@type/theme';
import BodyText from './text/body-text';

interface UnderlineButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    fontSize?: FontSizes;
    color?: string;
    height?: string;
    lineHeight?: string;
    weight?: FontWeight;
}

const UnderlineButton: React.FC<UnderlineButtonProps> = ({ fontSize, children, color, height = '20px', lineHeight, weight, ...props }) => {
    const { theme } = useHarmony();

    const fontWeight = useDesignComponents<FontWeight>({
        [Design.DesignA]: 'thin',
        [Design.DesignC]: 'regular',
    });

    const defaultColor = useDesignComponents<string>({
        [Design.DesignA]: theme.gray[800],
        [Design.DesignB]: theme.gray[700],
    });

    return (
        <button
            type="button"
            css={css`
                width: fit-content;
                height: ${height};
                display: flex;
                align-items: center;
                color: ${color || defaultColor};
                text-decoration: underline;
                text-decoration-color: ${color || defaultColor};
                background: transparent;
                cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
                opacity: ${props.disabled ? '30%' : '100%'};
            `}
            {...props}
        >
            <BodyText size={fontSize || '15px'} weight={weight ?? fontWeight} color="inherit" lineHeight={lineHeight}>
                {children}
            </BodyText>
        </button>
    );
};

export default UnderlineButton;
