import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import React from 'react';
import { BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import { HomeComponentHeadingProps } from '../home-component-heading';

const HomeComponentHeadingA: React.FC<HomeComponentHeadingProps> = ({ title, subtitle, children }) => {
    const { theme } = useHarmony();

    return (
        <StyledHeading>
            <StyledTitle theme={theme} spacing="5px" align="center" alignItems="start">
                <BodyText ellipsis weight="bold" size="18px" lineHeight="26px" whiteSpace="nowrap" lineClamp={1}>
                    {title}
                </BodyText>
                {subtitle && (
                    <BodyText lineHeight="18px" ellipsis font="secondary" weight="thin" size="15px" height="15px" lineClamp={1}>
                        {subtitle}
                    </BodyText>
                )}
            </StyledTitle>
            {children}
        </StyledHeading>
    );
};

export default HomeComponentHeadingA;

const StyledHeading = styled.div`
    width: 100%;
    height: 50px;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`;

const StyledTitle = styled(BlockVStack)`
    ${({ theme }) => `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        color: ${theme.black};
        flex: 1;
        height: 100%;
        width: 100%;
    `}
`;
