import { Box, Grid } from '@chakra-ui/react';
import DesignedContainer from '@designs/primitives/common/designed-container';
import IconMenuItem from '@designs/primitives/main/icon-menu/icon-menu-item';
import { useHarmony } from '@hooks/use-harmony';
import { IconMenuProps } from '..';

const IconMenuD: React.FC<IconMenuProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();

    return (
        <Box as="section" p="16px">
            <DesignedContainer p="16px 16px">
                <Grid rowGap="24px" columnGap="8px" templateColumns="repeat(4, 1fr)">
                    {channelComponent.items.map((item, index) => (
                        <IconMenuItem key={index} imageUrl={item.image} label={item.displayName} pagePath={item.path} />
                    ))}
                </Grid>
            </DesignedContainer>
        </Box>
    );
};

export default IconMenuD;
